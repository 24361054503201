import React, { ReactNode } from 'react';
import { PostHogProvider } from 'posthog-js/react';
import { ENVIRONMENT_CONFIG } from './config/hosts';

const options = {
  // eslint-disable-next-line camelcase
  api_host: ENVIRONMENT_CONFIG.postHogApiHost,
};

export const AnalyticsWrapper = ({ children }: { children: ReactNode }) => {
  return ENVIRONMENT_CONFIG.isAnalyticsEnabled ? (
    <PostHogProvider
      apiKey={ENVIRONMENT_CONFIG.postHogPublicKey}
      options={options}
    >
      {children}
    </PostHogProvider>
  ) : (
    children
  );
};
