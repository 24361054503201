import React from 'react';
import {
  formatDatetime,
  getPersonFullName,
  Proposal,
  getIsSignatureRequired,
} from 'common';
import styles from './AcceptedBySection.module.scss';

const AcceptedBySection = ({ proposal }: { proposal: Proposal }) => {
  const isSignatureRequired = getIsSignatureRequired(proposal);

  if (
    !isSignatureRequired &&
    (proposal.acceptedByContact || proposal.acceptedByUser) &&
    proposal.acceptedAt
  ) {
    return (
      <div className={styles.acceptedBySection}>
        <div className={styles.acceptedByWrapper}>
          <p>Accepted by&nbsp;</p>
          <p className={styles.person}>
            {getPersonFullName(
              proposal.acceptedByContact ?? proposal.acceptedByUser
            )}
            ,
          </p>
          <p>&nbsp;{formatDatetime(proposal.acceptedAt)}.&nbsp;</p>
        </div>
      </div>
    );
  }
  return null;
};

export default AcceptedBySection;
