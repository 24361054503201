import React, { useEffect, useState } from 'react';
import { getScheduleMessage } from '../../../utils/utils';
import {
  AcceptRequest,
  BillingMethodRequest,
  Button,
  ButtonBar,
  CheckoutPaymentMethod,
  CompanyInfo,
  Contract,
  CreateContractRequest,
  FormField,
  Input,
  Modal,
  PaymentSchedule,
  Proposal,
  Type,
} from 'common';
import { PlaidLinkOnSuccessMetadata } from 'react-plaid-link';
import DoneButton from './DoneButton';
import PlaidLogo from '../../../assets/PlaidLogo';
import styles from './DirectDebutConfirmModal.module.scss';

interface Props {
  companyInfo: CompanyInfo;
  contract?: Contract;
  handleClose: () => void;
  isOpen: boolean;
  paymentMethod?: CheckoutPaymentMethod;
  paymentSchedule: PaymentSchedule;
  plaidData: PlaidLinkOnSuccessMetadata | undefined;
  plaidToken: string;
  proposal: Proposal;
}

const DirectDebitConfirmModal = ({
  companyInfo,
  contract,
  handleClose,
  isOpen,
  paymentMethod,
  paymentSchedule,
  plaidData,
  plaidToken,
  proposal,
}: Props) => {
  const [legalName, setLegalName] = useState('');
  const [error, setError] = useState('');
  const defaultLegalName: string = proposal.customer?.name || '';

  useEffect(() => {
    setLegalName(defaultLegalName);
  }, [defaultLegalName]);

  const enrichRequest = (request: CreateContractRequest) => {
    if (!plaidData) {
      return request;
    }

    return {
      ...request,
      paymentMethod: {
        ...request.paymentMethod,
        plaidAccounts: {
          publicToken: plaidToken,
          institutionName: plaidData.institution?.name,
          legalName,
          accounts: plaidData.accounts,
          authorizeAmount: 1,
        },
      },
    };
  };

  const enrichUpdateRequest = (request: BillingMethodRequest) => {
    if (!plaidData) {
      return request;
    }

    return {
      ...request,
      plaidAccounts: {
        publicToken: plaidToken,
        institutionName: plaidData.institution?.name,
        legalName,
        accounts: plaidData.accounts,
        authorizeAmount: 1,
      },
    };
  };

  const enrichRenewalRequest = (request: AcceptRequest) => {
    if (!plaidData) {
      return request;
    }

    return {
      billingMethodRequest: {
        paymentMethodId: request.billingMethodRequest?.paymentMethodId,
        plaidAccounts: {
          publicToken: plaidToken,
          institutionName: plaidData.institution?.name,
          legalName,
          accounts: plaidData.accounts,
          authorizeAmount: 1,
        },
      },
    };
  };

  const onError = (msg?: string) => {
    msg && setError(msg);
  };

  if (!paymentMethod?.id) {
    return null;
  }

  return (
    <Modal
      width={700}
      isOpen={isOpen}
      onClose={handleClose}
      className={styles.directDebitConfirmModal}
      header={
        <div className={styles.logo}>
          <PlaidLogo />
        </div>
      }
      footer={
        <ButtonBar>
          <Button label="Cancel" onClick={handleClose} type="secondary" />
          <DoneButton
            contract={contract}
            label="Agree & proceed"
            onError={onError}
            onSuccess={handleClose}
            paymentMethodId={paymentMethod.id}
            proposal={proposal}
            createContractRequestEnricher={enrichRequest}
            createChangeRenewalRequestEnricher={enrichRenewalRequest}
            updateContractRequestEnricher={enrichUpdateRequest}
          />
        </ButtonBar>
      }
    >
      <Type header={3}>
        On behalf of {companyInfo.name},{' '}
        {getScheduleMessage(proposal.proposalItems, paymentSchedule)}
      </Type>

      <div className={styles.hr} />

      <FormField
        label="Please enter the legal company name on the account."
        errorToShow={error || legalName === '' ? 'field cannot be blank' : ''}
      >
        <Input
          placeholder="Enter name"
          value={legalName}
          onChange={(value) => setLegalName(value)}
        />
      </FormField>
    </Modal>
  );
};

export default DirectDebitConfirmModal;
