import {
  arrayTop,
  CompanyInfo,
  ContactInternal,
  formatMoneyOrDefault,
  getBillingPeriodMonths,
  getCadenceDescription,
  getDatetime,
  getErrorData,
  getErrorMessage,
  getErrorStatus,
  hasUsage,
  isArrayNonEmpty,
  isDefined,
  isString,
  Item,
  Payment,
  PaymentSchedule,
  Proposal,
} from 'common';
import { parseISO } from 'date-fns';
import React from 'react';
import ErrorPage from '../components/ErrorPage';
import { useIsPreview } from './viewMode';

const getNumberOfPayments = (paymentSchedule: PaymentSchedule): number => {
  return (paymentSchedule.payments || []).filter(
    (payment: Payment) => payment.amount > 0
  ).length;
};

const getFirstNonZeroPayment = (
  items: Item[],
  paymentSchedule: PaymentSchedule
) => {
  const scheduleBillingPeriod = paymentSchedule.payEvery;

  const amounts = items
    .filter((ic) => ic.product.recurrence === 'recurring')
    .map((i) => i.spans[scheduleBillingPeriod]?.[0]?.amount)
    .filter(isDefined);

  if (!isArrayNonEmpty(amounts)) return undefined;

  return {
    currency: amounts[0].currency || 'USD',
    scale: amounts[0].scale || 2,
    amount: amounts.reduce((sum, i) => sum + (i.amount || 0), 0),
  };
};

const formatDayAndSuffix = (day: number) => {
  if (day > 3 && day < 21) return day + 'th';
  switch (day % 10) {
    case 1:
      return day + 'st';
    case 2:
      return day + 'nd';
    case 3:
      return day + 'rd';
    default:
      return day + 'th';
  }
};

export const getScheduleMessage = (
  items: Item[],
  paymentSchedule: PaymentSchedule
) => {
  const dueDate = paymentSchedule.payments?.[0]?.dueDate;
  const day = dueDate ? parseISO(dueDate).getDate() : 0;
  const numPayments = getNumberOfPayments(paymentSchedule);
  const unit = numPayments > 1 ? 'payments' : 'payment';
  const firstNonZeroPayment = getFirstNonZeroPayment(items, paymentSchedule);
  const monthsInBillingPeriod = getBillingPeriodMonths(
    paymentSchedule.payEvery
  );

  // TODO: When there are one-time fees involved, this should describe
  // both the first payment and ongoing payments.
  if (firstNonZeroPayment) {
    return `Cacheflow will automatically debit 
  ${formatMoneyOrDefault(firstNonZeroPayment)} from your account ${
    monthsInBillingPeriod === 1 ? `on the ${formatDayAndSuffix(day)} of ` : ''
  }every ${getCadenceDescription(
    paymentSchedule.payEvery
  )} for ${numPayments} ${unit}`;
  }
  return '';
};

export const useActiveEmail = () => {
  const isPreview = useIsPreview();

  if (isPreview) return 'example@getcacheflow.com';

  const email = new URLSearchParams(window.location.search).get('email') || '';

  // TODO this is a hack
  // I cant imagine a valid space in an email so Ok until we fix CFAPP
  return email.replace(' ', '+');
};

export const useActiveContact = (proposal: Proposal): ContactInternal => {
  const isPreview = useIsPreview();
  const email = useActiveEmail();

  if (isPreview) {
    const now = getDatetime();

    return (
      arrayTop(proposal.proposalContacts)?.contact || {
        createdAt: now,
        createdBy: 'example@example.com',
        email: '{contact email}',
        firstName: '{contact',
        lastName: 'name}',
        updatedAt: now,
      }
    );
  }

  const activeContact = proposal.proposalContacts.find(
    (proposalContact) => proposalContact.contact?.email === email
  )?.contact;

  if (!activeContact) {
    throw new Error("Couldn't find active contact");
  }

  return activeContact;
};

export const getRecordInaccessibleError = (
  contactName?: string,
  contactEmail?: string
) => {
  const message = (
    <p>
      {contactName
        ? `Please contact ${contactName} at ${contactEmail} for more information.`
        : 'Please contact the seller for more information.'}
    </p>
  );

  return <ErrorPage title="Proposal access expired" message={message} />;
};

export const getError = (error: unknown): React.ReactElement => {
  const status = getErrorStatus(error);
  const message = getErrorMessage(error);
  const errorData = getErrorData(error);

  if (!error || !message) {
    return <ErrorPage title="Something went wrong" message="" />;
  }

  switch (status) {
    case 500:
      return <ErrorPage title="Internal Server Error" message={message} />;
    case 404:
      return <ErrorPage title="Proposal Not Found" message={message} />;
    case 401:
      return <ErrorPage title="Proposal access error" message={message} />;
    case 400: {
      if (errorData?.errorCode === 'PROPOSAL_NOT_VIEWABLE') {
        return getRecordInaccessibleError();
      }
      return <ErrorPage title="Invalid proposal" message={message} />;
    }
    default:
      return <ErrorPage title="Something went wrong" message={message} />;
  }
};

const emailRegex =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

export const validateEmail = (email: string | undefined): boolean => {
  return isString(email) && emailRegex.test(email);
};

const nameRegex = /^[a-z0-9,'.;\s-]*$/i;
export const validateName = (name: string | undefined): boolean => {
  if (name) {
    const n = name.trim();
    return nameRegex.test(n) && n.length > 0;
  }
  return false;
};

export const getAmountDesc = (proposal: Proposal) => {
  return (hasUsage(proposal) ? 'from ' : '') + proposal.amountFormatted;
};

export const getCompanyLogoUrl = (companyInfo?: CompanyInfo) => {
  const companyLogoStr = window.sessionStorage.getItem('companyLogo');
  return companyLogoStr
    ? companyLogoStr
    : companyInfo?.logo?.latestVersion?.url;
};
