import React from 'react';
import { Space } from 'antd';
import {
  AddressDisplay,
  CompanyInfo,
  Icon,
  Proposal,
  Type,
  getIsSignatureRequired,
} from 'common';
import ProposalLayout from '../ProposalLayout';
import { useIsPreview } from '../../utils/viewMode';
import ShippingAddressModal from '../ShippingAddressModal';
import HeaderPillButton from './HeaderPillButton';

interface Props {
  companyInfo: CompanyInfo;
  proposal: Proposal;
}

const HeaderAddresses: React.FC<Props> = ({ companyInfo, proposal }) => {
  const isPreview = useIsPreview();
  const [isEditing, setIsEditing] = React.useState(false);
  const customerName: string | undefined =
    proposal.customer?.name ?? (isPreview ? 'Customer Name' : undefined);
  const shippingAddress = proposal.customer?.shippingAddress;

  return (
    <ProposalLayout.TwoColumn borderBottom>
      <div data-testid={`proposal-seller-${companyInfo.name}`}>
        <Type header={2}>Supplier</Type>
        <Type header={3} noMargin>
          {proposal.shipFrom?.name ?? companyInfo.name}
        </Type>
        {(proposal.shipFrom?.billingAddress ?? companyInfo.billingAddress) && (
          <AddressDisplay
            address={
              proposal.shipFrom?.billingAddress ?? companyInfo.billingAddress
            }
          />
        )}
        {companyInfo.tin && <p>Tax ID: {companyInfo.tin}</p>}
      </div>
      <div data-testid={`proposal-for-${customerName}`}>
        <Space align="start" size="large">
          <Type header={2}>Ship to</Type>
          <ShippingAddressModal
            hasSigning={getIsSignatureRequired(proposal)}
            isOpen={isEditing}
            proposal={proposal}
            setIsOpen={setIsEditing}
          />
          <HeaderPillButton
            className="print:hidden"
            icon={shippingAddress ? Icon.Edit : Icon.Plus}
            label={shippingAddress ? 'Change' : 'Add'}
            onClick={() => setIsEditing(true)}
          />
        </Space>
        <Type header={3} noMargin>
          {customerName}
        </Type>
        {shippingAddress && <AddressDisplay address={shippingAddress} />}
        {proposal.customer?.tin && <p>Tax ID: {proposal.customer.tin}</p>}
      </div>
    </ProposalLayout.TwoColumn>
  );
};

export default HeaderAddresses;
