import {
  CustomizeTieredPriceModal,
  isProductTiered,
  Item,
  ItemSchedule,
  PaymentSchedule,
  Proposal,
  ProposalConfig,
  TabsLoader,
} from 'common';
import React, { useEffect, useState } from 'react';
import { SelectedOptionProps } from '../../types/model';
import { ItemCheckoutTab } from './ItemCheckoutTab';
import { ItemDeltasTab } from './ItemDeltasTab';
import styles from './ProductsSection.module.scss';
import { useOrgDefaults } from '../../services/orgConfigs';
import { getIsChangesOnly } from '../../utils/pdf';
import { useGetProposalDeltas } from '../../services/proposal';

type ModalDefaults =
  | {
      isOpen: false;
    }
  | {
      isOpen: true;
      item: Item;
      selectedSchedule: ItemSchedule;
    };

const CONFIG_KEY = 'proposalConfig';

const priceModalDefaults: ModalDefaults = {
  isOpen: false,
};

interface Props {
  isPdf: boolean;
  isReadOnly: boolean;
  proposal: Proposal;
  selectedOptions?: SelectedOptionProps;
}

const tabs = [
  {
    label: 'All',
    key: 'all',
    component: null,
  },
  {
    label: 'Changes only',
    key: 'changes',
    component: null,
  },
];

export const ProductsSection: React.FC<Props> = ({
  proposal,
  selectedOptions,
  isReadOnly,
  isPdf,
}) => {
  const { data: orgDefaults, isLoading: isOrgDefaultsLoading } =
    useOrgDefaults();
  const isChangesOnly = getIsChangesOnly();

  const { data: deltas } = useGetProposalDeltas(proposal.id);

  const isProposalChanged =
    proposal.proposalType === 'change' && (deltas ?? []).length > 0;

  const [selectedTab, setSelectedTab] = useState<string>(
    isChangesOnly && isProposalChanged ? 'changes' : 'all'
  );

  useEffect(() => {
    if (orgDefaults && isProposalChanged) {
      const proposalConfig = orgDefaults.get(CONFIG_KEY)
        ?.configValue as ProposalConfig;
      const changesOnly = proposalConfig.changesOnlyViewAsDefault;
      setSelectedTab(changesOnly ? 'changes' : 'all');
    }
  }, [orgDefaults, isProposalChanged]);

  const billingPeriod: PaymentSchedule['payEvery'] | undefined =
    selectedOptions?.selectedSchedule?.payEvery ??
    proposal.defaultBillingPeriod;

  const [priceModal, setPriceModal] =
    useState<ModalDefaults>(priceModalDefaults);

  const handleShowPriceModalClick = (
    selectedSchedule: ItemSchedule,
    item: Item
  ) => {
    setPriceModal({
      isOpen: true,
      selectedSchedule,
      item,
    });
  };

  if (!billingPeriod) {
    return null;
  }

  return (
    <div className="mt-4">
      {!isPdf && isProposalChanged && (
        <div className={styles.tabSection}>
          <div className={styles.tabs}>
            <TabsLoader
              defaultActiveTab={selectedTab}
              isLoading={isOrgDefaultsLoading}
              onChange={setSelectedTab}
              tabs={tabs}
            />
          </div>
        </div>
      )}
      {selectedTab &&
        (selectedTab === 'all' ? (
          <ItemCheckoutTab
            billingPeriod={billingPeriod}
            isPdf={isPdf}
            isReadOnly={isReadOnly}
            onShowPriceModalClick={handleShowPriceModalClick}
            proposal={proposal}
          />
        ) : (
          <ItemDeltasTab billingPeriod={billingPeriod} proposal={proposal} />
        ))}
      {priceModal.isOpen && isProductTiered(priceModal.item.product) && (
        <CustomizeTieredPriceModal
          closeModal={() => setPriceModal(priceModalDefaults)}
          isOpen={priceModal.isOpen}
          isViewOnlyMode
          selectedItem={priceModal.item}
          selectedSchedule={priceModal.selectedSchedule}
        />
      )}
    </div>
  );
};
