import React, { useState } from 'react';
import {
  Button,
  ButtonBar,
  CompanyInfo,
  Drawer,
  isChangeProposalType,
  isRenewalProposalType,
  isScheduleSelectionProposalStatus,
  Proposal,
  Type,
} from 'common';
import ProposalChangesList from './ProposalChangesList';
import styles from './ChangeTopDrawer.module.scss';
import { getIsPdf } from '../../utils/pdf';

interface Props {
  companyInfo: CompanyInfo;
  proposal: Proposal;
}

const SHOW_STATUS: Proposal['status'][] = ['schedule_selection', 'active'];

const ChangeTopDrawer: React.FC<Props> = ({ proposal, companyInfo }) => {
  const changes = proposal.changes;
  const isProposalTypeChange = isChangeProposalType(proposal);
  const isProposalTypeRenewal = isRenewalProposalType(proposal);
  const isAllowedStatus = SHOW_STATUS.includes(proposal.status);
  const proposalNeedsSigning = isScheduleSelectionProposalStatus(proposal);
  const isPdf = getIsPdf();

  if (
    isPdf ||
    !changes ||
    !isAllowedStatus ||
    (!isProposalTypeChange && !isProposalTypeRenewal)
  ) {
    return null;
  }

  const hasChanges: boolean = [
    changes.itemChanges?.length,
    changes.customTermsChange,
    changes.renewalConfigurationChange,
  ].some((value) => !!value);

  if (!hasChanges) {
    return null;
  }

  const [isOpen, setIsOpen] = useState(true);
  const onClose = () => setIsOpen(false);

  const getBodyText = (): string => {
    const companyName = companyInfo.name || '';

    const baseRenewalMessage = `Please review your ${companyName} subscription renewal`;
    const signedRenewalMessage = `Please review and sign your ${companyName} subscription renewal`;

    const baseChangeMessage = `Please review changes to your ${companyName} subscription`;
    const signedChangeMessage = `Please review and sign changes to your ${companyName} subscription`;

    if (isProposalTypeRenewal) {
      return proposalNeedsSigning ? signedRenewalMessage : baseRenewalMessage;
    }

    if (isProposalTypeChange) {
      return proposalNeedsSigning ? signedChangeMessage : baseChangeMessage;
    }

    return baseChangeMessage;
  };

  const getButtonText = () => {
    if (isProposalTypeRenewal) {
      return 'Okay, review renewal';
    }

    return 'Okay, I’ll review changes';
  };

  return (
    <Drawer
      className={styles.changeTopDrawer}
      isOpen={isOpen}
      placement="top"
      onClose={onClose}
    >
      <div className={styles.container}>
        <div className={styles.left}>
          <Type header={0}>{getBodyText()}</Type>
        </div>
        <div className={styles.border} />
        <div className={styles.right}>
          <div className={styles.changes}>
            <Type header={2}>What changed?</Type>
            <ProposalChangesList proposal={proposal} />
          </div>
          <div className={styles.button}>
            <ButtonBar className="mt-8">
              <Button
                dataTestId="change-top-drawer-confirm"
                label={getButtonText()}
                onClick={onClose}
              />
            </ButtonBar>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default ChangeTopDrawer;
