import { OrgConfig } from 'common';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { buyerAxios } from './api';

const DEFAULTS_URL = '/api/latest/settings/org/defaults/';

export const useOrgDefaults = (): UseQueryResult<
  Map<OrgConfig['configKey'], OrgConfig>
> =>
  useQuery({
    queryKey: ['orgDefaults'],
    queryFn: async () => {
      const { data } = await buyerAxios.get<OrgConfig[]>(DEFAULTS_URL);
      return new Map(data.map((config) => [config.configKey, config]));
    },
  });
