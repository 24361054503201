import React from 'react';
import usePlaid from '../../../../services/plaid';
import PoweredByPlaid from './PoweredByPlaid';
import { useIsPreview } from '../../../../utils/viewMode';
import { Button, Proposal } from 'common';
import {
  PlaidLinkError,
  PlaidLinkOnExitMetadata,
  PlaidLinkOnSuccessMetadata,
} from 'react-plaid-link';

interface Props {
  onClose?: () => void;
  onFailureAction?: (
    error: PlaidLinkError,
    metadata: PlaidLinkOnExitMetadata
  ) => void;
  onSetIsFallback?: (isFallback: boolean) => void;
  onSuccessAction?: (
    plaidToken: string,
    metadata: PlaidLinkOnSuccessMetadata
  ) => void;
  proposal: Proposal;
  showCancelButton?: boolean;
}

const PlaidButton = ({
  proposal,
  onSetIsFallback,
  onSuccessAction,
  onFailureAction,
  showCancelButton,
  onClose,
}: Props) => {
  const isPreview = useIsPreview();

  const { open, ready, error } = usePlaid(
    isPreview,
    proposal,
    onSuccessAction,
    onFailureAction
  );

  return (
    <>
      <div className="pt-2 flex gap-2">
        <Button
          label="Connect bank"
          isDisabled={!ready && !error}
          onClick={() => open!()}
        />
        <Button
          label="Manually connect"
          type="secondary"
          onClick={() => onSetIsFallback?.(true)}
        />
        {showCancelButton && (
          <Button
            onClick={onClose}
            className="ml-[10px]"
            label="Cancel"
            type="secondary"
          />
        )}
        {/* <button
          onClick={handleClick}
          className="w-3/5 px-8 py-5 mb-4 font-semibold text-white transition duration-200 rounded-md bg-blue sm:hover:opacity-90"
        >
          Simulate Direct debit Error
        </button> */}
      </div>
      <div className="pt-4">
        <PoweredByPlaid />
      </div>
    </>
  );
};

export default PlaidButton;
