import React from 'react';
import { CompanyInfo, defaultBrand, getCompanyBranding } from 'common';
import { getIsPdf, useHaveAllImagesDownloadedOrFailed } from '../../utils/pdf';
import { getCompanyLogoUrl } from '../../utils/utils';

interface Props {
  companyInfo: CompanyInfo;
}

const ReadyIndicatorPixels = ({ companyInfo }: Props) => {
  const branding = getCompanyBranding(companyInfo);
  const windowBgImageSrc =
    branding?.window?.backgroundImage || defaultBrand.window.backgroundImage;
  const headerBgImageSrc =
    branding?.header?.backgroundImage || defaultBrand.header.backgroundImage;
  const paperBgImageSrc =
    branding?.paper?.backgroundImage || defaultBrand.paper.backgroundImage;
  const logoImageSrc = getCompanyLogoUrl(companyInfo);
  const isPdf = getIsPdf();
  const isReady = useHaveAllImagesDownloadedOrFailed([
    windowBgImageSrc,
    headerBgImageSrc,
    paperBgImageSrc,
    logoImageSrc,
  ]);

  if (!isReady) {
    return null;
  }

  const Pixel: React.FC<{ id: string }> = ({ id }) => (
    <div
      id={id}
      style={{
        position: 'absolute',
        width: 1,
        height: 1,
      }}
    />
  );

  return (
    <>
      <Pixel id="page-ready" />
      {isPdf && <Pixel id="pdf-ready" />}
    </>
  );
};

export default ReadyIndicatorPixels;
