export const PAYMENT_SETUP_SECTION = 'payment-setup-section';
export const BILL_TO_SECTION = 'bill-to-section';

export const scrollTo = (id: string) => {
  const sectionById = document.getElementById(id);

  if (sectionById) {
    sectionById.scrollIntoView({ behavior: 'smooth' });
  }
};

export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};
