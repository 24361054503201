import React, { useEffect, useState } from 'react';

import {
  CheckoutPaymentMethod,
  CompanyInfo,
  Contract,
  hasAnAcceptedProposalStatus,
  isAcceptedProposalStatus,
  isPendingRenewalProposalStatus,
  Proposal,
  useFlags,
} from 'common';

import { SelectedOptionProps } from '../../types/model';
import { getEnabledMethodsForProposal } from '../../utils/payment';
import { ChangeRenewalStateType } from '../PaymentSection/ProposalTypePaymentSection';

import Method from './Method';
import ACH from './Methods/ACH';
import Wire from './Methods/Wire';
import Check from './Methods/Check';
import Custom from './Methods/Custom';
import Manual from './Methods/Manual';
import CreditCard from './Methods/CreditCard';
import DirectDebit from './Methods/DirectDebit';
import NoneSelected from './Methods/NoneSelected/NoneSelected';
import { useIsPreview } from '../../utils/viewMode';

import './Methods/methods.scss';

interface Props {
  changeRenewalState: ChangeRenewalStateType;
  companyInfo: CompanyInfo;
  contract?: Contract;
  enabledForOtp: boolean;
  paymentMethods: CheckoutPaymentMethod[];
  proposal: Proposal;
  selectedOptions: SelectedOptionProps;
  setChangeRenewalState: any;
  setSelectedMethodId: (method: string) => void;
}

const ProposalTypeMethods = ({
  proposal,
  contract,
  companyInfo,
  paymentMethods,
  selectedOptions,
  setChangeRenewalState,
  changeRenewalState,
  enabledForOtp,
  setSelectedMethodId,
}: Props) => {
  const { enableChangeProposalPaymentMethods } = useFlags();
  const hasAnAcceptedStatus = hasAnAcceptedProposalStatus(proposal);
  const isAccepted = isAcceptedProposalStatus(proposal);

  const isPendingRenewal = isPendingRenewalProposalStatus(proposal);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
    undefined | CheckoutPaymentMethod
  >(undefined);
  const isPreview = useIsPreview();

  useEffect(() => {
    const selectedPayment =
      (hasAnAcceptedStatus && paymentMethods.find(getSelectedBillingMethod)) ||
      contract?.billingMethod?.paymentMethod;

    if (selectedPayment && !selectedPayment.configuration) {
      // merge configuration from CheckoutPaymentMethod
      const paymentMethod = paymentMethods.find(
        (pm) => pm.id === selectedPayment.id
      );
      selectedPayment.configuration = paymentMethod?.configuration;
    }

    setSelectedPaymentMethod(selectedPayment);
    setShowLink(typeof selectedPaymentMethod !== 'undefined');
  }, [proposal, contract]);

  useEffect(() => {
    if (selectedPaymentMethod) {
      setShowLink(typeof selectedPaymentMethod !== 'undefined');
    }
  }, [selectedPaymentMethod]);

  const paymentType = getPaymentType(selectedPaymentMethod);
  const paymentSchedule = selectedOptions.selectedSchedule;

  const [showLink, setShowLink] = useState(
    typeof selectedPaymentMethod !== 'undefined'
  );

  const isViewingFinalizedProposal =
    isAccepted && !!proposal.billingMethod?.paymentMethod;

  const isViewingAcceptedWithoutBilling =
    isAccepted &&
    (enableChangeProposalPaymentMethods && proposal.proposalType === 'change'
      ? !contract?.billingMethod?.paymentMethod
      : !proposal.billingMethod?.paymentMethod);

  const isCurrent = isCurrentPaymentMethod(
    isAccepted,
    isViewingAcceptedWithoutBilling,
    isPendingRenewal,
    contract,
    selectedPaymentMethod
  );

  const isButtonEnabled =
    !isPreview &&
    isSubmitButtonEnabled(
      changeRenewalState,
      showLink,
      isViewingFinalizedProposal,
      isViewingAcceptedWithoutBilling,
      isPendingRenewal,
      enabledForOtp,
      contract
    );

  const isMethodsEnabled = isBillingMethodsEnabled(
    changeRenewalState,
    isViewingFinalizedProposal,
    isViewingAcceptedWithoutBilling,
    isPendingRenewal,
    enabledForOtp,
    contract,
    selectedPaymentMethod
  );
  const showCancelButton =
    isViewingAcceptedWithoutBilling ||
    (showLink && changeRenewalState.isClicked && !isViewingFinalizedProposal);

  const resetChangeRenewalState = {
    isClicked: false,
    isDisabled: true,
    isEditable: false,
    isCanceled: true,
  };

  const handleOnSubmit = () => {
    setChangeRenewalState(resetChangeRenewalState);
  };

  const handleOnClose = () => {
    setChangeRenewalState({
      ...changeRenewalState,
      ...resetChangeRenewalState,
    });
    if (changeRenewalState.originalPaymentSelection) {
      setSelectedPaymentMethod(changeRenewalState.originalPaymentSelection);
    }
  };

  const getMethodDetail = () => {
    switch (paymentType) {
      case 'ach':
        return (
          <ACH
            proposal={proposal}
            isEditable={isButtonEnabled}
            companyInfo={companyInfo}
            paymentMethod={selectedPaymentMethod}
            isCurrent={isCurrent}
            contract={contract}
            showCancelButton={showCancelButton}
            onClose={handleOnClose}
            onSubmit={handleOnSubmit}
          />
        );
      case 'direct_debit':
        return (
          <DirectDebit
            proposal={proposal}
            isEditable={isButtonEnabled}
            companyInfo={companyInfo}
            paymentSchedule={paymentSchedule}
            paymentMethod={selectedPaymentMethod}
            isCurrent={isCurrent}
            contract={contract}
            showCancelButton={showCancelButton}
            onClose={handleOnClose}
            onSubmit={handleOnSubmit}
          />
        );
      case 'wire':
        return <Wire />;
      case 'cc':
        return (
          <CreditCard
            proposal={proposal}
            isEditable={isButtonEnabled}
            paymentMethods={paymentMethods}
            companyInfo={companyInfo}
            paymentSchedule={paymentSchedule}
            paymentMethod={selectedPaymentMethod}
            isCurrent={isCurrent}
            contract={contract}
            showCancelButton={showCancelButton}
            onClose={handleOnClose}
            onSubmit={handleOnSubmit}
            isChangePaymentMethodClicked={changeRenewalState.isClicked}
          />
        );
      case 'check':
        return <Check />;
      case 'manual':
        return (
          <Manual
            proposal={proposal}
            paymentMethod={selectedPaymentMethod}
            isEditable={isButtonEnabled}
            isCurrent={isCurrent}
            contract={contract}
            showCancelButton={showCancelButton}
            onClose={handleOnClose}
            onSubmit={handleOnSubmit}
          />
        );
      case 'custom':
        return (
          <Custom
            proposal={proposal}
            paymentMethod={selectedPaymentMethod}
            isEditable={isButtonEnabled}
            isCurrent={isCurrent}
            contract={contract}
            showCancelButton={showCancelButton}
            onClose={handleOnClose}
            onSubmit={handleOnSubmit}
          />
        );
      case 'none_selected':
        return <NoneSelected />;
      default:
        return 'Not Supported';
    }
  };

  function getSelectedBillingMethod(
    paymentMethod: CheckoutPaymentMethod
  ): boolean {
    // Looking at a finalized proposal
    if (isAccepted && proposal.billingMethod) {
      return selectedOptions.selectedMethodId === paymentMethod.id;
    }

    // Initially load our billingMethod selection
    if (hasAnAcceptedStatus && !changeRenewalState.isClicked) {
      return paymentMethod.id === proposal.billingMethod?.paymentMethod.id;
    }

    // Non-accepted proposal, if we have a contract compare paymentMethod against original selection
    if (contract && selectedPaymentMethod) {
      return paymentMethod.id === selectedPaymentMethod.id;
    }

    return isSelected(paymentMethod, selectedOptions, changeRenewalState);
  }

  const handleEnableSelections = () => {
    const originalMethod = selectedPaymentMethod;
    // Check if they are using a now unsupported payment method
    if (!paymentMethods.find(getSelectedBillingMethod)) {
      setSelectedPaymentMethod(paymentMethods[0]);
    }
    setChangeRenewalState({
      isDisabled: false,
      isEditable: true,
      isClicked: true,
      originalPaymentSelection: originalMethod,
    });
  };

  return (
    <div className="break-inside-avoid mt-8">
      <span className="flex items-baseline w-[450px] justify-between">
        <h2 className="text-xl font-extrabold mb-4">
          {!changeRenewalState.isClicked
            ? 'Payment methods'
            : 'How would you like to pay?'}
        </h2>

        {showLink &&
          !changeRenewalState.isClicked &&
          !isViewingFinalizedProposal && (
            <span
              onClick={handleEnableSelections}
              className="font-medium text-blue hover:underline pointer-events-auto cursor-pointer"
            >
              Change payment method
            </span>
          )}
      </span>

      <div className="grid gap-0 grid-cols-1 sm:grid-cols-2 sm:gap-6">
        <div>
          {getEnabledMethodsForProposal(paymentMethods, selectedOptions)?.map(
            (paymentMethod) => {
              const isMethodSelected = getSelectedBillingMethod(paymentMethod);

              return (
                <Method
                  key={paymentMethod.id}
                  isDisabled={!isMethodsEnabled}
                  isSelected={isMethodSelected}
                  paymentMethod={paymentMethod}
                  dataTestId={paymentMethod.paymentType + '-method'}
                  onClick={() => {
                    if (!changeRenewalState.isClicked) {
                      handleEnableSelections();
                    }

                    setSelectedPaymentMethod(paymentMethod);
                  }}
                  setSelectedMethodId={setSelectedMethodId}
                />
              );
            }
          )}
        </div>

        <div>{getMethodDetail()}</div>
      </div>
    </div>
  );
};

export default ProposalTypeMethods;

const isCurrentPaymentMethod = (
  isAccepted: boolean,
  isViewingAcceptedWithoutBilling: boolean,
  isPendingRenewal: boolean,
  contract?: Contract,
  paymentMethod?: CheckoutPaymentMethod
) => {
  if (isPendingRenewal) {
    return false;
  }

  // Proposal is accepted but no billing method on proposal
  // Proposal with signing and accept on signing enabled
  if (isViewingAcceptedWithoutBilling) {
    return false;
  }

  if (!isAccepted) {
    return false;
  }

  return contract?.billingMethod?.paymentMethod.name === paymentMethod?.name;
};

const getPaymentType = (selectedPaymentMethod?: CheckoutPaymentMethod) => {
  return !selectedPaymentMethod
    ? 'none_selected'
    : selectedPaymentMethod.paymentType;
};

const isSubmitButtonEnabled = (
  changeRenewalState: ChangeRenewalStateType,
  showLink: boolean,
  isViewingFinalizedProposal: boolean,
  isViewingAcceptedWithoutBilling: boolean,
  isPendingRenewal: boolean,
  enabledForOtp: boolean,
  contract?: Contract
) => {
  if (enabledForOtp) {
    return true;
  }

  // Can happen when accept on signing is enabled
  // Proposal is accepted but no billing method on proposal
  // Need to enable submit button to select a payment method
  if (isViewingFinalizedProposal) {
    return false;
  }

  if (isViewingAcceptedWithoutBilling) {
    return true;
  }

  if (isPendingRenewal) {
    return true;
  }

  if (contractNeedsPayment(contract)) {
    return true;
  }

  if (showLink) {
    return true;
  }

  return changeRenewalState.isEditable;
};

const isBillingMethodsEnabled = (
  changeRenewalState: ChangeRenewalStateType,
  isViewingFinalizedProposal: boolean,
  isViewingAcceptedWithoutBilling: boolean,
  isPendingRenewal: boolean,
  enabledForOtp: boolean,
  contract?: Contract,
  selectedPaymentMethod?: CheckoutPaymentMethod
) => {
  if (enabledForOtp) {
    return true;
  }

  if (isViewingFinalizedProposal) {
    return false;
  }

  if (isViewingAcceptedWithoutBilling && changeRenewalState.isClicked) {
    return true;
  }

  if (isViewingAcceptedWithoutBilling) {
    return true;
  }

  if (isPendingRenewal && changeRenewalState.isClicked) {
    return true;
  }

  // If our previous payment method is no longer available
  // we need to be enabled to select a new one
  if (isPendingRenewal && !selectedPaymentMethod) {
    return true;
  }

  if (isPendingRenewal) {
    return false;
  }

  if (contractNeedsPayment(contract)) {
    return true;
  }

  // If no payment method, needs to be enabled to select one
  if (!selectedPaymentMethod) {
    return true;
  }

  return changeRenewalState.isEditable;
};

const contractNeedsPayment = (contract?: Contract) => {
  return contract && !contract.billingMethod;
};

const isSelected = (
  paymentMethod: CheckoutPaymentMethod,
  selectedOptions: SelectedOptionProps,
  changeRenewalState: ChangeRenewalStateType
) => {
  if (
    changeRenewalState.isCanceled &&
    changeRenewalState.originalPaymentSelection
  ) {
    return changeRenewalState.originalPaymentSelection.id === paymentMethod.id;
  }

  return selectedOptions.selectedMethodId === paymentMethod.id;
};
