import React from 'react';
import {
  Button,
  Icon,
  isValidAddress,
  Proposal,
  getIsSignatureRequired,
} from 'common';
import clsx from 'clsx';
import { getIsPdf } from '../utils/pdf';
import ShippingAddressModal from './ShippingAddressModal';

interface Props {
  className?: string;
  proposal: Proposal;
}

const MissingShippingAddressButton: React.FC<Props> = ({
  proposal,
  className,
}) => {
  const [isEditing, setIsEditing] = React.useState(false);
  const isPdf = getIsPdf();
  const hasShippingAddress = isValidAddress(proposal.customer?.shippingAddress);

  if (isPdf || hasShippingAddress) {
    return null;
  }

  return (
    <>
      <ShippingAddressModal
        hasSigning={getIsSignatureRequired(proposal)}
        isOpen={isEditing}
        proposal={proposal}
        setIsOpen={setIsEditing}
      />
      <Button
        className={clsx('print:hidden', className)}
        dataTestId="add-shipping-address"
        iconBefore={Icon.Edit}
        label="Add shipping address"
        onClick={() => setIsEditing(true)}
      />
    </>
  );
};

export default MissingShippingAddressButton;
