import { QueryClient, useQuery } from '@tanstack/react-query';
import { Signature } from 'common';

const DEFAULT_SIGNATURE_KEY = ['default-signature'];

export const useDefaultSignature = () =>
  useQuery<Signature>({
    queryKey: DEFAULT_SIGNATURE_KEY,
    queryFn: () => {
      // NOTE: buyers don't have access to default signature API yet, we only support preload for now.
      return {};
    },
  });

export const setDefaultSignature = (qc: QueryClient, signature: Signature) => {
  qc.setQueryData(DEFAULT_SIGNATURE_KEY, signature);
};
