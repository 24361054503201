import { ENVIRONMENT_CONFIG } from '../config/hosts';

const lastSegment = (str: string) => str.substring(str.indexOf('/') + 1);

// the last segment in the branch name is the version. ex release/0.11.2
const version = lastSegment(process.env.BRANCH || 'dev');

export const VERSION_INFO = {
  name: 'checkout',
  version,
  env: ENVIRONMENT_CONFIG.env,
  branch: process.env.BRANCH,
  sha: process.env.COMMIT_HASH,
  buildTime: process.env.BUILD_TIME,
};

export const getVersion = () => JSON.stringify(VERSION_INFO, null);
