import React, { useState } from 'react';
import clsx from 'clsx';
import {
  CheckoutPaymentMethod,
  CompanyInfo,
  Contract,
  hasAnAcceptedProposalStatus,
  Proposal,
  useFlags,
  getIsSignatureRequired,
} from 'common';
import styles from './App.module.scss';
import AcceptChangeSection from './components/AcceptChangeSection/AcceptChangeSection';
import AcceptedBySection from './components/AcceptedBySection';
import { Appendix } from './components/Appendix/Appendix';
import AttachmentsSection from './components/Attachments/AttachmentsSection';
import { AutoRenewalSection } from './components/AutoRenewalSection/AutoRenewalSection';
import BillingInfoSection from './components/BillingInfoSection';
import ChangeTopDrawer from './components/ChangeTopDrawer/ChangeTopDrawer';
import CheckoutFooter from './components/CheckoutFooter';
import CongratulationsPrompt from './components/CongratulationsPrompt';
import CoverLetterSection from './components/CoverLetterSection';
import CustomTermsSection from './components/CustomTermsSection';
import DisableInPreview from './components/DisableInPreview';
import Header from './components/Header/Header';
import StatusBar from './components/Header/StatusBar';
import PaymentScheduleSection from './components/PaymentPlan/PaymentScheduleSection';
import PaymentSelectionSection from './components/PaymentSection/PaymentSelectionSection';
import PoweredBy from './components/PoweredBy';
import PrintWrapper from './components/PrintWrapper';
import { ProductsSection } from './components/ProductsSection';
import ProductsSectionDeprecated from './components/ProductsSectionDeprecated';
import SignedBillingPrompt from './components/Signing/SignedBillingPrompt';
import SigningSection from './components/Signing/SigningSection';
import { SelectedOptionProps } from './types/model';
import { getIsPdf } from './utils/pdf';
import { isProposalNeedingBillingAfterApproval } from './utils/proposalUtils';
import { useIsPreview } from './utils/viewMode';
import { getIsAwaitingSignature, hasValidCustomerInfo } from './utils/workflow';

interface Props {
  companyInfo: CompanyInfo;
  contract: Contract | undefined;
  paymentMethods: CheckoutPaymentMethod[];
  proposal: Proposal;
  selectedOptions: SelectedOptionProps;
  setSelectedOptions: (selectedOptions: SelectedOptionProps) => void;
}

const Checkout: React.FC<Props> = ({
  companyInfo,
  contract,
  paymentMethods,
  proposal,
  selectedOptions,
  setSelectedOptions,
}) => {
  const isSignatureRequired = getIsSignatureRequired(proposal);
  const isPdf = getIsPdf();
  const isPreview = useIsPreview();
  const needsBillingModal: boolean =
    isProposalNeedingBillingAfterApproval(contract, proposal) &&
    !isPreview &&
    !getIsAwaitingSignature(proposal) &&
    isSignatureRequired;
  const isBillingEditable: boolean =
    (!proposal.billTo && !hasAnAcceptedProposalStatus(proposal)) ||
    needsBillingModal;
  const [isBillingInfoBeingEdited, setIsBillingInfoBeingEdited] = useState(
    isBillingEditable && !hasValidCustomerInfo(proposal)
  );
  const [showSetupPaymentModal, setShowSetupPaymentModal] =
    useState(needsBillingModal);
  const { enableChangeProposalPaymentMethods, enhancedSpans } = useFlags();
  const useOldProducts = proposal.legacyCheckout || !enhancedSpans;

  return (
    <PrintWrapper>
      <div className={clsx(styles.app, 'sm:px-6 py-8 print:p-0')}>
        <ChangeTopDrawer companyInfo={companyInfo} proposal={proposal} />

        <div className="m-auto mw">
          <div className="sm:pb-0 print:hidden">
            <PoweredBy companyInfo={companyInfo} />
          </div>
          <CoverLetterSection proposal={proposal} />
          <div className="shadow-xl print:shadow-none rounded-md overflow-clip">
            {hasAnAcceptedProposalStatus(proposal) && (
              <StatusBar status={proposal.status} />
            )}

            <DisableInPreview>
              <Header
                companyInfo={companyInfo}
                proposal={proposal}
                selectedOptions={selectedOptions}
              />
            </DisableInPreview>

            <div className={clsx(styles.paper, 'py-10 bg-white pb-10')}>
              {!useOldProducts && (
                <ProductsSection
                  isPdf={isPdf}
                  isReadOnly
                  proposal={proposal}
                  selectedOptions={selectedOptions}
                />
              )}
              <DisableInPreview>
                <div className={styles.paddingX}>
                  {useOldProducts && (
                    <ProductsSectionDeprecated
                      proposal={proposal}
                      selectedOptions={selectedOptions}
                    />
                  )}

                  <CustomTermsSection proposal={proposal} />

                  <CongratulationsPrompt
                    contract={contract}
                    proposal={proposal}
                  />

                  <AttachmentsSection proposal={proposal} />

                  <AutoRenewalSection proposal={proposal} />
                </div>
              </DisableInPreview>
              <div className={styles.paddingX}>
                <PaymentScheduleSection
                  paymentMethods={paymentMethods}
                  proposal={proposal}
                  selectedOptions={selectedOptions}
                  setSelectedOptions={setSelectedOptions}
                />

                <DisableInPreview>
                  <BillingInfoSection
                    editable={isBillingEditable}
                    isBillingInfoBeingEdited={isBillingInfoBeingEdited}
                    isPdf={isPdf}
                    proposal={proposal}
                    setIsBillingInfoBeingEdited={setIsBillingInfoBeingEdited}
                  />

                  <SigningSection
                    companyInfo={companyInfo}
                    onClose={() => {
                      setShowSetupPaymentModal(false);
                    }}
                    proposal={proposal}
                  />

                  <AcceptedBySection proposal={proposal} />

                  {enhancedSpans && (
                    <Appendix isPdf={isPdf} proposal={proposal} />
                  )}

                  <PaymentSelectionSection
                    companyInfo={companyInfo}
                    contract={contract}
                    isBillingInfoBeingEdited={isBillingInfoBeingEdited}
                    paymentMethods={paymentMethods}
                    proposal={proposal}
                    selectedOptions={selectedOptions}
                    setSelectedOptions={setSelectedOptions}
                  />

                  {!enableChangeProposalPaymentMethods && (
                    <AcceptChangeSection
                      contract={contract}
                      proposal={proposal}
                    />
                  )}

                  <SignedBillingPrompt
                    closeModalCallback={setShowSetupPaymentModal}
                    isOpen={showSetupPaymentModal}
                    onClose={() => setShowSetupPaymentModal(false)}
                    proposal={proposal}
                  />
                </DisableInPreview>
              </div>
            </div>
          </div>

          <CheckoutFooter proposal={proposal} />
        </div>
      </div>
    </PrintWrapper>
  );
};

export default Checkout;
