import React, { useState } from 'react';
import { useAuth, useOtpToken } from '../../Auth';
import OtpModal from '../Otp/OtpModal';
import { Button, hasAnAcceptedProposalStatus, log, Proposal } from 'common';

const useUpdateMethod = (proposal: Proposal) => {
  const auth = useAuth();
  const isAuthorized = () => auth.role === 'buyer';
  const hasAnAcceptedStatus = hasAnAcceptedProposalStatus(proposal);
  const [sendOtp, setSendOtp] = useState(false);
  const { error } = useOtpToken(proposal.id, sendOtp);
  const [isOtpOpen, setIsOtpOpen] = useState(false);

  const handleOtpClose = () => {
    setIsOtpOpen(false);
    setSendOtp(false);
  };

  const sendAndOpenModal = () => {
    if (!hasAnAcceptedStatus || sendOtp) return;
    if (!isAuthorized()) {
      setSendOtp(true);
      log.debug('sending code, error  : ' + error);
      setIsOtpOpen(true);
    }
  };

  const modal = () => {
    return (
      proposal.id && (
        <OtpModal
          proposalId={proposal.id}
          isOpen={isOtpOpen}
          handleClose={handleOtpClose}
        />
      )
    );
  };

  const paymentUpdateMessage = (
    baseText: React.ReactNode,
    successMesg?: string
  ) => {
    if (isAuthorized()) {
      return (
        <>
          {baseText} You are now authenticated to update the payment information
          below.
        </>
      );
    }

    return (
      <>
        {successMesg && (
          <div className="pb-2 mt-2 text-sm text-green">{successMesg}</div>
        )}
        {baseText} Please click the "Request passcode" button to update your
        payment information.
      </>
    );
  };

  const paymentUpdateSection = (
    baseText: React.ReactNode,
    successMesg?: string
  ) => {
    return (
      <>
        <div className="h-4" />
        <div
          className={`flex flex-row items-center justify-between w-full mt-2 text-sm rounded-md shadow-md ${
            isAuthorized() ? 'bg-green-lightest' : 'bg-orange-lightest'
          } break-inside-avoid print:hidden`}
        >
          <div className="flex flex-col w-3/4 p-3">
            {paymentUpdateMessage(baseText, successMesg)}
          </div>

          <div className="flex flex-col w-1/4 p-3">
            <Button
              label="Request passcode"
              isDisabled={isAuthorized()}
              onClick={sendAndOpenModal}
            />
          </div>
        </div>
      </>
    );
  };

  return {
    paymentUpdateSection,
    modal,
    isAuthorized,
  };
};

export default useUpdateMethod;
