import React from 'react';
import { getVersion } from './utils/version';

const ProjectStatus: React.FC = () => (
  <div style={{ padding: 10 }}>
    <p>{getVersion()}</p>
  </div>
);

export default ProjectStatus;
