// Used to link plaid component and handle the onsuccess event

import { PaymentMethodInitResponse, Proposal } from 'common';

import {
  PlaidLinkError,
  PlaidLinkOnExitMetadata,
  PlaidLinkOnSuccessMetadata,
  usePlaidLink,
} from 'react-plaid-link';
import { getProposalUrl } from './proposal';

import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { buyerAxios } from './api';

const initPaymentMethodDDUrl = (proposalId: string) => {
  return getProposalUrl(proposalId) + '/payment-method/direct_debit/init';
};

const usePlaidToken = (
  proposal: Proposal
): UseQueryResult<PaymentMethodInitResponse> =>
  useQuery({
    queryKey: [initPaymentMethodDDUrl(proposal.id)],
    queryFn: async () => {
      const { data } = await buyerAxios.post(
        initPaymentMethodDDUrl(proposal.id)
      );
      return data as PaymentMethodInitResponse;
    },
  });

const usePlaid = (
  preview: boolean,
  proposal: Proposal,
  onSuccessAction?: (
    plaidToken: string,
    metadata: PlaidLinkOnSuccessMetadata
  ) => void,
  onFailureAction?: (
    error: PlaidLinkError,
    metadata: PlaidLinkOnExitMetadata
  ) => void
) => {
  // onSuccess event after plaid account integration
  const onSuccess = (
    // eslint-disable-next-line camelcase
    public_token: string,
    metadata: PlaidLinkOnSuccessMetadata
  ) => {
    if (onSuccessAction) {
      onSuccessAction(public_token, metadata);
    }
  };

  // onExit event after plaid account integration
  const onExit = (
    error: PlaidLinkError | null,
    metadata: PlaidLinkOnExitMetadata
  ) => {
    if (error && onFailureAction) {
      onFailureAction(error, metadata);
    }
  };

  // get the plaid token
  const plaidSetup = (p: Proposal) => {
    if (preview) {
      return {
        ready: false,
        open: undefined,
        error: undefined,
      };
    }

    const response = usePlaidToken(p);
    const linkToken = response.data?.clientSecret;

    const config: Parameters<typeof usePlaidLink>[0] = {
      token: linkToken || 'null', // TODO better error handling here
      onSuccess,
      onExit,
    };

    return usePlaidLink(config);
  };

  const { open, ready, error } = plaidSetup(proposal);

  return {
    open,
    ready,
    error,
  };
};

export default usePlaid;
