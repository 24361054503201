import { allFlagsOff, defaultTestFlags, Flags, log } from 'common';

const ENVIRONMENT = {
  local: 'local',
  development: 'development',
  ci: 'ci',
  dev: 'development',
  demo: 'demo',
  sandbox: 'sandbox',
  staging: 'staging',
  production: 'production',
  prod: 'production',
};

const currentEnvironment =
  // @ts-ignore Unsure how to configure ENVIRONMENT_NAME as a global
  ENVIRONMENT_NAME || process.env.NODE_ENV || ENVIRONMENT.local;

log.info('Environment = ' + currentEnvironment);

interface EnvironmentConfig {
  cfAppHost: string;
  cfSsrHost: string;
  cfBaseAppHost: string;
  checkoutHost: string;
  defaultFlags: Flags;
  dsIntegrationKey: string;
  env: string;
  isCi: boolean;
  isMonitored: boolean;
  isProduction: boolean;
  isLocal: boolean;
  ldClientSecret: string | undefined;
  sentryKey: string;
  shouldUseHttps: boolean;
  shouldUseCheckoutHttps: boolean;
  userflowKey: string;
  postHogPublicKey: string;
  postHogApiHost: string;
  isAnalyticsEnabled?: boolean;
}

const defaultKeys: Pick<EnvironmentConfig, 'dsIntegrationKey' | 'userflowKey'> =
  {
    dsIntegrationKey: '48ae54ee-aebc-4cbe-91dc-3e8149c40702',
    userflowKey: 'ct_lk3tl4ndfraz5c2uuzv7nvrozq', // this is the dev key
  };

const baseConfig = {
  ...defaultKeys,
  env: currentEnvironment,
  isLocal: process.env.BRANCH === 'local',
  isCi: currentEnvironment === ENVIRONMENT.ci,
  isMonitored: process.env.BRANCH !== 'local',
  ldClientSecret: process.env.LD_SECRET_KEY || undefined,
  sentryKey:
    process.env.SENTRY_KEY ||
    'https://5d69d8a1e900426eb47fa8bb9eb0b4dd@o1012459.ingest.sentry.io/5977924',
  userflowKey: process.env.USERFLOW_KEY || defaultKeys.userflowKey,
  dsIntegrationKey:
    process.env.DS_INTEGRATION_KEY || defaultKeys.dsIntegrationKey,
  postHogPublicKey:
    process.env.REACT_APP_PUBLIC_POSTHOG_KEY ||
    'phc_HerKBHLkm6NwQkOnxJCdfZUEb6eLHOVxLwjYmfcABfC',
  postHogApiHost:
    process.env.REACT_APP_PUBLIC_POSTHOG_HOST || 'https://us.i.posthog.com',
  isAnalyticsEnabled: process.env.BRANCH !== 'local',
};

const localConfig = {
  ...baseConfig,
  shouldUseHttps: false,
  shouldUseCheckoutHttps: false,
  isMonitored: false,
  cfAppHost: 'localhost:8080',
  cfSsrHost: 'localhost:3001',
  cfBaseAppHost: 'localhost:3042',
  checkoutHost: 'checkout.localhost:3000',
  isProduction: false,
  defaultFlags: defaultTestFlags,
  isAnalyticsEnabled: false,
};

const environmentConfig: { [key: string]: EnvironmentConfig } = {
  [ENVIRONMENT.local]: localConfig,
  [ENVIRONMENT.development]: {
    ...baseConfig,
    shouldUseHttps: true,
    shouldUseCheckoutHttps: true,
    cfAppHost: 'api.dev.getcacheflow.com',
    cfSsrHost: 'ssr.dev.getcacheflow.com',
    cfBaseAppHost: 'app.dev.getcacheflow.com',
    checkoutHost: 'checkout.dev.getcacheflow.com',
    isProduction: false,
    defaultFlags: defaultTestFlags,
  },
  [ENVIRONMENT.ci]: {
    ...baseConfig,
    env: 'development',
    shouldUseHttps: true,
    shouldUseCheckoutHttps: false,
    cfAppHost: 'api.dev.getcacheflow.com',
    cfSsrHost: 'ssr.dev.getcacheflow.com',
    cfBaseAppHost: 'app.dev.getcacheflow.com',
    checkoutHost: 'checkout.localhost:3000',
    isProduction: false,
    defaultFlags: { ...defaultTestFlags },
  },
  [ENVIRONMENT.demo]: {
    ...baseConfig,
    shouldUseHttps: true,
    shouldUseCheckoutHttps: true,
    cfAppHost: 'api.demo.getcacheflow.com',
    cfSsrHost: 'ssr.demo.getcacheflow.com',
    cfBaseAppHost: 'app.demo.getcacheflow.com',
    checkoutHost: 'checkout.demo.getcacheflow.com',
    isProduction: false,
    defaultFlags: { ...defaultTestFlags },
  },
  [ENVIRONMENT.sandbox]: {
    ...baseConfig,
    shouldUseHttps: true,
    shouldUseCheckoutHttps: true,
    cfAppHost: 'api.sandbox.getcacheflow.com',
    cfSsrHost: 'ssr.sandbox.getcacheflow.com',
    cfBaseAppHost: 'app.sandbox.getcacheflow.com',
    checkoutHost: 'checkout.sandbox.getcacheflow.com',
    isProduction: true,
    defaultFlags: allFlagsOff,
    userflowKey: 'ct_2agotgq7qvdjjkh2u6q4tcd5zu', // this is the prod key
  },
  [ENVIRONMENT.staging]: {
    ...baseConfig,
    shouldUseHttps: true,
    shouldUseCheckoutHttps: true,
    cfAppHost: 'api.staging.getcacheflow.com',
    cfSsrHost: 'ssr.staging.getcacheflow.com',
    cfBaseAppHost: 'app.staging.getcacheflow.com',
    checkoutHost: 'checkout.staging.getcacheflow.com',
    isProduction: true,
    defaultFlags: allFlagsOff,
  },
  [ENVIRONMENT.production]: {
    ...baseConfig,
    shouldUseHttps: true,
    shouldUseCheckoutHttps: true,
    cfAppHost: 'api.getcacheflow.com',
    cfSsrHost: 'ssr.getcacheflow.com',
    cfBaseAppHost: 'app.getcacheflow.com',
    checkoutHost: 'checkout.getcacheflow.com',
    isProduction: true,
    defaultFlags: allFlagsOff,
    userflowKey: 'ct_2agotgq7qvdjjkh2u6q4tcd5zu', // this is the prod key
  },
};

export const ENVIRONMENT_CONFIG =
  environmentConfig[currentEnvironment] || localConfig;

if (!ENVIRONMENT_CONFIG.isProduction) {
  log.setLevel('trace');
  log.debug(ENVIRONMENT_CONFIG);
} else {
  log.setLevel('info');
}

let localEnv = undefined;
if (ENVIRONMENT.development === currentEnvironment) {
  localEnv = environmentConfig[ENVIRONMENT.local];
}
export const LOCAL_ENVIRONMENT_CONFIG = localEnv;

export const isLocalOrDevEnvironment = () => {
  return (
    currentEnvironment === ENVIRONMENT.local ||
    currentEnvironment === ENVIRONMENT.development
  );
};
