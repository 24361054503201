import React from 'react';
import { Bordered } from 'common';
import clsx from 'clsx';
import styles from './ProposalLayout.module.scss';

interface Props extends React.ComponentProps<typeof Bordered> {
  children: React.ReactNode;
  noPadding?: boolean;
}

const TwoColumn: React.FC<Props> = ({
  noPadding,
  className,
  ...passDownProps
}) => (
  <Bordered
    {...passDownProps}
    className={clsx(styles.twoColumn, !noPadding && styles.padding, className)}
  />
);

export default {
  TwoColumn,
};
