import React, { SVGProps } from 'react';
import { noop, SIZE_SMALL, Type } from 'common';
import clsx from 'clsx';

import styles from './HeaderPillButton.module.scss';

interface Props {
  className?: string;
  dataTestId?: string;
  icon: React.ComponentType<SVGProps<SVGSVGElement>>;
  isDisabled?: boolean;
  label: string;
  onClick: () => void;
}

const HeaderPillButton: React.FC<Props> = ({
  className,
  dataTestId,
  icon: IconProp,
  isDisabled,
  label,
  onClick,
}) => {
  return (
    <div
      className={clsx(
        styles.headerPillButton,
        className,
        isDisabled && styles.disabled
      )}
      data-testid={dataTestId}
      onClick={isDisabled ? noop : onClick}
    >
      <IconProp {...SIZE_SMALL} />
      <Type size={12} semiBold>
        {label}
      </Type>
    </div>
  );
};

export default HeaderPillButton;
