import React from 'react';

import {
  Item,
  ItemSchedule,
  SimpleTable,
  useCustomizeTieredPriceModal,
} from 'common';

import styles from './TieredPriceTableItem.module.scss';

interface TieredPriceTableItemProps {
  schedule: ItemSchedule;
  item: Item;
}

const TieredPriceTableItem = ({
  schedule,
  item,
}: TieredPriceTableItemProps) => {
  const { rows, columns, itemName } = useCustomizeTieredPriceModal(
    item,
    schedule
  );

  return (
    <div className={styles.container}>
      <div className={styles.nameRow}>
        <b>{itemName}</b>
        <div className={styles.pill}>{item.product.recurrence}</div>
      </div>

      <SimpleTable cells={columns} disableSearch disableShadow rows={rows} />
    </div>
  );
};

export default TieredPriceTableItem;
