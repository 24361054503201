import React from 'react';
import { Button, ButtonBar, FormField, Input, Modal, useToast } from 'common';

interface Props {
  handleClose: () => void;
  isOpen: boolean;
}

const SomeoneElseModal = ({ isOpen, handleClose }: Props) => {
  const showToast = useToast();

  const copyUrl = async () => {
    const linkUrl = window.location.href;
    await navigator.clipboard.writeText(linkUrl);
    showToast.success('Copied to clipboard!');
  };

  return (
    <Modal
      isOpen={isOpen}
      footer={
        <ButtonBar>
          <Button label="Copy link" onClick={copyUrl} type="secondary" />
          <Button label="Close" onClick={handleClose} />
        </ButtonBar>
      }
      onClose={handleClose}
      header="Need someone else to sign?"
    >
      <FormField label="No problem. Just share the link below with the correct signer.">
        <Input value={window.location.href} />
      </FormField>
    </Modal>
  );
};

export default SomeoneElseModal;
