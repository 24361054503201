import React from 'react';
import {
  BillingPeriodsType,
  getProductSpanStartIndex,
  isChangeProposalType,
  PaymentSchedule,
  ProductsDeprecated,
  Proposal,
  useFlags,
} from 'common';
import SubTitle from './SubTitle';
import { SelectedOptionProps } from '../types/model';

interface Props {
  proposal: Proposal;
  selectedOptions?: SelectedOptionProps;
}

const ProductsSectionDeprecated: React.FC<Props> = ({
  proposal,
  selectedOptions,
}) => {
  const items = proposal.proposalItems;
  const billingPeriod: PaymentSchedule['payEvery'] | undefined =
    selectedOptions?.selectedSchedule?.payEvery ||
    proposal.defaultBillingPeriod;
  const isChangeProposal = isChangeProposalType(proposal);
  const { hideUnchangedItems } = useFlags();

  if (!billingPeriod) {
    return null;
  }

  const getStartIndex = (bp: BillingPeriodsType): number | undefined =>
    isChangeProposal ? getProductSpanStartIndex(proposal, bp) : 0;

  const buildHeaderLabel = (name: string) => {
    return <SubTitle title={name} />;
  };

  return (
    <div className="mt-4 overflow-x-auto">
      <ProductsDeprecated
        itemChanges={proposal.changes?.itemChanges}
        isDisabled
        items={items}
        buildHeaderLabel={buildHeaderLabel}
        billingPeriod={billingPeriod}
        showCustomizations={false}
        hideDiscounts={proposal.options?.hideDiscounts ?? false}
        hideUnchangedItems={hideUnchangedItems}
        getStartIndex={getStartIndex}
        isCheckout
        proposal={proposal}
        isChangeType={isChangeProposal}
      />
    </div>
  );
};

export default ProductsSectionDeprecated;
