import DoneButton from './DoneButton';
import {
  AcceptRequest,
  BillingMethodRequest,
  Button,
  CreateContractRequest,
  Input,
} from 'common';
import React, { useState } from 'react';
import { MethodProps } from './methodUtils';

const Manual = ({
  paymentMethod,
  proposal,
  contract,
  isEditable,
  isCurrent,
  onClose,
  showCancelButton,
}: MethodProps) => {
  const [virtualAccountName, setVirtualAccountName] = useState('');

  const enrichRequest = (request: CreateContractRequest) => {
    return {
      ...request,
      paymentMethod: {
        ...request.paymentMethod,
        virtualAccount: {
          name: virtualAccountName,
        },
      },
    };
  };

  const enrichUpdateRequest = (request: BillingMethodRequest) => {
    return {
      ...request,
      virtualAccount: {
        name: virtualAccountName,
      },
    };
  };

  const enrichRenewalRequest = (request: AcceptRequest) => {
    return {
      billingMethodRequest: {
        paymentMethodId: request.billingMethodRequest?.paymentMethodId,
        virtualAccount: {
          name: virtualAccountName,
        },
      },
    };
  };

  if (!paymentMethod || !paymentMethod.id) {
    return null;
  }

  return (
    <div className="xs:mt-8 sm:mt-0">
      <p className="mb-4 font-bold xs:text-sm sm:text-lg">
        Set up payments to a virtual account
      </p>
      <div>
        <label className="font-medium ">Virtual account name</label>
        <Input
          placeholder="Enter name"
          value={virtualAccountName}
          onChange={(newValue) => setVirtualAccountName(newValue)}
        />
      </div>

      <div className="pt-2 flex">
        <DoneButton
          isDisabled={virtualAccountName === '' || !isEditable || isCurrent}
          proposal={proposal}
          contract={contract}
          paymentMethodId={paymentMethod.id}
          createContractRequestEnricher={enrichRequest}
          updateContractRequestEnricher={enrichUpdateRequest}
          createChangeRenewalRequestEnricher={enrichRenewalRequest}
        />
        {showCancelButton && (
          <Button
            onClick={onClose}
            className="ml-[10px]"
            label="Cancel"
            type="secondary"
          />
        )}
      </div>
    </div>
  );
};

export default Manual;
