import React from 'react';
import { ChangesPill, CustomTermsChange, Proposal } from 'common';
import SubTitle from './SubTitle';
import CustomTerms from './Header/CustomTerms';
import { getIsPdf } from '../utils/pdf';

interface Props {
  proposal: Proposal;
}

const CustomTermsSection: React.FC<Props> = ({ proposal }) => {
  const isPdf = getIsPdf();
  const { customTerms, changes, previous } = proposal;

  if (!customTerms) {
    return null;
  }

  const renderChangedChicklet = () => {
    if (
      !proposal.changes ||
      !changes?.customTermsChange ||
      !previous ||
      isPdf
    ) {
      return null;
    }

    return (
      <ChangesPill>
        <CustomTermsChange proposal={proposal} changes={proposal.changes} />
      </ChangesPill>
    );
  };

  return (
    <div className="mt-8">
      <SubTitle title="Terms" actions={renderChangedChicklet()} />
      <CustomTerms terms={customTerms} />
    </div>
  );
};

export default CustomTermsSection;
