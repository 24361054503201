import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { SetupIntent } from '@stripe/stripe-js';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  AcceptRequest,
  BillingMethodRequest,
  Button,
  ButtonBar,
  CheckoutPaymentMethod,
  Contract,
  CreateContractRequest,
  FormField,
  getErrorMessage,
  hasAnAcceptedProposalStatus,
  Input,
  isActiveProposalStatus,
  isChangeProposalType,
  isPaymentSelectionProposalStatus,
  isRenewalProposalType,
  log,
  noop,
  PaymentSchedule,
  Proposal,
  StripePaymentIntentResponse,
  useDebounce,
  useFlags,
} from 'common';

import { useActiveContact } from '../../../../utils/utils';
import { createPaymentIntent } from '../../../../services/stripe';
import {
  getContractKey,
  useCreateContract,
  useUpdateContractPayment,
} from '../../../../services/contract';
import { useAcceptProposal } from '../../../../services/proposal';
import styles from './StripeForm.module.scss';

interface Props {
  contract?: Contract;
  editable: boolean;
  isChangePaymentMethodClicked?: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
  paymentMethod?: CheckoutPaymentMethod;
  paymentSchedule: PaymentSchedule;
  proposal: Proposal;
  showCancelButton?: boolean;
}

const StripeForm = ({
  contract,
  editable,
  isChangePaymentMethodClicked,
  onClose,
  onSubmit,
  paymentMethod,
  paymentSchedule,
  proposal,
  showCancelButton,
}: Props) => {
  const [errorMsg, setErrorMsg] = useState<string>();
  const [successMsg, setSuccessMsg] = useState<string>();
  const [isProcessing, setIsProcessing] = useState(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(!editable);
  const [clientSecret, setClientSecret] = useState<string>();
  const [intentResponse, setIntentResponse] =
    useState<StripePaymentIntentResponse>();
  const stripe = useStripe();
  const elements = useElements()!;
  const buyer = useActiveContact(proposal);
  const [ccName, setCCName] = useState('');
  const queryClient = useQueryClient();
  const { enableChangeProposalPaymentMethods } = useFlags();
  const isRenewalProposal = isRenewalProposalType(proposal);
  const isChangeProposal = isChangeProposalType(proposal);

  const {
    mutate: _setupIntent,
    isSuccess: hasInitiated,
    isPending: isInitiating,
  } = useMutation({
    mutationFn: () => createPaymentIntent(proposal),
    onError: (error) => {
      setErrorMsg('Unable to initiate cc: ' + getErrorMessage(error));
      setIsDisabled(true);
    },
    onSuccess: (data: StripePaymentIntentResponse) => {
      setClientSecret(data.clientSecret);
      setIntentResponse(data);
    },
  });

  // This is because the useEffect below is being double-called instantly, too quickly for isInitiating
  const { debouncedFunction: setupIntent, clear } = useDebounce(
    _setupIntent,
    100
  );

  useEffect(() => {
    if (!editable || hasInitiated || isInitiating) {
      return noop;
    }

    const undo = () => {
      setIsDisabled(isDisabled);
      clear();
    };

    setIsDisabled(false);
    setupIntent();

    return undo;
  }, [editable, proposal.id, paymentSchedule.id]);

  const cardOptions = {
    style: {
      base: {
        iconColor: 'var(--text-black)',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': {
          color: 'var(--text-black)',
        },
      },
      invalid: {
        iconColor: 'var(--red)',
        color: 'var(--red)',
      },
    },
    disabled: !editable,
  };

  const handleChange: React.ComponentProps<typeof CardElement>['onChange'] = (
    event
  ) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setIsDisabled(event.empty);
    setErrorMsg(event.error?.message ?? '');
  };

  const handleSubmit = async () => {
    if (isDisabled || isProcessing || !stripe || !clientSecret) return;

    setIsProcessing(true);

    try {
      const payload = await stripe.confirmCardSetup(clientSecret, {
        // eslint-disable-next-line camelcase
        payment_method: {
          card: elements.getElement(CardElement)!,
          // eslint-disable-next-line camelcase
          billing_details: { name: ccName },
        },
      });

      if (payload.error) {
        setErrorMsg(`Payment failed ${payload.error.message}`);
      } else {
        setErrorMsg(undefined);
        handleStripeResult(payload.setupIntent);
      }
    } finally {
      setIsProcessing(false);
    }
  };

  const handleStripeResult = (e: SetupIntent) => {
    // This is not PaymentMethod because the field is not expanded
    const paymentMethodId = e.payment_method as string | undefined;

    const billingMethodRequest: BillingMethodRequest = {
      stripeAccounts: {
        customerId: intentResponse?.customerId,
        paymentMethodId,
      },
      paymentMethodId: paymentMethod?.id,
    };

    if (
      enableChangeProposalPaymentMethods
        ? isRenewalProposal || isChangeProposal
        : isRenewalProposal
    ) {
      if (!hasAnAcceptedProposalStatus(proposal)) {
        const request: AcceptRequest = {
          billingMethodRequest: {
            stripeAccounts: {
              customerId: intentResponse?.customerId,
              paymentMethodId,
            },
            paymentMethodId: paymentMethod?.id,
          },
        };

        acceptProposal(request);
        return;
      }
    }

    if (contract?.id) {
      // TODO do billing update with the contract id, proposalid
      // stripe accounts
      updateContract(billingMethodRequest);
      log.debug('stripe payment update submitting:', billingMethodRequest);
      return;
    }

    const body: CreateContractRequest = {
      proposalId: proposal.id,
      contactId: buyer.id,
      paymentMethod: billingMethodRequest,
    };
    createContract(body);
    log.debug('stripe submitting:', body);
  };

  const onSuccess = async (successData: Proposal | Contract) => {
    log.debug('successData', successData);
    setIsDisabled(true);
    setIsProcessing(false);
    onSubmit?.();
    if (contract) {
      await queryClient.invalidateQueries({
        queryKey: getContractKey(contract.id),
      });
    }
    setSuccessMsg('Updated payment information.');
  };

  const onError = (mutateError: unknown) => {
    log.warn(mutateError);
    setIsProcessing(false);
    setErrorMsg(getErrorMessage(mutateError) || 'Error.');
  };

  const { mutate: createContract } = useCreateContract(
    proposal.id,
    onSuccess,
    onError,
    queryClient
  );

  const { mutate: updateContract } = useUpdateContractPayment(
    contract,
    onSuccess,
    onError,
    queryClient
  );

  const { mutate: acceptProposal } = useAcceptProposal(
    proposal.id,
    onSuccess,
    onError,
    queryClient
  );

  const handleUseCardOnFileSubmit = () => {
    acceptProposal({});
  };

  const isActiveOrPaymentSelectionStatus =
    isActiveProposalStatus(proposal) ||
    isPaymentSelectionProposalStatus(proposal);

  const isChangePaymentMethodNotClicked = !isChangePaymentMethodClicked;

  const showUseCardOnFile =
    (isRenewalProposal || isChangeProposal) &&
    isActiveOrPaymentSelectionStatus &&
    isChangePaymentMethodNotClicked;

  if (showUseCardOnFile) {
    return (
      <>
        <p className="mb-4 font-bold xs:text-sm sm:text-lg xs:mt-8 sm:mt-0">
          Pay with credit card
        </p>
        <p className="mb-4 text-sm text-semidbold">
          Cacheflow can use your credit card on file and charge you based on the
          payment plan selected.
        </p>
        <div className="mt-2 ml-[-10px] flex">
          <Button
            onClick={handleUseCardOnFileSubmit}
            className="ml-[10px]"
            label="Submit"
            isDisabled={isDisabled || !editable}
            isLoading={editable && isProcessing}
          />
        </div>
      </>
    );
  }

  if (isChangePaymentMethodClicked === false && isDisabled) {
    return (
      <>
        <p className="mb-4 font-bold xs:text-sm sm:text-lg xs:mt-8 sm:mt-0">
          Pay with credit card
        </p>
        <p className="mb-4 text-sm text-semidbold">
          Cacheflow can use your credit card on file and charge you based on the
          payment plan selected.
        </p>
      </>
    );
  }

  return (
    <>
      <p className="mb-4 font-bold xs:text-sm sm:text-lg xs:mt-8 sm:mt-0">
        Pay with {paymentMethod?.name?.toLowerCase()}
      </p>
      <>
        <p className="mb-4 text-sm text-semidbold">
          Cacheflow will store your card on file and charge you based on the
          payment plan selected.
        </p>

        {successMsg && (
          <p className="mb-4 text-sm text-semidbold">{successMsg}</p>
        )}

        <form className={styles.paymentForm} onSubmit={handleSubmit}>
          <FormField label="Name on card">
            <Input
              placeholder="Full name"
              onChange={(value) => setCCName(value)}
              isDisabled={!editable}
            />
          </FormField>

          <FormField label="Credit card">
            <CardElement
              options={cardOptions}
              onChange={handleChange}
              className={clsx('p-3', styles.cardElement)}
            />
          </FormField>

          <ButtonBar>
            {showCancelButton && (
              <Button
                onClick={onClose}
                className="ml-[10px]"
                label="Cancel"
                type="secondary"
              />
            )}

            <Button
              label="Submit"
              isDisabled={isDisabled || !editable}
              isLoading={editable && (isProcessing || !clientSecret)}
              onClick={handleSubmit}
            />
          </ButtonBar>

          {errorMsg && <div className="error-message">{errorMsg}</div>}
        </form>
      </>
    </>
  );
};

export default StripeForm;
