import React from 'react';
import {
  arrayTop,
  formatDate,
  formatDateOrDefault,
  getPersonFullName,
  hasAnAcceptedProposalStatus,
  isChangeProposalType,
  isRenewalProposalType,
  Proposal,
  Type,
} from 'common';
import { useActiveContact } from '../../utils/utils';
import { useIsPreview } from '../../utils/viewMode';
import { getIsPdf } from '../../utils/pdf';

interface Props {
  proposal: Proposal;
}

const OrderInfo = ({ proposal }: Props) => {
  const isPreview = useIsPreview();
  const { proposalContacts, createdBy, owner } = proposal;
  const activeContact = useActiveContact(proposal);
  const isChangeProposal = isChangeProposalType(proposal);
  const isRenewalProposal = isRenewalProposalType(proposal);
  const hasAnAcceptedStatus = hasAnAcceptedProposalStatus(proposal);
  const { email: buyerEmail } = activeContact;
  const isSigning = proposal.options?.requiresSigning || false;
  const isPdf = getIsPdf();

  const OrderInfoRow: React.FC<{
    dataTestId?: string;
    label: string;
    value: React.ReactNode;
  }> = ({ label, value, dataTestId }) => {
    return (
      <tr data-testid={dataTestId}>
        <td className="pr-4 align-top">{label}</td>
        <td className="align-top">{value}</td>
      </tr>
    );
  };

  const title = () => {
    if (hasAnAcceptedStatus || isPdf) {
      if (isChangeProposal) return 'Change order';
      if (isRenewalProposal) return 'Renewal order';
      return 'Order';
    }

    if (isChangeProposal) return 'Change proposal';
    if (isRenewalProposal) return 'Renewal proposal';

    return 'Proposal';
  };

  return (
    <div>
      <div className="flex mb-3">
        <Type header={1} noMargin dataTestId="proposal-header">
          {title()} {isPreview && !isPdf && '(Preview)'}
        </Type>
      </div>

      <table className="w-full">
        <thead>
          <tr>
            <th className="w-[25%] min-w-[8em] max-w-[50%] h-0" />
            <th className="h-0" />
          </tr>
        </thead>

        <tbody>
          {proposal.proposalNumber && (
            <OrderInfoRow label="Order no." value={proposal.proposalNumber} />
          )}

          {proposal.expiresAt && (
            <OrderInfoRow
              label="Valid until"
              value={formatDate(proposal.expiresAt)}
            />
          )}

          {isSigning && (
            <>
              <OrderInfoRow
                label="Offered"
                value={formatDateOrDefault(
                  arrayTop(proposalContacts)?.createdAt || proposal.updatedAt
                )}
              />
              <OrderInfoRow
                label="Created by"
                value={owner ? getPersonFullName(owner) : createdBy}
              />
            </>
          )}

          <OrderInfoRow
            label="Created for"
            value={
              <>
                {getPersonFullName(activeContact)}
                <br />
                {buyerEmail}
              </>
            }
          />
        </tbody>
      </table>
    </div>
  );
};

export default OrderInfo;
