import React from 'react';
import {
  AcceptRequest,
  BillingMethodRequest,
  Button,
  Contract,
  CreateContractRequest,
  getErrorMessage,
  hasAnAcceptedProposalStatus,
  isChangeProposalType,
  isRenewalProposalType,
  Proposal,
  useFlags,
} from 'common';
import { useActiveContact } from '../../../utils/utils';
import { useQueryClient } from '@tanstack/react-query';
import {
  getContractKey,
  useCreateContract,
  useUpdateContractPayment,
} from '../../../services/contract';
import { useAcceptProposal } from '../../../services/proposal';

interface Props {
  contract?: Contract;
  createChangeRenewalRequestEnricher?: (
    request: AcceptRequest
  ) => AcceptRequest;
  createContractRequestEnricher?: (
    request: CreateContractRequest
  ) => CreateContractRequest;
  isDisabled?: boolean;
  label?: string;
  onError?: (message?: string) => void;
  onSuccess?: (update: boolean) => void;
  paymentMethodId: string;
  proposal: Proposal;
  updateContractRequestEnricher?: (
    request: BillingMethodRequest
  ) => BillingMethodRequest;
}

const DoneButton = ({
  label,
  proposal,
  paymentMethodId,
  isDisabled = false,
  createContractRequestEnricher,
  updateContractRequestEnricher,
  createChangeRenewalRequestEnricher,
  onSuccess,
  onError,
  contract,
}: Props) => {
  const queryClient = useQueryClient();
  const buyer = useActiveContact(proposal);
  const isRenewalProposal = isRenewalProposalType(proposal);
  const isChangeProposal = isChangeProposalType(proposal);
  const isAcceptedOrPendingRenewal = hasAnAcceptedProposalStatus(proposal);
  const { enableChangeProposalPaymentMethods } = useFlags();
  const getError = (error: unknown): string => {
    const msg: string = getErrorMessage(error) || '';

    if (msg.includes('user.legal_name must contain first name and last name')) {
      return 'Legal name must contain a first and last name.';
    } else if (msg.includes('insufficient')) {
      return 'Transfer authorization declined due to insufficient funds for first payment';
    } else {
      return msg;
    }
  };

  const handleSuccess = async () => {
    onSuccess && onSuccess(true);
    if (contract) {
      await queryClient.invalidateQueries({
        queryKey: getContractKey(contract.id),
      });
    }
  };

  const { mutate: acceptProposal, isPending: isLoadingAccept } =
    useAcceptProposal(
      proposal.id,
      handleSuccess,
      (error: unknown) => {
        onError && onError(getError(error));
      },
      queryClient
    );

  const { mutate: createContract, isPending: isLoadingCreateContract } =
    useCreateContract(
      proposal.id,
      handleSuccess,
      (error: unknown) => {
        onError && onError(getError(error));
      },
      queryClient
    );

  const { mutate: updateContract, isPending: isLoadingUpdateContract } =
    useUpdateContractPayment(
      contract,
      handleSuccess,
      (mutateError: unknown) => {
        onError && onError(getError(mutateError));
      },
      queryClient
    );

  const handleCreateContract = () => {
    const request: CreateContractRequest = {
      paymentMethod: {
        paymentMethodId,
      },
      proposalId: proposal.id,
      contactId: buyer.id,
    };

    if (createContractRequestEnricher) {
      createContract(createContractRequestEnricher(request));
    } else {
      createContract(request);
    }
  };

  const handleUpdateContract = () => {
    const request: BillingMethodRequest = {
      paymentMethodId,
    };

    if (updateContractRequestEnricher) {
      updateContract(updateContractRequestEnricher(request));
    } else {
      updateContract(request);
    }
  };

  const handleChangeRenewContract = () => {
    const request: AcceptRequest = {
      billingMethodRequest: {
        paymentMethodId,
      },
    };

    if (createChangeRenewalRequestEnricher) {
      acceptProposal(createChangeRenewalRequestEnricher(request));
    } else {
      acceptProposal(request);
    }
  };

  const handleClick = () => {
    if (
      enableChangeProposalPaymentMethods
        ? isRenewalProposal || isChangeProposal
        : isRenewalProposal
    ) {
      if (!isAcceptedOrPendingRenewal) {
        handleChangeRenewContract();
        return;
      }
    }

    if (contract?.id) {
      handleUpdateContract();
      return;
    }

    handleCreateContract();
  };

  const isSaving =
    isLoadingAccept || isLoadingCreateContract || isLoadingUpdateContract;

  return (
    <Button
      label={isSaving ? 'In progress...' : label || 'Submit'}
      isDisabled={isDisabled}
      isLoading={isSaving}
      onClick={handleClick}
    />
  );
};

export default DoneButton;
