enum ViewMode {
  view,
  preview,
}

export const useTokenForPreview = () => {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get('token');
};

export const useOtpForAuth = () => {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get('otp_code');
};

export const useOriginalStatus = () => {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get('original_status');
};

const useViewMode = () => {
  const tokenValue = useTokenForPreview();
  if (tokenValue) {
    return ViewMode.preview;
  } else {
    return ViewMode.view;
  }
};

export const useIsPreview = (): boolean => {
  return useViewMode() === ViewMode.preview;
};
