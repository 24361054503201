import React from 'react';

import {
  AddressDisplay,
  AddressRest,
  Button,
  ButtonBar,
  Modal,
  VerifyAddressResponse,
} from 'common';

interface Props {
  onAcceptCurrent: () => void;
  onAcceptVerified: (verifiedAddress: AddressRest) => void;
  onEditCurrent: () => void;
  verifyAddressResponse?: VerifyAddressResponse;
}

const VerifyAddressModal: React.FC<Props> = ({
  verifyAddressResponse,
  onAcceptVerified,
  onAcceptCurrent,
  onEditCurrent,
}) => {
  const { verifiedAddress } = verifyAddressResponse ?? {};

  let buttons;
  let body;
  if (verifyAddressResponse && verifiedAddress) {
    body = (
      <div className="flex flex-col gap-8">
        <div className="flex gap-16">
          <p>Original address</p>
          <AddressDisplay address={verifyAddressResponse.originalAddress} />
        </div>
        <div className="flex gap-16">
          <p>Verified address</p>
          <AddressDisplay address={verifyAddressResponse.verifiedAddress} />
        </div>
      </div>
    );
    buttons = (
      <ButtonBar>
        <Button
          type="secondary"
          label="Keep provided address"
          onClick={onAcceptCurrent}
        />
        <Button
          label="Use verified address"
          onClick={() => onAcceptVerified(verifiedAddress)}
        />
      </ButtonBar>
    );
  } else if (verifyAddressResponse?.couldNotBeVerified) {
    body = (
      <div>
        <p>The following address could not be verified.</p>
        <br />
        <AddressDisplay address={verifyAddressResponse.originalAddress} />
      </div>
    );
    buttons = (
      <ButtonBar>
        <Button
          label="Keep provided address"
          type="secondary"
          onClick={onAcceptCurrent}
        />
        <Button label="Edit address" onClick={onEditCurrent} />
      </ButtonBar>
    );
  } else if (verifyAddressResponse?.badCountry) {
    body = (
      <div>
        <p>Invalid country: {verifyAddressResponse.originalAddress.country}</p>
      </div>
    );
    buttons = (
      <ButtonBar>
        <Button label="Ok" onClick={onEditCurrent} />
      </ButtonBar>
    );
  }

  return (
    <Modal
      isOpen={!!verifyAddressResponse}
      header="Address verification"
      footer={buttons}
    >
      {body}
    </Modal>
  );
};

export default VerifyAddressModal;
