import React from 'react';
import {
  CheckoutPaymentMethod,
  CompanyInfo,
  Contract,
  isChangeProposalType,
  Proposal,
  useFlags,
  getIsSignatureRequired,
} from 'common';
import { SelectedOptionProps } from '../../types/model';
import { getIsPdf } from '../../utils/pdf';
import ProposalTypePaymentSection from './ProposalTypePaymentSection';
import ProposalPaymentSection from './ProposalPaymentSection';
import { getIsSectionMuted } from '../../utils/workflow';

interface Props {
  companyInfo: CompanyInfo;
  contract?: Contract;
  isBillingInfoBeingEdited: boolean;
  paymentMethods: CheckoutPaymentMethod[];
  proposal: Proposal;
  selectedOptions: SelectedOptionProps;
  setSelectedOptions: (selectedOptions: SelectedOptionProps) => void;
}

const PaymentSelectionSection: React.FC<Props> = ({
  companyInfo,
  contract,
  isBillingInfoBeingEdited,
  paymentMethods,
  proposal,
  selectedOptions,
  setSelectedOptions,
}) => {
  const { proposalType } = proposal;
  const isPdf = getIsPdf();
  const isSignatureRequired = getIsSignatureRequired(proposal);
  const { enableChangeProposalPaymentMethods } = useFlags();

  if (!enableChangeProposalPaymentMethods && isChangeProposalType(proposal)) {
    return null;
  }

  if (
    enableChangeProposalPaymentMethods
      ? proposalType === 'renewal' || proposalType === 'change'
      : proposalType === 'renewal'
  ) {
    return (
      <ProposalTypePaymentSection
        companyInfo={companyInfo}
        contract={contract}
        isMuted={
          isBillingInfoBeingEdited ||
          getIsSectionMuted(proposal, 'payment_methods')
        }
        isPdf={isPdf}
        paymentMethods={paymentMethods}
        proposal={proposal}
        selectedOptions={selectedOptions}
        setSelectedMethodId={(method: string) =>
          setSelectedOptions({
            ...selectedOptions,
            selectedMethodId: method,
          })
        }
      />
    );
  }

  return (
    <ProposalPaymentSection
      companyInfo={companyInfo}
      contract={contract}
      isPdf={isPdf}
      needsBillingModal={isSignatureRequired}
      paymentMethods={paymentMethods}
      proposal={proposal}
      selectedOptions={selectedOptions}
      setSelectedMethodId={(method: string) =>
        setSelectedOptions({
          ...selectedOptions,
          selectedMethodId: method,
        })
      }
    />
  );
};

export default PaymentSelectionSection;
