import { QueryClient } from '@tanstack/react-query';
import {
  CheckoutPaymentMethod,
  CompanyInfo,
  Contract,
  ItemDeltaSpansResponse,
  log,
  Proposal,
  Signature,
} from 'common';
import { setBrandingInCache, setPaymentMethodsInCache } from './checkoutApi';
import { setContract } from './contract';
import { setItemDeltaSpans, setProposal } from './proposal';
import { setDefaultSignature } from './signature';

// using console here for logging since reading output from log.info e.g.
// is hard to read in playwright browser logs
export const preloadFromSessionStorage = (qc: QueryClient) => {
  try {
    tryPreloadFromSessionStorage(qc);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error preloading data from session storage: ', e);
    log.error('Error preloading data from session storage: ', e);
    throw e;
  }
};

const base64DecodeUtf8 = (str: string): string => {
  const data = atob(str);
  const asArray = Uint8Array.from(data, (c) => c.charCodeAt(0));
  const decoder = new TextDecoder('utf-8');
  return decoder.decode(asArray);
};

function readItemFromSessionStorage<T>(key: string): T | undefined {
  // eslint-disable-next-line no-console
  console.log(`Reading ${key} from window.sessionStorage`);

  const content = window.sessionStorage.getItem(key);
  if (content === null) {
    // eslint-disable-next-line no-console
    console.log(`could not read ${key} from window.sessionStorage`);
    return undefined;
  }

  const decoded = base64DecodeUtf8(content);
  const rv: T = JSON.parse(decoded);
  return rv;
}

const tryPreloadFromSessionStorage = (qc: QueryClient) => {
  if (!(window as any).preloaded) {
    (window as any).preloaded = true;

    const proposal: Proposal | undefined =
      readItemFromSessionStorage('proposal');
    if (proposal) {
      setProposal(qc, proposal);
    }

    const companyInfo: CompanyInfo | undefined =
      readItemFromSessionStorage('companyInfo');
    if (companyInfo) {
      setBrandingInCache(qc, companyInfo);
    }

    const paymentMethods: CheckoutPaymentMethod[] | undefined =
      readItemFromSessionStorage('paymentMethods');
    if (paymentMethods && proposal?.id !== undefined) {
      setPaymentMethodsInCache(qc, proposal.id, paymentMethods);
    }

    const contract: Contract | undefined =
      readItemFromSessionStorage('contract');
    if (contract) {
      setContract(qc, contract);
    }

    const signature: Signature | undefined =
      readItemFromSessionStorage('signature');
    if (signature) {
      setDefaultSignature(qc, signature);
    }

    const itemDeltaSpans: ItemDeltaSpansResponse[] | undefined =
      readItemFromSessionStorage('itemDeltaSpans');
    if (itemDeltaSpans && proposal?.id !== undefined) {
      setItemDeltaSpans(qc, itemDeltaSpans, proposal.id);
    }
  }
};
