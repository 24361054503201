import {
  Customer,
  hasAnAcceptedProposalStatus,
  isDefined,
  isPaymentSelectionProposalStatus,
  isValidAddress,
  Proposal,
  getIsSignatureRequired,
} from 'common';
import { useOtpForAuth } from './viewMode';

// This is a list of all required steps in the order they must be done
type Section =
  | 'billing_info'
  | 'complete'
  | 'payment_methods'
  | 'payment_plan'
  | 'shipping_info'
  | 'signing';

const sections: Section[] = [
  'shipping_info',
  'billing_info',
  'signing',
  'payment_plan',
  'payment_methods',
  'complete',
];

export const getIsAwaitingSignature = (proposal: Proposal): boolean =>
  getIsSignatureRequired(proposal) && !proposal.signingDocument?.signedAt;

const hasValidCustomerAddresses = (
  customer: Customer,
  validateIsoFields: boolean = false
): boolean =>
  isValidAddress(customer.billingAddress, validateIsoFields) &&
  isValidAddress(customer.shippingAddress, validateIsoFields);

export const hasValidCustomerInfo = (proposal: Proposal): boolean =>
  isDefined(proposal.customer) &&
  hasValidCustomerAddresses(proposal.customer) &&
  !!proposal.customer.billingContact;

const getSection = (proposal: Proposal): Section => {
  // Older proposals can be completed without proper information
  if (hasAnAcceptedProposalStatus(proposal)) {
    return 'complete';
  }

  if (!isValidAddress(proposal.customer?.shippingAddress)) {
    return 'shipping_info';
  }

  // if customer doesn't have a valid billing address or is missing a billing contact,
  // but only if bill to is not set
  if (
    !proposal.billTo &&
    (!isValidAddress(proposal.customer?.billingAddress) ||
      !proposal.customer?.billingContact)
  ) {
    return 'billing_info';
  }

  if (getIsAwaitingSignature(proposal)) {
    return 'signing';
  }

  if (isPaymentSelectionProposalStatus(proposal) || useOtpForAuth() !== null) {
    return 'payment_methods';
  }

  return 'complete';
};

export const getIsSectionMuted = (
  proposal: Proposal,
  section: Section
): boolean =>
  sections.indexOf(getSection(proposal)) < sections.indexOf(section);

export const getIsSectionDisabled = (
  proposal: Proposal,
  section: Section
): boolean => {
  if (
    proposal.signingDocument &&
    !proposal.signingDocument.signedAt &&
    section !== 'signing'
  ) {
    // Disable everything after contract generation but before signing
    return true;
  }

  const isAccepted = hasAnAcceptedProposalStatus(proposal);

  if (section === 'payment_methods' && isAccepted && useOtpForAuth() !== null) {
    return true;
  }

  return isAccepted;
};
