import React from 'react';
import { createRoot } from 'react-dom/client';
import 'common/style.css';
import {
  createRoutesFromChildren,
  matchRoutes,
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AnalyticsWrapper } from './AnalyticsWrapper';
import { getVersion, VERSION_INFO } from './utils/version';
import Status from './Status';
import { ENVIRONMENT_CONFIG } from './config/hosts';
import * as Sentry from '@sentry/react';
import ProposalPage from './ProposalPage';
import CheckoutInitWrapper from './pages/CheckoutInitWrapper';
import CheckoutFormWrapper from './pages/CheckoutFormWrapper';
import AuthDocusignViewCallback from './pages/AuthDocusignViewCallback';
import ReactGA from 'react-ga';
import {
  Button,
  DEFAULT_STALE_TIME,
  initI18N,
  log,
  ToastWrapper,
} from 'common';
import BrandingConfig from './BrandingConfig';
import './index.css';
import ErrorPage from './components/ErrorPage';
import { LaunchDarklyWrapper } from './utils/launchDarkly';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      staleTime: DEFAULT_STALE_TIME,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    },
  },
});

if (ENVIRONMENT_CONFIG.isMonitored) {
  Sentry.init({
    dsn: ENVIRONMENT_CONFIG.sentryKey,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.extraErrorDataIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.3,
    environment: ENVIRONMENT_CONFIG.env,
    release: VERSION_INFO.branch?.replace(/\//g, '-'),
  });
}

log.info(getVersion());

if (ENVIRONMENT_CONFIG.isProduction) {
  ReactGA.initialize('UA-227488425-1', {
    /* debug: true, */
  });

  // TODO add more events and user details
  ReactGA.pageview(window.location.pathname);
}

initI18N().catch(log.error);

function ErrorFallback() {
  return (
    <ErrorPage
      title="Something went wrong"
      message="Please contact support at help@getcacheflow.com"
    />
  );
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <AnalyticsWrapper>
      {/* @ts-ignore https://github.com/TanStack/query/issues/3476 */}
      <QueryClientProvider client={queryClient}>
        <Sentry.ErrorBoundary fallback={ErrorFallback}>
          <LaunchDarklyWrapper>
            <BrandingConfig>
              <ToastWrapper>
                <Router>
                  <Routes>
                    <Route path="/p/:proposalId" element={<ProposalPage />} />
                    <Route
                      path="/t/:templateId"
                      element={<CheckoutInitWrapper />}
                    />
                    <Route path="/chat" element={<CheckoutFormWrapper />} />

                    <Route path="/status" element={<Status />} />
                    <Route
                      path="/error"
                      element={
                        <div>
                          <Button
                            onClick={() => {
                              throw new Error('Yep, this is an error');
                            }}
                            label="Throw error"
                          />
                        </div>
                      }
                    />
                    <Route
                      path="/docusign/view-callback"
                      element={<AuthDocusignViewCallback />}
                    />
                    <Route element={<ErrorPage title="404 Not found" />} />
                  </Routes>
                </Router>
              </ToastWrapper>
            </BrandingConfig>
          </LaunchDarklyWrapper>
        </Sentry.ErrorBoundary>
      </QueryClientProvider>
    </AnalyticsWrapper>
  </React.StrictMode>
);
