import React from 'react';

import { isProductTiered, Proposal } from 'common';

import { TieredPriceTable } from './TieredPriceTable/TieredPriceTable';

import styles from './Appendix.module.scss';

type Props = {
  isPdf: boolean;
  proposal: Proposal;
};

export const Appendix = ({ proposal, isPdf }: Props) => {
  const proposalItems = proposal.proposalItems;
  const tieredProposalItems = proposalItems.filter((item) =>
    isProductTiered(item.product)
  );

  const selectedBillingPeriod = proposal.schedules.find(
    (schedule) => schedule.id === proposal.selectedScheduleId
  );

  const schedulePeriod = selectedBillingPeriod?.payEvery;

  if (!tieredProposalItems.length || !schedulePeriod || !isPdf) return null;

  return (
    <div className={styles.appendix}>
      <h2 className={styles.header}>Appendix</h2>
      <h3>Tiers</h3>

      <div>
        {tieredProposalItems.map((item) => {
          return (
            <TieredPriceTable
              key={item.id}
              item={item}
              schedulePeriod={schedulePeriod}
            />
          );
        })}
      </div>
    </div>
  );
};
