import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { isExpiredProposalStatus, Loading, log, noop } from 'common';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useProposal } from './services/proposal';
import { getError, getRecordInaccessibleError } from './utils/utils';
import { launchDarklyIdentify } from './utils/launchDarkly';
import App from './App';
import ErrorPage from './components/ErrorPage';
import { useQueryClient } from '@tanstack/react-query';
import { preloadFromSessionStorage } from './services/preload';
import { getIsPdf } from './utils/pdf';

const ProposalWrapper: React.FC = () => {
  const { proposalId } = useParams<{ proposalId: string }>();
  const isPdf = getIsPdf();
  const ldClient = useLDClient();

  const qc = useQueryClient();
  if (isPdf) {
    preloadFromSessionStorage(qc);
  }

  if (!proposalId) {
    return <ErrorPage />;
  }

  const {
    data: proposal,
    isLoading: proposalIsLoading,
    error: proposalError,
  } = useProposal(proposalId);

  useEffect(() => {
    if (proposal && ldClient) {
      launchDarklyIdentify(proposal, ldClient).then(noop, log.error);
    }
  }, [proposal]);

  if (proposalIsLoading) {
    return <Loading />;
  }

  if (proposalError || !proposal) {
    return getError(proposalError);
  }

  if (isExpiredProposalStatus(proposal)) {
    return getRecordInaccessibleError(
      `${proposal.owner?.firstName} ${proposal.owner?.lastName}`,
      proposal.owner?.username
    );
  }

  return <App proposal={proposal} />;
};

export default ProposalWrapper;
