import React, { useState } from 'react';
import { CompanyInfo, Icon } from 'common';
import AboutCacheflowModal from './AboutCacheflowModal';
import CfLogo from '../assets/CfLogo';

const PoweredBy = ({ companyInfo }: { companyInfo?: CompanyInfo }) => {
  const [isOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function handleClose() {
    setIsOpen(false);
  }

  return (
    <>
      <div
        style={{ color: 'var(--window-foreground-color)' }}
        className="w-full pb-6 text-sm text-center sm:mt-0"
      >
        {companyInfo?.name} checkout powered by
        <span
          className="inline-flex ml-1 align-text-top cursor-pointer hover:opacity-100"
          onClick={openModal}
        >
          <CfLogo className="h-[13px] mt-[1px]" name="Cacheflow logo" />
          <Icon.Information className="-m-1 ml-0" height="20px" width="20px" />
        </span>
      </div>

      <AboutCacheflowModal isOpen={isOpen} handleClose={handleClose} />
    </>
  );
};

export default PoweredBy;
