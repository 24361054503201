import { CheckoutPaymentMethod, isDefined, PaymentSchedule } from 'common';
import { SelectedOptionProps } from '../types/model';

export const getEnabledScheduleMethods = (
  allPaymentMethods: CheckoutPaymentMethod[],
  schedule: PaymentSchedule
) => getEnabledMethods(allPaymentMethods, schedule.paymentMethodIds || []);

const getEnabledMethods = (
  allPaymentMethods: CheckoutPaymentMethod[],
  paymentMethodIds: string[]
): CheckoutPaymentMethod[] => {
  const methodMap = new Map<string, CheckoutPaymentMethod>();
  allPaymentMethods.forEach((m) => {
    methodMap.set(m.id!, m);
  });

  // ids could include disabled payment methods.
  return paymentMethodIds.map((id) => methodMap.get(id)).filter(isDefined);
};

export const getEnabledMethodsForProposal = (
  paymentMethods: CheckoutPaymentMethod[],
  selectedOption: SelectedOptionProps
): CheckoutPaymentMethod[] | undefined => {
  const { selectedSchedule } = selectedOption;

  if (selectedSchedule) {
    return getEnabledScheduleMethods(paymentMethods, selectedSchedule);
  }

  return undefined;
};
