import React, { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, UseQueryResult } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Loading, log } from 'common';
import ErrorPage from './components/ErrorPage';
import { ENVIRONMENT_CONFIG } from './config/hosts';
import { nonAuthAxios } from './services/api';
import { getApiUrl, getSubdomainParam } from './services/helper';
import { useActiveEmail } from './utils/utils';
import { useOtpForAuth, useTokenForPreview } from './utils/viewMode';
import * as Sentry from '@sentry/react';

const AUTH_TOKEN_PATH = '/security/session';
const AUTH_OTP_PATH = '/security/otp';

// this is a temporary interface till we get a proper implementation of auth flow
export interface Auth {
  orgId: string;
  role: string;
  token: string;
  userId: string;
}

interface Code {
  type: string;
  value: string; // preview, invite, hotp, totp
}

interface ReferenceKey {
  referenceId: string;
  referenceType: string;
}

interface AuthRequest {
  code?: Code;
  email?: string;
  referenceScope: ReferenceKey;
}

export const useOtpToken = (
  proposalId: string,
  enabled: boolean
): UseQueryResult<string> => {
  const email = useActiveEmail();

  const params: AuthRequest = {
    referenceScope: {
      referenceId: proposalId,
      referenceType: 'proposal',
    },
    email,
  };

  const url = getApiUrl(AUTH_OTP_PATH);

  return useQuery({
    queryKey: ['otp_auth'],
    queryFn: async () => {
      await nonAuthAxios.post(url, params);
      return 'OK';
    },
    enabled,
  });
};

export const createAuthTokenRequest = (
  proposalId: string,
  email: string,
  otpCode: string | null,
  token: string | null
) => {
  const params: AuthRequest = {
    referenceScope: {
      referenceId: proposalId,
      referenceType: 'proposal',
    },
    email,
  };

  if (otpCode) {
    params.code = {
      value: otpCode,
      type: 'totp',
    };
  } else if (token) {
    params.code = {
      value: token,
      type: 'preview',
    };
  }
  return params;
};

const authHook = async (params: AuthRequest) => {
  const url = getApiUrl(AUTH_TOKEN_PATH);
  const { data } = await nonAuthAxios.post(url, params);
  return data as Auth;
};

export const useUpdateAuthToken = (
  onSuccess: (data: Auth) => void,
  onError: (error: unknown) => void
) =>
  useMutation({
    mutationKey: [AUTH_TOKEN_PATH],
    mutationFn: authHook,
    onSuccess,
    onError,
  });

const useAuthToken = (
  proposalId: string | undefined,
  enabled: boolean
): UseQueryResult<Auth> => {
  const email = useActiveEmail();
  const token = useTokenForPreview();
  const otpCode = useOtpForAuth();

  const params: AuthRequest = createAuthTokenRequest(
    proposalId || new URLSearchParams(window.location.search).get('proposal')!,
    email,
    otpCode,
    token
  );

  return useQuery({
    queryKey: ['token_auth'],
    queryFn: async () => {
      return await authHook(params);
    },
    enabled,
  });
};

interface AuthContextType {
  loading: boolean;
  role: string;
  setAuthState: (tokenIn: string, roleIn: string) => void;
  token: string;
}

const AuthContext = React.createContext<AuthContextType>(null!);

export function AuthProvider({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  const { proposalId } = useParams<{ proposalId: string }>();
  const [token, setToken] = useState<any>(ENVIRONMENT_CONFIG.token);
  const [role, setRole] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const email = useActiveEmail();

  const { data, error } = useAuthToken(proposalId, !token);
  const setAuthState = (tokenIn: string, roleIn: string) => {
    setToken(tokenIn);
    setRole(roleIn);
    ENVIRONMENT_CONFIG.token = tokenIn;
  };

  const tokenUpdate = (event: CustomEvent<{ token: string }>) => {
    const newToken = event.detail.token;

    if (!newToken) {
      return;
    }

    log.debug('new token is:', newToken);
    setAuthState(newToken, role);
  };

  window.addEventListener<any>('token_exchange', tokenUpdate);

  useEffect(() => {
    if (data && !token) {
      const auth = data;

      if (ENVIRONMENT_CONFIG.isMonitored) {
        Sentry.setContext('checkout_user_context', {
          proposalId,
          role: auth.role,
          email,
          flowDomain: getSubdomainParam(),
        });
      }

      setAuthState(auth.token, auth.role);
      setLoading(false);
    }

    if (token) {
      setLoading(false);
    }

    if (error) {
      setLoading(false);
    }
  }, [data, error]);

  const memoedValue = useMemo(
    () => ({
      token,
      role,
      loading,
      setAuthState,
    }),
    [token, role, loading]
  );

  return (
    <AuthContext.Provider value={memoedValue}>
      <>
        {loading && <Loading />}
        {!loading && token && children}
        {!loading && !token && <ErrorPage title="Proposal access error" />}
      </>
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return React.useContext(AuthContext);
}
