import { ENVIRONMENT_CONFIG } from '../config/hosts';
import { log, Proposal, StripePaymentIntentResponse } from 'common';
import { loadStripe } from '@stripe/stripe-js';

import { buyerAxios } from './api';
import { getProposalUrl } from './proposal';
import { useEffect } from 'react';

const getCreatePaymentIntentUrl = (proposalId: string) => {
  return getProposalUrl(proposalId) + '/create-payment-intent';
};

// Initiates the payment flow with stripe
export const createPaymentIntent = async (
  proposal: Proposal
): Promise<StripePaymentIntentResponse> => {
  const url = getCreatePaymentIntentUrl(proposal.id);
  const { data } = await buyerAxios.post(url);
  return data as StripePaymentIntentResponse;
};

export const useStripePromise = (
  stripeAccountId: string | undefined,
  callback: Function
) => {
  useEffect(() => {
    if (stripeAccountId) {
      loadStripe(ENVIRONMENT_CONFIG.stripeKey, {
        stripeAccount: stripeAccountId,
      }).then((stripeMe) => {
        callback(stripeMe);
      }, log.error);
    }
  }, [stripeAccountId]);
};
