import React from 'react';

import { Proposal, ProposalAttachment } from 'common';

import SubTitle from '../SubTitle';
import Attachment from './Attachment';
import { getIsPdf } from 'checkout/src/utils/pdf';

import styles from './AttachmentsSection.module.scss';

interface Props {
  proposal: Proposal;
}

export const isRenderableDocument = (
  attachment: ProposalAttachment
): boolean => {
  const renderableExtensions = /\.(png|jpg|jpeg)$/i;

  return (
    attachment.type === 'document' &&
    !!attachment.documentVersion?.fileName &&
    renderableExtensions.test(attachment.documentVersion.fileName)
  );
};

const AttachmentsSection = ({ proposal }: Props) => {
  const isPdf = getIsPdf();

  if (!proposal.attachments.length) {
    return null;
  }

  const sortNonRenderableDocumentsToTop = (
    a: ProposalAttachment,
    b: ProposalAttachment
  ) => {
    if (isRenderableDocument(a) && !isRenderableDocument(b)) {
      return 1;
    }
    if (!isRenderableDocument(a) && isRenderableDocument(b)) {
      return -1;
    }

    return 0;
  };

  return (
    <div className={styles.attachmentsSection}>
      <SubTitle title="Attachments" />

      {proposal.attachments
        .sort(sortNonRenderableDocumentsToTop)
        .map((attachment) => (
          <Attachment
            attachment={attachment}
            isPdf={isPdf}
            key={attachment.id}
          />
        ))}
    </div>
  );
};

export default AttachmentsSection;
