import { formatRichText, Proposal } from 'common';
import React from 'react';
import { getIsPdf } from '../utils/pdf';
import styles from './CoverLetterSection.module.scss';

interface Props {
  proposal: Proposal;
}

const CoverLetterSection: React.FC<Props> = ({ proposal }) => {
  const isPdf = getIsPdf();
  const { coverLetter } = proposal;

  if (!coverLetter || isPdf) {
    return null;
  }

  return (
    <div className={styles.coverLetter}>{formatRichText(coverLetter)}</div>
  );
};

export default CoverLetterSection;
