import React from 'react';
import { Proposal } from 'common';
import clsx from 'clsx';

interface Props {
  status: Proposal['status'];
}

const getBarColor = (status: Proposal['status']) => {
  switch (status) {
    case 'pending_renewal':
    case 'accepted':
      return 'bg-green text-white';
    case 'draft':
      return 'bg-slate-lightest text-blue-lighter';
    case 'cancelled':
      return 'bg-red text-white';
    default:
      return '';
  }
};

const getStatusBarText = (status: Proposal['status']): string => {
  switch (status) {
    case 'pending_renewal':
      return 'accepted';
    default:
      return status;
  }
};

const StatusBar = ({ status }: Props) => {
  return (
    <div
      className={clsx(
        'h-10 px-1.5 mr-0 ml-auto flex font-bold items-center justify-center',
        getBarColor(status)
      )}
    >
      <p className="tracking-widest uppercase">{getStatusBarText(status)}</p>
    </div>
  );
};

export default StatusBar;
