import { ProposalEvent } from 'common';
import ReactGA, { EventArgs } from 'react-ga';
import { createProposalEvent } from '../services/proposal';

const getGoogleAnalyticsArgs = (
  eventType: ProposalEvent['eventType']
): undefined | EventArgs => {
  switch (eventType) {
    case 'buyer_downloaded':
      return {
        action: 'Generate checkout PDF',
        category: 'Buyer',
      };
    case 'buyer_printed':
      return {
        category: 'Buyer',
        action: 'Print checkout',
      };
    default:
      return undefined;
  }
};

const logEvent = async (
  eventType: ProposalEvent['eventType'],
  proposalId: string,
  userEmail: string
) => {
  const googleAnalyticsArgs = getGoogleAnalyticsArgs(eventType);

  if (googleAnalyticsArgs) {
    ReactGA.event(googleAnalyticsArgs);
  }

  await createProposalEvent(proposalId, userEmail, { eventType });
};

export default logEvent;
