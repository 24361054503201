import { useEffect, useState } from 'react';
import { isString } from 'common';

export function useHaveAllImagesDownloadedOrFailed(
  imageSources: (string | undefined)[]
): boolean {
  const createMapFromSources = (
    srcs: (string | undefined)[]
  ): Map<string, boolean> => {
    const newMap = new Map<string, boolean>();
    srcs.filter(isString).forEach((src) => {
      if (src) {
        newMap.set(src, false);
      }
    });
    return newMap;
  };

  const [statuses, setStatuses] = useState<Map<string, boolean>>(
    createMapFromSources(imageSources)
  );

  const downloadImage = (src: string) => {
    const img = new Image();
    const setImageDownloaded = () => {
      setStatuses((prev) => {
        const newMap = new Map(prev);
        newMap.set(src, true);
        return newMap;
      });
    };
    img.onload = setImageDownloaded;
    img.onerror = setImageDownloaded;
    img.src = src;
  };

  // on first render, download everything
  useEffect(() => {
    imageSources.filter(isString).forEach((src) => {
      downloadImage(src);
    });
  }, []);

  // on subsequent renders, download those that haven't been started already
  useEffect(() => {
    imageSources.filter(isString).forEach((src) => {
      if (statuses.get(src) === undefined) {
        downloadImage(src);
      }
    });
    // stringify to avoid js object identity checking
  }, [JSON.stringify(imageSources)]);

  return Array.from(statuses.values()).every((value) => !!value);
}

export const getIsPdf = (): boolean => {
  const searchParams = new URLSearchParams(window.location.search);

  return Boolean(
    searchParams.get('printPdf') || searchParams.get('docusignPdf')
  );
};

export const getIsChangesOnly = (): boolean => {
  const searchParams = new URLSearchParams(window.location.search);

  return Boolean(searchParams.get('changesOnly'));
};
