import React from 'react';

import { Icon, ProposalAttachment } from 'common';

import { isRenderableDocument } from './AttachmentsSection';

import styles from './Attachment.module.scss';

interface Props {
  attachment: ProposalAttachment;
  isPdf: boolean;
}

const Attachment = ({ attachment, isPdf }: Props) => {
  const getFileNameLink = () => {
    return (
      <div className={styles.attachmentLink}>
        {attachment.type === 'document' ? <Icon.DocumentSign /> : <Icon.Link />}
        <a
          href={
            attachment.type === 'link'
              ? attachment.linkUrl
              : attachment.documentVersion?.url
          }
          rel="noreferrer"
          target="_blank"
        >
          <span className={styles.attachmentName}>{attachment.name}</span>
        </a>{' '}
      </div>
    );
  };

  // When isPdf and is a renderable attachment, embed image directly
  if (isPdf && isRenderableDocument(attachment)) {
    return (
      <div className={styles.attachmentContainer}>
        {getFileNameLink()}
        <img
          className={styles.attachmentImage}
          src={attachment.documentVersion?.url}
        />
      </div>
    );
  }

  return <div className={styles.attachmentIcon}>{getFileNameLink()}</div>;
};

export default Attachment;
