import React from 'react';
import {
  CheckoutPaymentMethod,
  hasAnAcceptedProposalStatus,
  isChangeProposalType,
  Proposal,
} from 'common';
import { SelectedOptionProps } from '../../../types/model';
import ScheduleOption from './ScheduleOption';

interface Props {
  isDisabled: boolean;
  paymentMethods: CheckoutPaymentMethod[];
  proposal: Proposal;
  selectedOptions: SelectedOptionProps;
  setSelectedOptions: (selectedOptions: SelectedOptionProps) => void;
}
const ScheduleOptions = ({
  proposal,
  paymentMethods,
  selectedOptions,
  isDisabled,
  setSelectedOptions,
}: Props) => {
  const hasAnAcceptedStatus =
    hasAnAcceptedProposalStatus(proposal) || isChangeProposalType(proposal);
  const selectedSchedule = selectedOptions.selectedSchedule;

  if (!selectedSchedule || !selectedSchedule.id) {
    return null;
  }

  if (hasAnAcceptedStatus) {
    return (
      <div className="schedule-options">
        <div className="h3 mb-3">Billing period</div>
        <ScheduleOption
          key={selectedSchedule.id}
          proposal={proposal}
          paymentMethods={paymentMethods}
          isAccepted
          isSelected
          setSelectedOptions={setSelectedOptions}
          isDisabled={isDisabled}
          paymentSchedule={selectedSchedule}
        />
      </div>
    );
  }

  return (
    <div className="schedule-options">
      <div className="h3 mb-3">Billing periods</div>
      {proposal.schedules.map((ps) => (
        <ScheduleOption
          key={ps.id}
          proposal={proposal}
          paymentMethods={paymentMethods}
          isSelected={selectedSchedule.id === ps.id}
          isAccepted={false}
          paymentSchedule={ps}
          isDisabled={isDisabled}
          setSelectedOptions={setSelectedOptions}
        />
      ))}
    </div>
  );
};

export default ScheduleOptions;
