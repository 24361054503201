import {
  asyncNoop,
  BillingMethodRequest,
  Contract,
  CreateContractRequest,
} from 'common';
import {
  QueryClient,
  useMutation,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import { buyerAxios } from './api';
import { getApiUrl } from './helper';
import { clearCachedProposal } from './proposal';

const CONTRACTS_URL = '/api/latest/data/contracts';
const CONTRACTS_KEY = 'Contracts';

export const getContractKey = (id: string | undefined) => [
  `${CONTRACTS_KEY}${id}`,
];

const getContractUrl = (contractId: string | undefined) => {
  // TODO: email / token for server side access check
  return getApiUrl(`${CONTRACTS_URL}/${contractId}`);
};

const getContractsUrl = () => {
  return getApiUrl(CONTRACTS_URL);
};

const getContractPaymentUpdateUrl = (contractId: string, email?: string) => {
  let rv = getApiUrl(`${CONTRACTS_URL}/${contractId}/billing-method`);
  if (email) {
    rv = `${rv}?email=${encodeURIComponent(email)}`;
  }
  return rv;
};

const createBillingMethod =
  (contractId: string) => async (body: BillingMethodRequest) => {
    const url = getContractPaymentUpdateUrl(contractId);
    const { data } = await buyerAxios.post(url, body);
    return data;
  };

const updateContractPayment =
  (contractId?: string) => async (body: BillingMethodRequest) => {
    if (contractId) {
      const { data } = await buyerAxios.put(
        getContractPaymentUpdateUrl(contractId),
        body
      );
      return data;
    } else {
      return undefined;
    }
  };

export const useUpdateContractPayment = (
  contract: Contract | undefined,
  onSuccess: (data: Contract) => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [getContractKey(contract?.id)],
    mutationFn: !contract?.id
      ? asyncNoop
      : contract.billingMethod
        ? updateContractPayment(contract.id)
        : createBillingMethod(contract.id),
    onSuccess: (data) => {
      onSuccess(data);
      if (data) setContract(qc, data); // updates data returned from useContract()
    },
    onError,
  });

export const useContract = (
  id: string | undefined,
  enabled: boolean
): UseQueryResult<Contract> =>
  useQuery({
    queryKey: getContractKey(id),

    // delays execution until enabled. see https://tanstack.com/query/v4/docs/guides/dependent-queries
    queryFn: async () => {
      const { data } = await buyerAxios.get<Contract>(getContractUrl(id));
      return data;
    },
    enabled,
  });

const createContract = async (body: CreateContractRequest) => {
  // TODO: email / token for server side access check
  const { data } = await buyerAxios.post<Contract>(getContractsUrl(), body);
  return data;
};

export const useCreateContract = (
  proposalId: string,
  onSuccess: (data: Contract) => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [`ProposalContract${proposalId}`],
    mutationFn: createContract,
    onSuccess: async (data) => {
      onSuccess(data);
      setContract(qc, data); // updates data returned from useContract()
      await clearCachedProposal(qc, proposalId); // proposal needs refetching
    },
    onError,
  });

/**
 * Set a contract value in cache
 *
 * @param qc
 * @param contract
 */
export const setContract = (qc: QueryClient, contract: Contract) => {
  qc.setQueryData(getContractKey(contract.id), contract);
};
