import DocusignPdfSignatures from 'checkout/src/components/Signing/DocusignPdfSignatures';
import {
  Button,
  CompanyInfo,
  ContactInternal,
  CreateContactRequest,
  formatDatetime,
  getErrorMessage,
  getPersonFullName,
  hasAnAcceptedProposalStatus,
  LoadingSpinner,
  MutedDiv,
  Proposal,
  SigningDocument,
  getIsSignatureRequired,
} from 'common';
import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  cancelProposalSigningDocument,
  createProposalSigningDocument,
  getView,
} from '../../services/docusign';
import { getIsPdf } from '../../utils/pdf';
import { useActiveContact } from '../../utils/utils';
import { getIsSectionMuted } from '../../utils/workflow';
import SignedPrompt from './SignedPrompt';
import SigningContactModal from './SigningContactModal';
import SomeoneElseModal from './SomeoneElseModal';

interface Props {
  companyInfo: CompanyInfo;
  onClose: () => void;
  proposal: Proposal;
}

export function toCreateContactRequest(
  signer: ContactInternal
): CreateContactRequest {
  return {
    firstName: signer.firstName ?? '',
    lastName: signer.lastName ?? '',
    email: signer.email,
    role: signer.role,
  };
}

const SigningSection = ({ proposal, companyInfo, onClose }: Props) => {
  const isSignatureRequired = getIsSignatureRequired(proposal);
  const activeContact = useActiveContact(proposal);
  const isPdf = getIsPdf();
  const [isSigningModalOpen, setIsSigningModalOpen] = useState<boolean>(false);
  const [isSomeoneElseModalOpen, setIsSomeoneElseModalOpen] =
    useState<boolean>(false);
  const [signingDocError, setSigningDocError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const hasAnAcceptedStatus = hasAnAcceptedProposalStatus(proposal);
  const [isDownloadSignedPromptOpen, setIsDownloadSignedPromptOpen] =
    useState<boolean>(!hasAnAcceptedStatus);
  const signingDoc = proposal.signingDocument;

  const gotoView = async (doc: SigningDocument) => {
    if (!doc.signer.email) {
      return;
    }

    // getView hits api asking for docusign url
    try {
      const data = await getView(
        proposal.id,
        doc.signer.email,
        proposal.status
      );
      if (data.url) {
        window.location.href = data.url;
      }
    } catch (error) {
      setSigningDocError(getErrorMessage(error));
    }
  };

  const getSigningDocErrorMessage = (): string => {
    let salesContact = 'sales contact';
    if (proposal.sellerEmail) {
      salesContact = `sales contact at ${proposal.sellerEmail}`;
    }
    return `Could not complete Docusign signing. Please reach out to your ${salesContact}.`;
  };

  const generateContract = async (
    signer: ContactInternal,
    setIsGenerating?: Dispatch<SetStateAction<boolean>>
  ) => {
    // this is what's called when buyer clicks "sign"
    try {
      setSigningDocError('');

      const signingDocument = await createProposalSigningDocument(
        proposal.id,
        activeContact.email,
        {
          provider: 'docusign',
          signingContact: toCreateContactRequest(signer),
        }
      );
      await gotoView(signingDocument);
    } catch (_ignored: unknown) {
      setIsGenerating?.(false);
      setSigningDocError(getSigningDocErrorMessage());
    }
  };

  const onSignClick = async () => {
    setIsLoading(true);
    if (signingDoc) {
      await gotoView(signingDoc);
    } else {
      await generateContract(activeContact);
    }
  };

  const cancelDocument = () => {
    setIsLoading(true);
    cancelProposalSigningDocument(
      proposal.id,
      signingDoc?.signer.email ?? '',
      'buyer cancelled'
    )
      .then(() => {
        window.location.reload();
      })
      .catch((reason: unknown) => {
        setSigningDocError(
          `Failed to cancel signing document: ${getErrorMessage(reason)}`
        );
      });
  };

  const getBody = () => {
    if (isLoading) {
      return <LoadingSpinner size={28} />;
    }

    if (signingDoc?.signedAt) {
      return (
        <div>
          <SignedPrompt
            isOpen={isDownloadSignedPromptOpen}
            onClose={() => {
              setIsDownloadSignedPromptOpen(false);
              onClose();
            }}
            proposal={proposal}
          />
          <div className="flex justify-center w-full">
            <div className="items-center">
              <div className="px-5 py-2 text-xs font-medium tracking-wide text-center text-blue-600 bg-blue-100 border border-gray-200 border-solid rounded-full sm:flex hover:text-blue-800">
                Signed via Docusign, by&nbsp;
                <span className="font-bold">
                  {getPersonFullName(signingDoc.signer)},
                </span>
                &nbsp;{formatDatetime(signingDoc.signedAt)}.&nbsp;
                {!signingDocError && (
                  <span
                    className="underline cursor-pointer"
                    onClick={() => gotoView(signingDoc)}
                  >
                    View contract
                  </span>
                )}
              </div>
              {signingDocError && (
                <div className="mt-4 mb-4 text-center sm:gap-6 text-red">
                  <div>{signingDocError}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }

    // different actions based on whether we have a signing doc generated or not
    const actionsConfig = signingDoc
      ? {
          signMessage: `Sign as: ${getPersonFullName(signingDoc.signer)}`,
          signAction: onSignClick,
          otherMessage: 'I need to change something before signing',
          otherAction: cancelDocument,
        }
      : {
          signMessage: 'Next: Sign the contract',
          signAction: () => setIsSigningModalOpen(true),
          otherMessage: 'Need someone else to sign? Click here.',
          otherAction: () => setIsSomeoneElseModalOpen(true),
        };

    const isMissingShippingAddress = !proposal.customer?.shippingAddress;

    return (
      <MutedDiv
        className="print:hidden"
        isMuted={getIsSectionMuted(proposal, 'signing')}
      >
        <div className="flex justify-center gap-2">
          <Button
            label={actionsConfig.signMessage}
            onClick={actionsConfig.signAction}
          />
        </div>
        <div className="mt-6 flex justify-center">
          <Button
            className="underline cursor-pointer"
            isDisabled={isMissingShippingAddress}
            label={actionsConfig.otherMessage}
            onClick={actionsConfig.otherAction}
            type="link"
          />
        </div>
        {signingDocError && !isSigningModalOpen && (
          <div className="text-red">{signingDocError}</div>
        )}
      </MutedDiv>
    );
  };

  if (isPdf) {
    return (
      <DocusignPdfSignatures companyInfo={companyInfo} proposal={proposal} />
    );
  }

  if (!isSignatureRequired) {
    return null;
  }

  return (
    <div className="flex justify-center w-full mt-16">
      {getBody()}
      <SigningContactModal
        handleClose={() => setIsSigningModalOpen(false)}
        isOpen={isSigningModalOpen}
        proposal={proposal}
        signWithContact={generateContract}
        signingDocError={signingDocError}
      />
      <SomeoneElseModal
        handleClose={() => setIsSomeoneElseModalOpen(false)}
        isOpen={isSomeoneElseModalOpen}
      />
    </div>
  );
};

export default SigningSection;
