import React from 'react';

interface Props {
  detail: string;
  title: string;
}
const ACHDetail = ({ title, detail }: Props) => {
  return (
    <div className="flex items-center w-full h-8 gap-2 border-b xs:text-xs sm:text-sm">
      <div className="w-1/2 font-medium">{title}</div>
      <div className="w-1/2 font-semibold">{detail}</div>
    </div>
  );
};

export default ACHDetail;
