import { saveAs } from 'file-saver';
import {
  buildFileName,
  CompanyInfo,
  Proposal,
  SigningDocument,
  SigningDocumentCreateRequest,
  SigningDocumentViewResponse,
} from 'common';
import { useOtpForAuth, useTokenForPreview } from '../utils/viewMode';
import { buyerAxios } from './api';
import { getProposalUrl } from './proposal';

const VIEW_REDIRECT_PATH = '/docusign/view-callback';

const getSigningDocumentPath = (proposalId: string, email: string) => {
  return (
    getProposalUrl(proposalId) +
    `/signing-document?email=${encodeURIComponent(email)}`
  );
};

export const createProposalSigningDocument = async (
  proposalId: string,
  email: string,
  body: SigningDocumentCreateRequest
): Promise<SigningDocument> => {
  const url = getSigningDocumentPath(proposalId, email);
  const { data } = await buyerAxios.post<SigningDocument>(url, body);
  return data;
};

export const getView = async (
  proposalId: string,
  email: string,
  status: string
): Promise<SigningDocumentViewResponse> => {
  const token = useTokenForPreview();
  const otpCode = useOtpForAuth();

  const redirectUrl = () => {
    if (token) {
      return `${window.location.protocol}//${window.location.host}${VIEW_REDIRECT_PATH}?proposal=${proposalId}&token=${token}`;
    }
    if (otpCode) {
      return `${window.location.protocol}//${window.location.host}${VIEW_REDIRECT_PATH}?proposal=${proposalId}&otp_code=${otpCode}&email=${email}`;
    }
    return `${window.location.protocol}//${window.location.host}${VIEW_REDIRECT_PATH}?proposal=${proposalId}&email=${email}&original_status=${status}`;
  };
  const encodedRedirect = encodeURIComponent(redirectUrl());
  const url =
    getProposalUrl(proposalId) + `/signing-view?returnUrl=${encodedRedirect}`;

  const { data } = await buyerAxios.get<SigningDocumentViewResponse>(url);
  return data;
};

export const downloadSigningDocument = async (
  proposal: Proposal,
  companyInfo: CompanyInfo
): Promise<void> => {
  const id: string = proposal.id;
  const url: string = `${getProposalUrl(id)}/signing-document/content`;
  const { data } = await buyerAxios.get<Blob>(url, {
    responseType: 'blob',
  });

  const fileName = buildFileName(companyInfo, proposal);

  saveAs(data, fileName);
};

export const updateSigningDocument = async (
  proposalId: string,
  isPolling?: boolean
): Promise<Proposal> => {
  const url =
    getProposalUrl(proposalId) +
    `/signing-update${isPolling ? '?poll=true' : ''}`;
  const { data } = await buyerAxios.post<Proposal>(url);
  return data;
};

export const cancelProposalSigningDocument = async (
  proposalId: string,
  email: string,
  reason: string
): Promise<SigningDocument> => {
  const url =
    getSigningDocumentPath(proposalId, email) +
    '&reason=' +
    encodeURIComponent(reason);
  const { data } = await buyerAxios.delete<SigningDocument>(url);
  return data;
};
