import {
  Api as AutoGeneratedApi,
  log,
  logError,
  logRejected,
  logResponse,
  SHARED_AXIOS_CONFIG,
  SHARED_HEADERS,
} from 'common';

import { getApiUrl, getSubdomainParam } from './helper';
import { ENVIRONMENT_CONFIG } from '../config/hosts';

const axiosHeaders: Record<string, string> = { ...SHARED_HEADERS };

const hostParam = getSubdomainParam();
if (hostParam) {
  axiosHeaders.CF_ORG_SUBDOMAIN = hostParam;
}

export const checkoutClient = new AutoGeneratedApi({
  ...SHARED_AXIOS_CONFIG,
  baseURL: getApiUrl(),
  headers: axiosHeaders,
});

// some helpful debugging
checkoutClient.instance.interceptors.request.use((config) => {
  log.trace(`${config.method} ${config.url}`);

  // eslint-disable-next-line camelcase
  config.headers.ui_request_path = window.location.pathname;
  config.headers.Authorization = `Bearer ${ENVIRONMENT_CONFIG.token}`;

  log.debug(config.headers);
  return config;
}, logRejected);

checkoutClient.instance.interceptors.response.use(logResponse, logError);
