import React from 'react';
import { AuthProvider } from '../Auth';
import DocusignViewCallback from './DocusignViewCallback';

const AuthDocusignViewCallback: React.FC = () => {
  return (
    <AuthProvider>
      <DocusignViewCallback />
    </AuthProvider>
  );
};

export default AuthDocusignViewCallback;
