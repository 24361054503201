import { Proposal, ScheduleChart, useTranslation } from 'common';
import React from 'react';
import { SelectedOptionProps } from '../../../types/model';
import { getIsPdf } from '../../../utils/pdf';
import { getPaymentTermDescriptionKey } from '../../../utils/proposalUtils';
import SubTitle from '../../SubTitle';

interface Props {
  isDisabled?: boolean;
  proposal: Proposal;
  selectedOptions: SelectedOptionProps;
}

const PaymentSchedule = ({ isDisabled, proposal, selectedOptions }: Props) => {
  const selectedSchedule = selectedOptions.selectedSchedule!;
  const { tk } = useTranslation();
  const termText = tk(getPaymentTermDescriptionKey(proposal));
  const isPdf = getIsPdf();
  const termComponent = !termText ? undefined : (
    <div>
      <div className="text-sm">{termText}</div>
    </div>
  );
  return (
    <div>
      <div className="xs:mt-8 sm:hidden">
        <SubTitle title="Upcoming Payments" />
      </div>

      <ScheduleChart
        infoSection={termComponent}
        isDisabled={isDisabled}
        proposal={proposal}
        schedule={selectedSchedule}
        hideButtonAndTitle={isPdf}
        forceDisplayAllBars={isPdf}
      />
    </div>
  );
};

export default PaymentSchedule;
