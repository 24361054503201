import React from 'react';
import { useQueryClient } from '@tanstack/react-query';

import {
  Button,
  ButtonBar,
  isChangeProposalType,
  log,
  Modal,
  Proposal,
} from 'common';

import { useAcceptProposal } from '../../services/proposal';
import SignedBillingPrompt from './SignedBillingPrompt';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  proposal: Proposal;
}

const SignedPrompt: React.FC<Props> = ({ isOpen, onClose, proposal }) => {
  const queryClient = useQueryClient();

  const { mutate: acceptProposal, isPending: isLoading } = useAcceptProposal(
    proposal.id,
    onClose,
    (error: unknown) => {
      log.warn('change proposal accept endpoint error', error);
    },
    queryClient
  );

  if (!isChangeProposalType(proposal)) {
    return (
      <SignedBillingPrompt
        isOpen={isOpen}
        onClose={onClose}
        proposal={proposal}
      />
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      width={600}
      onClose={onClose}
      header="Contract successfully signed!"
      footer={
        <ButtonBar>
          <Button
            isLoading={isLoading}
            label="Accept change"
            onClick={() => {
              acceptProposal({});
            }}
          />
        </ButtonBar>
      }
    >
      Next: Accept change proposal
    </Modal>
  );
};

export default SignedPrompt;
