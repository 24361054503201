import React from 'react';
import './PrintWrapper.scss';

export default ({ children }: { children: React.ReactNode }) => {
  return (
    <div>
      <div id="print-page-fill" />
      <table id="print-page-table">
        <thead>
          <tr>
            <td className="print-page-cell">
              <div className="print-header">{/* header print space */}</div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="print-page-cell">
              <div className="print-content">{children} </div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td className="print-page-cell">
              <div className="print-footer">{/* footer print space */}</div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};
