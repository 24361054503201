import { Contract, hasAnAcceptedProposalStatus, Proposal } from 'common';

export const findPaymentScheduleById = (
  proposal: Proposal,
  scheduleid?: string
) => {
  if (!scheduleid) {
    const defaultScheduleOrFirst =
      proposal.schedules.find(
        (schedule) => schedule.payEvery === proposal.defaultBillingPeriod
      ) || proposal.schedules[0];
    return {
      foundSchedule: defaultScheduleOrFirst,
    };
  }

  const paymentSchedule = proposal.schedules.find((s) => s.id === scheduleid);

  return {
    foundSchedule: paymentSchedule,
  };
};

export const getPaymentTermDescriptionKey = (proposal: Proposal) => {
  const termName = proposal.paymentTerm;
  if (!termName) {
    return '';
  }
  // should be a string that looks like this term_net_60_description
  return `term_${termName}_description`;
};

export const isProposalNeedingBillingAfterApproval = (
  contract: Contract | undefined,
  proposal: Proposal
): boolean => {
  const needsBillingForRenewal =
    !proposal.billingMethod &&
    hasAnAcceptedProposalStatus(proposal) &&
    proposal.proposalType === 'renewal';

  return (
    needsBillingForRenewal ||
    (!!contract &&
      !contract.billingMethod &&
      hasAnAcceptedProposalStatus(proposal) &&
      proposal.proposalType !== 'renewal')
  );
};
