import React from 'react';
import {
  AutoRenewalChange,
  CustomTermsChange,
  ItemsChange,
  Proposal,
} from 'common';

interface Props {
  proposal: Proposal;
}

const ProposalChangesList: React.FC<Props> = ({ proposal }) => {
  if (!proposal.changes) {
    return null;
  }

  return (
    <>
      <CustomTermsChange proposal={proposal} changes={proposal.changes} />
      <AutoRenewalChange proposal={proposal} changes={proposal.changes} />
      <ItemsChange
        proposal={proposal}
        changes={proposal.changes}
        isChangeTopDrawer
      />
    </>
  );
};

export default ProposalChangesList;
