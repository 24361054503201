import { formatRichText } from 'common';

interface Props {
  terms: string;
}

const CustomTerms = ({ terms }: Props) => {
  return formatRichText(terms);
};

export default CustomTerms;
