import { useActiveEmail } from '../../utils/utils';
import React, { useState } from 'react';
import {
  Auth,
  createAuthTokenRequest,
  useAuth,
  useUpdateAuthToken,
} from '../../Auth';
import { Button, ButtonBar, FormField, Input, Modal } from 'common';
import styles from './OptModal.module.scss';

interface Props {
  handleClose: () => void;
  isOpen: boolean;
  proposalId: string;
}

const OtpModal = ({ proposalId, isOpen, handleClose }: Props) => {
  const [otpCode, setOtpCode] = useState('');
  const [error, setError] = useState('');
  const authState = useAuth();
  const email = useActiveEmail();

  const handleSubmit = () => {
    setError('');
    mutate(createAuthTokenRequest(proposalId, email, otpCode, null));
  };

  const { mutate } = useUpdateAuthToken(
    (data: Auth) => {
      authState.setAuthState(data.token, data.role);
      handleClose();
    },
    () => {
      setError(
        'Could not validate the passcode, cancel and request another one.'
      );
    }
  );

  return (
    <Modal
      className={styles.optModal}
      isOpen={isOpen}
      onClose={() => handleClose()}
    >
      <div className={styles.padding}>
        <FormField
          label={`A one-time passcode has been sent to your email ${
            email ? `(${email})` : ''
          }. Please enter the passcode from your email below:`}
          errorToShow={error}
        >
          <Input
            onChange={(value) => setOtpCode(value)}
            placeholder="Enter code"
          />
        </FormField>
        <ButtonBar>
          <Button
            onClick={() => handleClose()}
            type="secondary"
            label="Cancel"
          />
          <Button
            label="Enter"
            isDisabled={otpCode === ''}
            onClick={handleSubmit}
          />
        </ButtonBar>
      </div>
    </Modal>
  );
};

export default OtpModal;
