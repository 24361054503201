import React, { SVGProps } from 'react';
import { LoadingSpinner } from 'common';
import styles from './PageAction.module.scss';

interface Props {
  icon: React.ComponentType<SVGProps<SVGSVGElement>>;
  isLoading?: boolean;
  label: string;
  onClick: () => void;
}

const PageAction: React.FC<Props> = ({
  icon: IconProp,
  label,
  onClick,
  isLoading,
}) => (
  <button
    type="button"
    onClick={onClick}
    disabled={isLoading}
    className={styles.pageAction}
  >
    {isLoading ? (
      <LoadingSpinner size={22} stroke={1} />
    ) : (
      <IconProp height={22} width={22} />
    )}
    <div>{label}</div>
  </button>
);

export default PageAction;
