import React from 'react';
import { WarmlyConfig } from 'common';
import { useOrgDefaults } from '../services/orgConfigs';

interface Props {}

const Beacon: React.FC<Props> = () => {
  const { data: orgConfigs } = useOrgDefaults();

  const configValue = orgConfigs?.get('warmlyConfig')
    ?.configValue as WarmlyConfig;
  const beaconConfig = configValue;

  if (beaconConfig && beaconConfig.enabled) {
    const warmlyId = 'warmly-script-loader';
    const head = document.getElementsByTagName('head')[0];
    if (!document.getElementById(warmlyId)) {
      const script = document.createElement('script');
      script.id = warmlyId;
      // script.crossOrigin="anonymous";
      script.defer;
      script.type = 'text/javascript';
      script.src = `https://opps-widget.getwarmly.com/warmly.js?clientId=${beaconConfig.clientId}`;
      head?.appendChild(script);
    }
  }
  return null;
};

export default Beacon;
