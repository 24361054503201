import React from 'react';
import {
  formatDatetime,
  isAcceptedProposalStatus,
  Proposal,
  Type,
} from 'common';
import TryLink from './TryLink';
import styles from './CheckoutFooter.module.scss';
import { getIsPdf } from '../utils/pdf';

interface Props {
  proposal: Proposal;
}

const CheckoutFooter: React.FC<Props> = ({ proposal }) => {
  if (!proposal.updatedAt) {
    return null;
  }

  const isPdf = getIsPdf();

  const isAccepted = isAcceptedProposalStatus(proposal);

  if (isPdf) {
    return null;
  }

  return (
    <div className={styles.checkoutFooter}>
      <Type paragraph noMargin>
        Last changed {formatDatetime(proposal.updatedAt)}
      </Type>
      {isAccepted ? <TryLink /> : <div />}
    </div>
  );
};

export default CheckoutFooter;
