import React from 'react';
import Method from './Method';
import ACH from './Methods/ACH';
import Wire from './Methods/Wire';
import Check from './Methods/Check';
import Manual from './Methods/Manual';
import CreditCard from './Methods/CreditCard';
import DirectDebit from './Methods/DirectDebit';
import {
  captureError,
  CheckoutPaymentMethod,
  CompanyInfo,
  Contract,
  MutedDiv,
  Proposal,
} from 'common';
import { getIsSectionDisabled, getIsSectionMuted } from '../../utils/workflow';
import Custom from './Methods/Custom';
import { SelectedOptionProps } from '../../types/model';
import './Methods/methods.scss';
import { useIsPreview } from '../../utils/viewMode';
import { getEnabledMethodsForProposal } from '../../utils/payment';

interface Props {
  companyInfo: CompanyInfo;
  contract: Contract | undefined;
  isEditable: boolean;
  paymentMethods: CheckoutPaymentMethod[];
  proposal: Proposal;
  selectedOptions: SelectedOptionProps;
  setSelectedMethodId: (method: string) => void;
}

const Methods = ({
  proposal,
  contract,
  companyInfo,
  paymentMethods,
  selectedOptions,
  isEditable,
  setSelectedMethodId,
}: Props) => {
  const isPreview = useIsPreview();

  const isSelected = (paymentMethod: CheckoutPaymentMethod) => {
    return selectedOptions.selectedMethodId === paymentMethod.id;
  };

  const selectedPaymentMethod = paymentMethods.find((m) => isSelected(m));
  const paymentSchedule = selectedOptions.selectedSchedule;

  if (!selectedPaymentMethod) {
    captureError(
      new Error('Payment method is not available at checkout'),
      {
        proposalId: proposal.id,
      },
      'info'
    );

    return <>Payment method not available.</>;
  }

  const isCurrentPaymentMethod = (paymentMethod?: CheckoutPaymentMethod) => {
    return contract?.billingMethod?.paymentMethod.name === paymentMethod?.name;
  };

  const getMethodDetail = () => {
    const isCurrent = isCurrentPaymentMethod(selectedPaymentMethod);
    const type = selectedPaymentMethod.paymentType;

    const editableMethod = !isDisabledMethod();

    switch (type) {
      case 'ach':
        return (
          <ACH
            proposal={proposal}
            contract={contract}
            isEditable={editableMethod}
            companyInfo={companyInfo}
            paymentMethod={selectedPaymentMethod}
            isCurrent={isCurrent}
          />
        );
      case 'direct_debit':
        return (
          <DirectDebit
            proposal={proposal}
            contract={contract}
            isEditable={editableMethod}
            companyInfo={companyInfo}
            paymentSchedule={paymentSchedule}
            paymentMethod={selectedPaymentMethod}
            isCurrent={isCurrent}
          />
        );
      case 'wire':
        return <Wire />;
      case 'cc':
        return (
          <CreditCard
            proposal={proposal}
            contract={contract}
            isEditable={editableMethod}
            paymentMethods={paymentMethods}
            companyInfo={companyInfo}
            paymentSchedule={paymentSchedule}
            paymentMethod={selectedPaymentMethod}
            isCurrent={isCurrent}
          />
        );
      case 'check':
        return <Check />;
      case 'manual':
        return (
          <Manual
            proposal={proposal}
            contract={contract}
            paymentMethod={selectedPaymentMethod}
            isEditable={editableMethod}
            isCurrent={isCurrent}
          />
        );
      case 'custom':
        return (
          <Custom
            proposal={proposal}
            contract={contract}
            paymentMethod={selectedPaymentMethod}
            isEditable={editableMethod}
            isCurrent={isCurrent}
          />
        );
      default:
        return 'Not Supported';
    }
  };

  const contractNeedsPayment = (): boolean => {
    return !!contract && !contract.billingMethod;
  };

  const isDisabledMethod = () => {
    if (isPreview) {
      return true;
    }

    if (contractNeedsPayment()) {
      return false;
    }

    // its disabled only if the paymentSelection is displayed because the proposal is not in a state to be changed
    // and its not editable, meaning the user is not authorized to change the payment method
    return getIsSectionDisabled(proposal, 'payment_methods') && !isEditable;
  };

  return (
    <MutedDiv
      isMuted={getIsSectionMuted(proposal, 'payment_methods')}
      className="break-inside-avoid mt-8"
    >
      <h2 className="text-xl font-extrabold mb-4">
        How would you like to pay?
      </h2>

      <div className="grid gap-0 grid-cols-1 sm:grid-cols-2 sm:gap-6">
        <div>
          {getEnabledMethodsForProposal(paymentMethods, selectedOptions)?.map(
            (paymentMethod) => {
              return (
                <Method
                  key={paymentMethod.id}
                  isDisabled={isDisabledMethod()}
                  isSelected={isSelected(paymentMethod)}
                  paymentMethod={paymentMethod}
                  dataTestId={paymentMethod.paymentType + '-method'}
                  setSelectedMethodId={setSelectedMethodId}
                />
              );
            }
          )}
        </div>

        <div>{getMethodDetail()}</div>
      </div>
    </MutedDiv>
  );
};

export default Methods;
