import React from 'react';
import {
  Button,
  Contract,
  getErrorMessage,
  hasAnAcceptedProposalStatus,
  isChangeProposalType,
  log,
  Proposal,
} from 'common';
import { useQueryClient } from '@tanstack/react-query';
import { useAcceptProposal } from 'checkout/src/services/proposal';
import { getIsPdf } from '../../utils/pdf';
import { getIsAwaitingSignature } from '../../utils/workflow';
import { useOrgDefaults } from '../../services/orgConfigs';

interface Props {
  contract?: Contract;
  onError?: (message?: string) => void;
  onSuccess?: () => void;
  proposal: Proposal;
}

const AcceptChangeSection = ({
  proposal,
  contract,
  onSuccess,
  onError,
}: Props) => {
  const isPdf = getIsPdf();
  const requiresSigning = proposal.options?.requiresSigning || false;
  const {
    data: orgDefaults,
    isLoading: isOrgDefaultsLoading,
    error: orgDefaultsError,
  } = useOrgDefaults();

  const queryClient = useQueryClient();

  const handleSuccess = () => {
    onSuccess?.();
  };

  const handleError = (error: unknown) => {
    log.warn('change proposal accept endpoint error', error);
    onError?.(getErrorMessage(error) ?? undefined);
  };

  const { mutate, isPending: isLoading } = useAcceptProposal(
    proposal.id,
    handleSuccess,
    handleError,
    queryClient
  );

  const doAcceptChange = () => {
    mutate({});
  };

  if (
    isPdf ||
    !isChangeProposalType(proposal) ||
    hasAnAcceptedProposalStatus(proposal) ||
    !contract
  ) {
    return null;
  }

  if (isOrgDefaultsLoading || !orgDefaults || orgDefaultsError) return null;

  // if accept on signing is enabled and this proposal needs a signature,
  // don't show the accept button
  if (orgDefaults.get('acceptOnSigning')?.intValue === 1 && requiresSigning)
    return null;

  return (
    <div className="flex justify-center w-full mt-16 py-4">
      <Button
        dataTestId="accept-change-button"
        isDisabled={getIsAwaitingSignature(proposal)}
        isLoading={isLoading}
        label="Accept change"
        onClick={doAcceptChange}
      />
    </div>
  );
};

export default AcceptChangeSection;
