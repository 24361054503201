import React from 'react';
import { CompanyInfo } from 'common';
import { getCompanyLogoUrl } from '../../utils/utils';
import styles from './Logo.module.scss';

const Logo = ({ companyInfo }: { companyInfo?: CompanyInfo }) => {
  const imageSrc = getCompanyLogoUrl(companyInfo);

  return (
    <div className={styles.logo}>
      {imageSrc && (
        <img
          src={imageSrc}
          alt={companyInfo?.name || 'Company Logo'}
          className={styles.image}
        />
      )}
    </div>
  );
};

export default Logo;
