import React, { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  Button,
  Contact,
  emailValidator,
  FormField,
  getErrorMessage,
  Input,
  log,
  Modal,
  useFormValidation,
} from 'common';
import { useCreateBillingContact } from '../../services/proposal';
import { useActiveEmail } from '../../utils/utils';

interface Props {
  isOpen: boolean;
  onBack?: () => void;
  onClose: () => void;
  proposalId: string;
}

interface BillingContact {
  email: string;
  firstName?: string;
  lastName?: string;
}

const AddBillingContactModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onBack,
  proposalId,
}) => {
  const queryClient = useQueryClient();
  const [billingContact, setBillingContact] = useState<Partial<BillingContact>>(
    {}
  );
  const { getErrorToShow, setHasVisitedField, isFormValid, resetUIState } =
    useFormValidation<BillingContact>(
      [
        {
          fieldName: 'firstName',
          humanReadableName: 'First name',
          isRequired: true,
        },
        {
          fieldName: 'lastName',
          humanReadableName: 'Last name',
          isRequired: true,
        },
        {
          isRequired: true,
          fieldName: 'email',
          validator: emailValidator,
          humanReadableName: 'Email',
        },
      ],
      billingContact
    );

  useEffect(() => {
    setBillingContact({});
    resetUIState();
  }, [isOpen]);

  const onChange = (change: Partial<BillingContact>) => {
    setBillingContact((bc) => ({ ...bc, ...change }));
  };

  const getOnChangeFor =
    (fieldName: keyof BillingContact) => (value: unknown) =>
      onChange({ [fieldName]: value });

  const getInputPropsFor = (fieldName: keyof BillingContact) => ({
    className: 'h-10',
    errorToShow: getErrorToShow(fieldName),
    initialValue: billingContact[fieldName],
    onBlur: () => setHasVisitedField(fieldName),
    onChange: getOnChangeFor(fieldName),
    onEnter: submit,
  });

  const { mutate, isPending: isLoading } = useCreateBillingContact(
    proposalId,
    useActiveEmail()
  )(
    () => {
      onClose();
    },
    (error: unknown) => {
      log.warn('error creating billing contact', getErrorMessage(error));
    },
    queryClient
  );

  const submit = () => {
    if (isFormValid) {
      mutate(billingContact as Contact);
    }
  };

  return (
    <Modal
      width={600}
      onClose={onClose}
      isOpen={isOpen}
      header="Add billing contact"
      footer={
        <div className="flex justify-between">
          {onBack ? (
            <Button
              className="mr-2"
              label="Go back"
              onClick={onBack}
              type="link"
            />
          ) : (
            <div // empty div to make justify-between have desired effect
            />
          )}
          <div className="flex gap-4">
            <Button label="Cancel" onClick={onClose} type="secondary" />
            <Button
              isLoading={isLoading}
              label="Email instructions to billing contact"
              onClick={submit}
              isDisabled={!isFormValid}
            />
          </div>
        </div>
      }
    >
      <div className="bg-orange-lightest p-4 -mt-4 -mr-4 -ml-4 mb-4 border border-white">
        Enter the billing contact information below and Cacheflow will send an
        email prompting them to complete the payment. The information they enter
        will be used as the billing information for your subscription.
      </div>
      <div className="text-lg font-bold mb-4">Billing contact information</div>

      <div className="grid gap-x-4 grid-cols-2">
        <FormField label="First name">
          <Input {...getInputPropsFor('firstName')} />
        </FormField>

        <FormField label="Last name">
          <Input {...getInputPropsFor('lastName')} />
        </FormField>

        <FormField className="col-span-2" label="Email">
          <Input {...getInputPropsFor('email')} />
        </FormField>
      </div>
    </Modal>
  );
};

export default AddBillingContactModal;
