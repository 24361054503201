import {
  QueryClient,
  useMutation,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import { buyerAxios } from './api';
import {
  Address,
  buildFileName,
  BuyerProposalRequest,
  BuyerUpdateCustomerRequest,
  CompanyInfo,
  Contact,
  Customer,
  isSeller,
  ItemDeltaSpansResponse,
  LocationRest,
  log,
  Proposal,
  ProposalEvent,
  VerifyAddressResponse,
} from 'common';
import { saveAs } from 'file-saver';
import { checkoutClient } from './checkoutClient';
import { getApiUrl } from './helper';
import { useActiveEmail } from '../utils/utils';
import { useIsPreview } from '../utils/viewMode';

const PROPOSAL_URL = '/api/latest/proposals/';
const PROPOSAL_KEY = 'proposal';
const DELTA_SPANS_KEY = 'delta-spans';

export const getProposalUrl = (proposalId: string) => {
  return getApiUrl(`${PROPOSAL_URL}${proposalId}`);
};

const getUpdateCustomerUrl = (proposalId: string, email: string) => {
  return (
    getProposalUrl(proposalId) + '/customer?email=' + encodeURIComponent(email)
  );
};

const getProposalPdfUrl = (proposalId: string, email: string) => {
  const proposalUrl = getProposalUrl(proposalId);
  return `${proposalUrl}/pdf-view?email=${encodeURIComponent(email)}`;
};

const getBillingContactUrl = (proposalId: string, email: string) => {
  const proposalUrl = getProposalUrl(proposalId);
  return `${proposalUrl}/billing-invites?email=${encodeURIComponent(email)}`;
};

const getPatchProposalUrl = (proposalId: string, email: string) => {
  log.trace(
    'TEST',
    getProposalUrl(proposalId) +
      '/checkout-state?email=' +
      encodeURIComponent(email)
  );
  return (
    getProposalUrl(proposalId) +
    '/checkout-state?email=' +
    encodeURIComponent(email)
  );
};

export const useProposal = (proposalId: string): UseQueryResult<Proposal> => {
  const email = useActiveEmail();
  const isPreview = useIsPreview();

  const peek: boolean =
    new URLSearchParams(window.location.search).get('peek') === 'true' ||
    isSeller();

  return useQuery({
    queryKey: [PROPOSAL_KEY, getProposalUrl(proposalId)],
    queryFn: async () => {
      const { data } = isPreview
        ? await checkoutClient.previewProposal(proposalId)
        : await checkoutClient.viewProposal(proposalId, { email, peek });
      return data;
    },
  });
};

/**
 * Update the cached result for GET /proposals/id/view?email=... to this value.
 * Used by write operations to keep cached GET value current.
 *
 * We assume that preview mode doesn't allow any writes.
 */
export const setProposal = (qc: QueryClient, proposal: Proposal) => {
  qc.setQueryData([PROPOSAL_KEY, getProposalUrl(proposal.id)], proposal);
};

export const clearCachedProposal = async (
  qc: QueryClient,
  proposalId: string
) => {
  await qc.invalidateQueries({
    queryKey: [PROPOSAL_KEY, getProposalUrl(proposalId)],
  });
};

export const downloadProposalPdf = async (
  proposal: Proposal,
  companyInfo: CompanyInfo
): Promise<void> => {
  // called if ssr is being used
  const email = useActiveEmail();
  const url: string = getProposalPdfUrl(proposal.id, email);
  const { data } = await buyerAxios.get<Blob>(url, {
    responseType: 'blob',
  });

  const fileName = buildFileName(companyInfo, proposal);

  saveAs(data, fileName);
};

const patchCheckoutSchedule =
  (proposalId: string, email: string) => async (body: BuyerProposalRequest) => {
    const url = getPatchProposalUrl(proposalId, email);
    const { data } = await buyerAxios.patch(url, body);
    return data as Proposal;
  };

export const usePatchCheckoutSchedule =
  (proposalId: string, email: string) =>
  (
    onSuccess: (data: Proposal) => void,
    onError: (error: unknown) => void,
    qc: QueryClient
  ) =>
    useMutation({
      mutationKey: [`patch-checkout-${proposalId}`],
      mutationFn: patchCheckoutSchedule(proposalId, email),
      onSuccess: (data) => {
        onSuccess(data);
        setProposal(qc, data);
      },
      onError,
    });

const createBillingContact =
  (proposalId: string, email: string) => async (body: Contact) => {
    const url = getBillingContactUrl(proposalId, email);
    const { data } = await buyerAxios.post(url, body);
    return data as Proposal;
  };

export const useCreateBillingContact =
  (proposalId: string, email: string) =>
  (onSuccess: () => void, onError: (error: unknown) => void, qc: QueryClient) =>
    useMutation({
      mutationKey: [`create-billing-contact-checkout-${proposalId}`],
      mutationFn: createBillingContact(proposalId, email),
      onSuccess: (data) => {
        onSuccess();
        setProposal(qc, data);
      },
      onError,
    });

const updateCustomerAsBuyer =
  (proposalId: string, email: string) =>
  async (body: BuyerUpdateCustomerRequest) => {
    const url = getUpdateCustomerUrl(proposalId, email);
    const { data } = await buyerAxios.patch(url, body);
    return data as Customer;
  };

export const useUpdateCustomerAsBuyer =
  (proposalId: string, email: string) =>
  (
    onSuccess: (data: Customer) => void,
    onError: (error: unknown) => void,
    qc: QueryClient
  ) =>
    useMutation({
      mutationKey: ['UpdateCustomerAsBuyer'],
      mutationFn: updateCustomerAsBuyer(proposalId, email),
      onSuccess,
      onError,
      onSettled: async () => {
        await qc.invalidateQueries({ queryKey: [PROPOSAL_KEY] });
      },
    });

const verifyAddress =
  (proposalId: string, email: string) =>
  async (body: Address): Promise<VerifyAddressResponse> => {
    const url =
      getProposalUrl(proposalId) +
      '/verify-address?email=' +
      encodeURIComponent(email);
    const { data } = await buyerAxios.post(url, body);
    return data;
  };

export const useVerifyAddress =
  (proposalId: string, email: string) =>
  (
    onSuccess: (data: VerifyAddressResponse) => void,
    onError: (error: unknown) => void,
    qc: QueryClient
  ) =>
    useMutation({
      mutationKey: ['VerifyAddressAsBuyer'],
      mutationFn: verifyAddress(proposalId, email),
      onSuccess,
      onError,
      onSettled: async () => {
        await qc.invalidateQueries({ queryKey: [PROPOSAL_KEY] });
      },
    });

const acceptVerifiedAddress =
  (proposalId: string, email: string) =>
  async (body: Address): Promise<Address> => {
    const url =
      getProposalUrl(proposalId) +
      '/verified-address?email=' +
      encodeURIComponent(email);
    const { data } = await buyerAxios.put(url, body);
    return data as Address;
  };

export const useAcceptVerifiedAddress =
  (proposalId: string, email: string) =>
  (
    onSuccess: (data: Address) => void,
    onError: (error: unknown) => void,
    qc: QueryClient
  ) =>
    useMutation({
      mutationKey: ['AcceptVerifiedAddressAsBuyer'],
      mutationFn: acceptVerifiedAddress(proposalId, email),
      onSuccess,
      onError,
      onSettled: async () => {
        await qc.invalidateQueries({ queryKey: [PROPOSAL_KEY] });
      },
    });

export const createProposalEvent = async (
  proposalId: string,
  checkoutUserEmail: string,
  event: ProposalEvent
): Promise<ProposalEvent> => {
  const { data } = await buyerAxios.post<Proposal>(
    getProposalUrl(proposalId) +
      '/events?email=' +
      encodeURIComponent(checkoutUserEmail),
    event
  );
  return data as ProposalEvent;
};

const acceptProposal = (proposalId: string) => async (body: {}) => {
  const url = getProposalUrl(proposalId);
  const { data } = await buyerAxios.post<Proposal>(`${url}/accept`, body);
  return data;
};

export const useAcceptProposal = (
  proposalId: string,
  onSuccess: (data: Proposal) => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: ['AcceptProposal', proposalId],
    mutationFn: acceptProposal(proposalId),
    onSuccess: async (data) => {
      onSuccess(data);
      await clearCachedProposal(qc, proposalId); // proposal needs refetching
    },
    onError,
  });

const getTaxExemptCodes = async () => {
  const { data } = await buyerAxios.get(
    '/api/latest/settings/exempt-use-codes'
  );
  return data;
};

export const useTaxExemptCodes = (options = {}): UseQueryResult<string[]> =>
  useQuery({
    queryKey: ['tax-exempt-codes'],
    queryFn: getTaxExemptCodes,
    ...options,
  });

export const useCountries = (): UseQueryResult<LocationRest[]> =>
  useQuery({
    queryKey: ['countries'],
    queryFn: async () => {
      const { data } = await buyerAxios.get('/api/latest/settings/countries');
      return data;
    },
  });

export const useRegion = (iso: string): UseQueryResult<LocationRest[]> =>
  useQuery({
    queryKey: ['regions', iso],
    queryFn: async () => {
      const { data } = await buyerAxios.get(
        `/api/latest/settings/countries/${iso}/regions`
      );
      return data;
    },
    enabled: !!iso,
    select: (data) => data ?? [],
  });

export const setItemDeltaSpans = (
  qc: QueryClient,
  itemDeltaSpans: ItemDeltaSpansResponse[],
  proposalId: string
) => {
  qc.setQueryData([DELTA_SPANS_KEY, proposalId], itemDeltaSpans);
};

export const useGetProposalDeltas = (
  proposalId: string
): UseQueryResult<ItemDeltaSpansResponse[]> => {
  const email = useActiveEmail();
  const isPreview = useIsPreview();

  return useQuery<ItemDeltaSpansResponse[]>({
    queryKey: [DELTA_SPANS_KEY, proposalId],
    queryFn: async () => {
      const { data } = isPreview
        ? await checkoutClient.previewProposalDeltaSpans(proposalId)
        : await checkoutClient.getProposalDeltaSpans(proposalId, { email });

      return data;
    },
  });
};
