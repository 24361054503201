import React from 'react';
import { Button, ButtonBar, ExternalLink, Icon, Modal, Type } from 'common';
import CfLogo from '../../../../assets/CfLogo';
import PlaidLogo from '../../../../assets/PlaidLogo';
import styles from './PlaidModal.module.scss';

interface Props {
  handleClose: () => void;
  isOpen: boolean;
}

const PlaidModal = ({ isOpen, handleClose }: Props) => {
  const header: React.ReactNode = (
    <div className={styles.logos}>
      <PlaidLogo className={styles.plaidLogo} />
      <Icon.Plus />
      <CfLogo className={styles.cacheFlowLogo} />
    </div>
  );

  const footer: React.ReactNode = (
    <ButtonBar>
      <Button label="Ok" onClick={handleClose} />
    </ButtonBar>
  );

  return (
    <Modal
      className={styles.plaidModal}
      footer={footer}
      header={header}
      isOpen={isOpen}
      onClose={handleClose}
      width={700}
    >
      <Type header={3}>How is my data secured?</Type>
      <Type paragraph>
        Plaid uses encryption protocols like the Advanced Encryption Standard
        (AES 256) and Transport Layer Security (TLS) when transmitting financial
        data. Knowing that your data is transmitted using these security
        settings may give you an extra layer of confidence.
      </Type>

      <Type header={3}>About Plaid</Type>
      <Type paragraph>
        Plaid helps all companies build fintech solutions by making it easy,
        safe and reliable for people to connect their financial data to apps and
        services.
      </Type>

      <Type paragraph semiBold>
        Website:{' '}
        <ExternalLink href="https://plaid.com/">www.plaid.com</ExternalLink>
      </Type>
    </Modal>
  );
};

export default PlaidModal;
