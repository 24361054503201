import React, { useState } from 'react';
import { Bordered, CompanyInfo, Icon, log, Proposal } from 'common';
import { downloadSigningDocument } from '../services/docusign';
import { useActiveEmail } from '../utils/utils';
import logEvent from '../utils/logEvent';
import { downloadProposalPdf } from '../services/proposal';
import PageAction from './PageAction';

interface Props {
  companyInfo: CompanyInfo;
  proposal: Proposal;
}

const PageActions = ({ proposal, companyInfo }: Props) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const checkoutUserEmail = useActiveEmail();

  const generateAndSendPDF = async () => {
    setIsDownloading(true);
    await logEvent('buyer_downloaded', proposal.id, checkoutUserEmail);

    try {
      if (proposal.signed) {
        await downloadSigningDocument(proposal, companyInfo);
      } else {
        await downloadProposalPdf(proposal, companyInfo);
      }
    } catch (error) {
      log.error(error);
    } finally {
      setIsDownloading(false);
    }
  };

  const print = async () => {
    await logEvent('buyer_printed', proposal.id, checkoutUserEmail);
    window.print();
  };

  return (
    <Bordered className="flex justify-end gap-2 print:hidden" borderBottom>
      <PageAction
        icon={Icon.Pdf}
        label="Download (PDF)"
        onClick={generateAndSendPDF}
        isLoading={isDownloading}
      />
      <PageAction icon={Icon.Print} label="Print" onClick={print} />
    </Bordered>
  );
};

export default PageActions;
