import {
  BillingPeriodsType,
  ErrorFetching,
  ItemDeltas,
  Loading,
  Proposal,
} from 'common';
import React from 'react';
import { useGetProposalDeltas } from '../../services/proposal';

export const ItemDeltasTab = ({
  billingPeriod,
  proposal,
}: {
  billingPeriod: BillingPeriodsType;
  proposal: Proposal;
}) => {
  const { data: deltas, isError } = useGetProposalDeltas(proposal.id);

  if (deltas) {
    return (
      <ItemDeltas
        billingPeriod={billingPeriod}
        deltas={deltas}
        proposal={proposal}
      />
    );
  }

  if (isError) {
    return <ErrorFetching />;
  }

  return <Loading />;
};
