import { allFlagsOff, defaultTestFlags, Flags, log } from 'common';

const ENVIRONMENT = {
  local: 'local',
  demo: 'demo',
  ci: 'ci',
  development: 'development',
  sandbox: 'sandbox',
  staging: 'staging',
  production: 'production',
};

const defaultKeys = {
  stripeKey:
    'pk_test_51IZMGQCjJNpVEdddiiwuy9R4vug0YO70SWiMF5zl1Ng97z0zvQVsic8d1B9NNRBDZ8dtQDw3HHbiohRYtRsowen000ZbBY7fYJ',
  ldClientSecret: undefined,
};

interface EnvironmentConfig {
  apiHost: string;
  defaultFlags: Flags;
  env: string;
  isMonitored: boolean;
  isProduction: boolean;
  ldClientSecret: string | undefined;
  sentryKey: string;
  shouldUseHttps: boolean;
  stripeKey: string;
  token: string;
  postHogPublicKey: string;
  postHogApiHost: string;
  isAnalyticsEnabled?: boolean;
}

const baseConfig = {
  token: '',
  stripeKey: process.env.STRIPE_KEY || defaultKeys.stripeKey,
  ldClientSecret: process.env.LD_SECRET_KEY || defaultKeys.ldClientSecret,
  defaultFlags: defaultTestFlags,
  isMonitored: process.env.BRANCH !== 'local',
  sentryKey:
    process.env.SENTRY_KEY ||
    'https://b60290467c9b4aee94f60be3bf723907@o1012459.ingest.sentry.io/6229131',
  postHogPublicKey:
    process.env.REACT_APP_PUBLIC_POSTHOG_KEY ||
    'phc_HerKBHLkm6NwQkOnxJCdfZUEb6eLHOVxLwjYmfcABfC',
  postHogApiHost:
    process.env.REACT_APP_PUBLIC_POSTHOG_HOST || 'https://us.i.posthog.com',
  isAnalyticsEnabled: process.env.BRANCH !== 'local',
};

const localConfig: EnvironmentConfig = {
  ...baseConfig,
  shouldUseHttps: false,
  apiHost: 'localhost:8080',
  isProduction: false,
  env: ENVIRONMENT.local,
};

const environmentConfig: { [key: string]: EnvironmentConfig } = {
  [ENVIRONMENT.local]: localConfig,
  [ENVIRONMENT.development]: {
    ...baseConfig,
    shouldUseHttps: true,
    apiHost: 'api.dev.getcacheflow.com',
    isProduction: false,
    env: ENVIRONMENT.development,
  },
  [ENVIRONMENT.ci]: {
    ...baseConfig,
    shouldUseHttps: true,
    apiHost: 'api.dev.getcacheflow.com',
    isProduction: false,
    env: ENVIRONMENT.development,
  },
  [ENVIRONMENT.demo]: {
    ...baseConfig,
    shouldUseHttps: true,
    apiHost: 'api.demo.getcacheflow.com',
    isProduction: false,
    env: ENVIRONMENT.demo,
  },
  [ENVIRONMENT.staging]: {
    ...baseConfig,
    shouldUseHttps: true,
    apiHost: 'api.staging.getcacheflow.com',
    isProduction: false,
    env: ENVIRONMENT.staging,
  },
  [ENVIRONMENT.sandbox]: {
    ...baseConfig,
    shouldUseHttps: true,
    apiHost: 'api.sandbox.getcacheflow.com',
    isProduction: false,
    env: ENVIRONMENT.sandbox,
  },
  [ENVIRONMENT.production]: {
    ...baseConfig,
    defaultFlags: allFlagsOff,
    shouldUseHttps: true,
    apiHost: 'api.getcacheflow.com',
    isProduction: true,
    env: ENVIRONMENT.production,
  },
};

const currentEnvironment =
  // @ts-ignore Unsure how to configure ENVIRONMENT_NAME as a global
  ENVIRONMENT_NAME || process.env.NODE_ENV || ENVIRONMENT.local;
log.info('Environment = ' + currentEnvironment);

export const ENVIRONMENT_CONFIG =
  environmentConfig[currentEnvironment] || localConfig;

if (!ENVIRONMENT_CONFIG.isProduction) {
  log.setLevel('trace');
  log.debug(ENVIRONMENT_CONFIG);
} else {
  log.setLevel('info');
}
