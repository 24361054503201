import React from 'react';
import {
  BillingPeriodsType,
  Item,
  ItemChangeRow,
  ItemRow,
  ItemSchedule,
  ProductGrid,
  Proposal,
} from 'common';

export const ItemCheckoutTab = ({
  proposal,
  billingPeriod,
  isReadOnly,
  isPdf,
  onShowPriceModalClick,
}: {
  proposal: Proposal;
  billingPeriod: BillingPeriodsType;
  isReadOnly: boolean;
  isPdf: boolean;
  onShowPriceModalClick?: (schedule: ItemSchedule, item: Item) => void;
}) => {
  const changeForItem = (item: Item) => {
    return proposal.changes?.itemChanges?.find(
      (change) => change.key === item.key
    );
  };

  const removedItems =
    proposal.changes?.itemChanges?.filter(
      (change) => change.changeType === 'remove'
    ) ?? [];

  return (
    <ProductGrid>
      {proposal.proposalItems.map((item) => (
        <ItemRow
          billingPeriod={billingPeriod}
          isDisabled={isReadOnly}
          isPdf={isPdf}
          item={item}
          itemChange={changeForItem(item)}
          key={item.id}
          onShowPriceModalClick={onShowPriceModalClick}
          proposal={proposal}
        />
      ))}
      {removedItems.map((itemChange) => (
        <ItemChangeRow itemChange={itemChange} key={itemChange.key} />
      ))}
    </ProductGrid>
  );
};
