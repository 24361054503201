import React from 'react';
import {
  arrayTop,
  BuyerProposalRequest,
  CheckoutPaymentMethod,
  getBillingPeriodLabelAdjective,
  getErrorMessage,
  Icon,
  log,
  PaymentSchedule,
  Proposal,
  RadioBlock,
} from 'common';

import { useQueryClient } from '@tanstack/react-query';
import { usePatchCheckoutSchedule } from '../../../services/proposal';
import { useActiveEmail } from '../../../utils/utils';
import { SelectedOptionProps } from '../../../types/model';
import { useIsPreview } from '../../../utils/viewMode';
import { getEnabledScheduleMethods } from '../../../utils/payment';

interface Props {
  isAccepted: boolean;
  isDisabled: boolean;
  isSelected: boolean;
  paymentMethods: CheckoutPaymentMethod[];
  paymentSchedule: PaymentSchedule;
  proposal: Proposal;
  setSelectedOptions: (selectedOptions: SelectedOptionProps) => void;
}

const ScheduleOption: React.FC<Props> = ({
  paymentSchedule,
  proposal,
  paymentMethods,
  isSelected,
  isAccepted,
  setSelectedOptions,
  isDisabled,
}) => {
  const isPreview = useIsPreview();

  const qc = useQueryClient();

  const enabledBillingMethods = getEnabledScheduleMethods(
    paymentMethods,
    paymentSchedule
  );

  const handleClick = () => {
    if (isAccepted || isDisabled) return;

    setSelectedOptions({
      selectedMethodId: arrayTop(enabledBillingMethods)?.id,
      selectedSchedule: paymentSchedule,
    });

    if (!isPreview) {
      const checkoutReq: BuyerProposalRequest = {
        paymentScheduleId: paymentSchedule.id,
      };
      mutate(checkoutReq);
    }
  };

  const { mutate } = usePatchCheckoutSchedule(proposal.id, useActiveEmail())(
    (successData: Proposal) => {
      log.debug('schedule update success', successData);
    },
    (error: unknown) => {
      log.warn('error selecting schedule option', getErrorMessage(error));
    },
    qc
  );

  const title = getBillingPeriodLabelAdjective(paymentSchedule.payEvery);

  return (
    <RadioBlock
      description={enabledBillingMethods.map((pm) => pm.name).join(', ')}
      iconAfter={isSelected ? Icon.Checkmark : null}
      isBranded
      isSelected={isSelected}
      onClick={handleClick}
      title={title}
    />
  );
};

export default ScheduleOption;
