import React from 'react';
import clsx from 'clsx';
import styles from './HeaderDetail.module.scss';

interface Props {
  children?: React.ReactNode;
  info?: string;
  isLast?: boolean;
  item: any;
  title: string;
}

const HeaderDetail = ({ title, item, info, isLast, children }: Props) => {
  return (
    <div className={clsx(styles.headerDetail, isLast && styles.isLast)}>
      <div className={styles.title}>{title}</div>
      <div className={styles.item}>
        <div>{item}</div>
        {info && <div className={styles.info}>{info}</div>}
      </div>

      {children}
    </div>
  );
};

export default HeaderDetail;
