import {
  QueryClient,
  useMutation,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';

import {
  CheckoutPaymentMethod,
  CheckoutProposalRequest,
  CompanyInfo,
  Proposal,
} from 'common';
import { checkoutClient } from './checkoutClient';
import { nonAuthAxios } from './api';

const TEMPLATES_PATH = '/api/latest/checkout/template';
const BRANDING_PATH = '/api/latest/checkout/branding';
const CUSTOMER_PATH = '/api/latest/checkout/customer';

const getBrandingKey = () => [BRANDING_PATH];

const getPaymentMethodsKey = (id: string) => ['checkout-payment-methods', id];

export const usePaymentMethods = (
  id: string
): UseQueryResult<CheckoutPaymentMethod[]> =>
  useQuery({
    queryKey: getPaymentMethodsKey(id),
    queryFn: async () => {
      const { data } = await checkoutClient.getCheckoutPaymentMethods(id);
      return data;
    },
  });

export const useTemplate = (id: string): UseQueryResult<Proposal> =>
  useQuery({
    queryKey: [TEMPLATES_PATH],
    queryFn: async () => {
      const { data } = await nonAuthAxios.get(`${TEMPLATES_PATH}/${id}`);
      return data as Proposal;
    },
  });

export const useCheckoutBranding = (
  proposalId?: string
): UseQueryResult<CompanyInfo> =>
  useQuery({
    queryKey: getBrandingKey(),
    queryFn: async () => {
      const { data } = await nonAuthAxios.get<CompanyInfo>(
        proposalId ? `${BRANDING_PATH}/${proposalId}` : BRANDING_PATH
      );
      return data;
    },
  });

const postCustomerCheckout = async (body: CheckoutProposalRequest) => {
  const { data } = await nonAuthAxios.post(CUSTOMER_PATH, body);
  return data as Proposal;
};

export const useCheckoutCustomer = (
  onSuccess: (data: Proposal) => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [CUSTOMER_PATH],
    mutationFn: postCustomerCheckout,
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries();
    },
  });

export const setPaymentMethodsInCache = (
  qc: QueryClient,
  proposalId: string,
  paymentMethods: CheckoutPaymentMethod[]
) => {
  qc.setQueryData(getPaymentMethodsKey(proposalId), paymentMethods);
};

export const setBrandingInCache = (
  qc: QueryClient,
  companyInfo: CompanyInfo
) => {
  qc.setQueryData(getBrandingKey(), companyInfo);
};
