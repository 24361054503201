import DoneButton from './DoneButton';
import React from 'react';
import { MethodProps } from './methodUtils';
import { Button } from 'common';

const Custom = ({
  paymentMethod,
  proposal,
  isEditable,
  isCurrent,
  contract,
  showCancelButton,
  onClose,
}: MethodProps) => {
  if (!paymentMethod || !paymentMethod.id) {
    return null;
  }

  return (
    <div className="xs:mt-8 sm:mt-0">
      <p className="mb-4 font-bold xs:text-sm sm:text-lg">
        {paymentMethod.name}
      </p>
      <p className="mb-4 font-medium xs:text-xs sm:text-sm">
        {paymentMethod.customText}
      </p>

      <div className="pt-2 flex">
        <DoneButton
          isDisabled={!isEditable || isCurrent}
          proposal={proposal}
          contract={contract}
          paymentMethodId={paymentMethod.id}
        />
        {showCancelButton && (
          <Button
            onClick={onClose}
            className="ml-[10px]"
            label="Cancel"
            type="secondary"
          />
        )}
      </div>
    </div>
  );
};

export default Custom;
