import React from 'react';
import { ExternalLink, Type } from 'common';
import styles from './TryLink.module.scss';

const TryLink: React.FC = () => {
  const href =
    'https://www.getcacheflow.com/buyer-checkout?utm_medium=product&utm_source=client-checkout&utm_campaign=banner-1';

  return (
    <Type paragraph noMargin className="print:hidden">
      Like this experience?{' '}
      <ExternalLink className={styles.tryLink} href={href}>
        Try Cacheflow for yourself
      </ExternalLink>
    </Type>
  );
};

export default TryLink;
