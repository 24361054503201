import React from 'react';
import { AutoRenewalChange, ChangesPill, Proposal } from 'common';
import SubTitle from '../SubTitle';
import { getIsPdf } from '../../utils/pdf';
import styles from './AutoRenewalSection.module.scss';

interface Props {
  proposal: Proposal;
}

export const AutoRenewalSection = ({ proposal }: Props) => {
  const isPdf = getIsPdf();
  if (!proposal.renewalConfiguration?.renewalClauseDescription) {
    return null;
  }

  const renderChangedChicklet = () => {
    const autoRenewalChange = proposal.changes?.renewalConfigurationChange;

    if (!proposal.changes || !autoRenewalChange || isPdf) {
      return null;
    }

    return (
      <ChangesPill className={styles.pill}>
        <AutoRenewalChange proposal={proposal} changes={proposal.changes} />
      </ChangesPill>
    );
  };

  return (
    <div className={styles.autoRenewalSection}>
      <SubTitle
        className={styles.title}
        title="Renewal terms"
        actions={renderChangedChicklet()}
      />

      <p>{proposal.renewalConfiguration.renewalClauseDescription}</p>
    </div>
  );
};
