import clsx from 'clsx';
import {
  Button,
  ButtonBar,
  Contact,
  ContactInternal,
  DocuSignLogo,
  FormField,
  Input,
  InputError,
  Modal,
  Proposal,
} from 'common';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  useActiveContact,
  validateEmail,
  validateName,
} from '../../utils/utils';
import styles from './SigningContactModal.module.scss';

interface Props {
  handleClose: () => void;
  isOpen: boolean;
  proposal: Proposal;
  signWithContact: (
    contact: ContactInternal,
    setIsGenerating?: Dispatch<SetStateAction<boolean>>
  ) => void;
  signingDocError: string | null;
}

const SigningContactModal: React.FC<Props> = ({
  handleClose,
  isOpen,
  proposal,
  signingDocError,
  signWithContact,
}: Props): React.ReactElement => {
  const activeContact = useActiveContact(proposal);
  const [formErrors, setFormErrors] = useState<string[]>([]);

  const defaultContactState = {
    firstName: activeContact.firstName,
    lastName: activeContact.lastName,
    email: activeContact.email,
    role: activeContact.role,
    customer: { id: proposal.customer?.id },
  };

  const [contactState, setContactState] =
    useState<ContactInternal>(defaultContactState);
  const [isGenerating, setIsGenerating] = useState<boolean>(false);

  const onSignNow = () => {
    setIsGenerating(true);
    signWithContact(contactState, setIsGenerating);
  };

  useEffect(() => {
    validate();
  }, [contactState]);

  const validate = () => {
    const errors = [];
    if (!validateEmail(contactState.email)) {
      errors.push('Invalid email');
    }
    if (!validateName(contactState.firstName)) {
      errors.push('Invalid first name');
    }
    if (!validateName(contactState.lastName)) {
      errors.push('Invalid last name');
    }
    setFormErrors(errors);
  };

  const onChange = (field: keyof Contact, value: Contact[keyof Contact]) => {
    setContactState({
      ...contactState,
      [field]: value,
    });
  };

  const PoweredByDocuSign = () => (
    <div className={styles.poweredByDocusign}>
      <div className={styles.poweredBy}>Signing powered by</div>
      <DocuSignLogo />
    </div>
  );

  const footer = (
    <div>
      <div className={styles.footer}>
        <PoweredByDocuSign />

        <ButtonBar>
          <Button
            className="signing-button"
            isDisabled={isGenerating}
            label="Sign later"
            onClick={handleClose}
            type="secondary"
          />
          <Button
            className="signing-button"
            isDisabled={formErrors.length > 0}
            isLoading={isGenerating}
            label="Sign now via DocuSign"
            onClick={onSignNow}
          />
        </ButtonBar>
      </div>
      {isGenerating && (
        <div className={styles.bottomText}>
          Please wait. This may take a moment.
        </div>
      )}

      {signingDocError && (
        <div className={clsx(styles.bottomText, styles.signingErrors)}>
          {signingDocError}
        </div>
      )}
    </div>
  );

  return (
    <Modal
      className={styles.signingContactModal}
      footer={footer}
      header="Please make sure your information below is correct, so that we can prepare the contract for your signature."
      isOpen={isOpen}
      onClose={handleClose}
      width={550}
    >
      <FormField label="First name">
        <Input
          isDisabled={isGenerating}
          onChange={(value) => onChange('firstName', value)}
          value={contactState.firstName}
        />
      </FormField>

      <FormField label="Last name">
        <Input
          isDisabled={isGenerating}
          onChange={(value) => onChange('lastName', value)}
          value={contactState.lastName}
        />
      </FormField>

      <FormField label="Title">
        <Input
          isDisabled={isGenerating}
          onChange={(value) => onChange('role', value)}
          value={contactState.role}
        />
      </FormField>

      <FormField
        label="Email"
        infoText="You'll receive a copy of the signed contract at this email address."
      >
        <Input
          isDisabled={isGenerating}
          onChange={(value) => onChange('email', value)}
          value={contactState.email}
        />
      </FormField>

      {formErrors.map((error) => (
        <InputError key={error} errorToShow={error} />
      ))}
    </Modal>
  );
};

export default SigningContactModal;
