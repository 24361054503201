import React from 'react';

import { Item, PaymentSchedule } from 'common';

import TieredPriceTableItem from './TieredPriceTableItem';

type Props = {
  item: Item;
  schedulePeriod: PaymentSchedule['payEvery'];
};

export const TieredPriceTable = ({ item, schedulePeriod }: Props) => {
  const foundSchedules = item.schedules.filter(
    (schedule) => schedule.billingPeriod === schedulePeriod
  );

  if (foundSchedules.length === 0) return null;

  return (
    <div>
      {foundSchedules.map((schedule) => {
        return (
          <TieredPriceTableItem
            item={item}
            key={schedule.id}
            schedule={schedule}
          />
        );
      })}
    </div>
  );
};
