import { ENVIRONMENT_CONFIG } from '../config/hosts';
import { getSubDomain } from 'common';

const getUrl = (host: string, path?: string): string => {
  const protocol = ENVIRONMENT_CONFIG.shouldUseHttps ? 'https' : 'http';
  const endpoint = path || '';
  return `${protocol}://${host}${endpoint}`;
};

export const getSubdomainParam = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get('subdomain');
};

export const getApiUrl = (path?: string) => {
  let apiHost;
  if (getSubdomainParam()) {
    apiHost = ENVIRONMENT_CONFIG.apiHost;
  } else {
    const subdomain = getSubDomain();
    apiHost = subdomain + '.' + ENVIRONMENT_CONFIG.apiHost;
  }
  return getUrl(apiHost, path);
};
