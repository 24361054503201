import React from 'react';
import clsx from 'clsx';

interface Props {
  actions?: React.ReactNode;
  className?: string;
  title: React.ReactNode;
}

const SubTitle = ({ title, className, actions }: Props) => {
  return (
    <div className="flex items-center gap-1 break-after-avoid mb-4">
      <h2 className={clsx(className, 'text-xl font-extrabold')}>{title}</h2>
      {actions}
    </div>
  );
};

export default SubTitle;
