import React, { useState } from 'react';
import {
  AcceptRequest,
  alphaNumericValidator,
  BillingMethodRequest,
  Button,
  ButtonBar,
  CheckoutPaymentMethod,
  CompanyInfo,
  Contract,
  CreateAccountsWithManualRequest,
  CreateContractRequest,
  FormField,
  Input,
  Modal,
  PaymentSchedule,
  positiveIntValidator,
  Proposal,
  Type,
  useFormValidation,
  useTranslation,
} from 'common';
import DoneButton from './DoneButton';
import CacheflowLogo from '../../../assets/CfLogo';
import { getScheduleMessage } from '../../../utils/utils';
import styles from './DirectDebutConfirmModal.module.scss';

interface Props {
  companyInfo: CompanyInfo;
  contract?: Contract;
  handleClose: () => void;
  isOpen: boolean;
  paymentMethod?: CheckoutPaymentMethod;
  paymentSchedule: PaymentSchedule;
  proposal: Proposal;
}

interface CreateAccountsWithManualRequestConfirmable
  extends CreateAccountsWithManualRequest {
  confirmAccountNumber?: string;
}

const ManualBankAccountModal = ({
  companyInfo,
  paymentSchedule,
  contract,
  handleClose,
  isOpen,
  paymentMethod,
  proposal,
}: Props) => {
  const { tk } = useTranslation();
  const [error, setError] = useState('');

  const [bankAccountDraft, setBankAccountDraft] =
    useState<CreateAccountsWithManualRequestConfirmable>({
      type: 'depository',
      currency: proposal.currency,
    });

  const { getErrorToShow, setHasVisitedField, isFormValid } =
    useFormValidation<CreateAccountsWithManualRequestConfirmable>(
      [
        {
          fieldName: 'legalName',
          isRequired: true,
          validator: alphaNumericValidator,
          humanReadableName: 'Legal name',
        },
        {
          fieldName: 'institutionName',
          isRequired: true,
          validator: alphaNumericValidator,
          humanReadableName: 'Bank name',
        },
        {
          fieldName: 'routingNumber',
          isRequired: true,
          validator: positiveIntValidator,
          humanReadableName: 'Routing number',
        },
        {
          fieldName: 'accountNumber',
          isRequired: true,
          validator: (value, entity) =>
            value === entity.confirmAccountNumber
              ? null
              : 'Account numbers must match.',
          humanReadableName: 'Account number',
        },
        {
          fieldName: 'confirmAccountNumber',
          isRequired: true,
          validator: (value, entity) =>
            value === entity.accountNumber
              ? null
              : 'Account numbers must match.',
          humanReadableName: 'Confirm Account number',
        },
      ],
      bankAccountDraft
    );

  const handleFieldChange = (
    fieldName: keyof CreateAccountsWithManualRequestConfirmable,
    value: string
  ): void => {
    setBankAccountDraft((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const enrichRequest = (request: CreateContractRequest) => {
    return {
      ...request,
      paymentMethod: {
        ...request.paymentMethod,
        manualRequest: bankAccountDraft,
      },
    };
  };

  const enrichUpdateRequest = (request: BillingMethodRequest) => {
    return {
      ...request,
      manualRequest: bankAccountDraft,
    };
  };

  const enrichRenewalRequest = (request: AcceptRequest) => {
    return {
      billingMethodRequest: {
        paymentMethodId: request.billingMethodRequest?.paymentMethodId,
        manualRequest: bankAccountDraft,
      },
    };
  };

  const onError = (msg?: string) => {
    msg && setError(msg);
  };

  if (!paymentMethod?.id) {
    return null;
  }

  return (
    <Modal
      width={700}
      isOpen={isOpen}
      onClose={handleClose}
      className={styles.directDebitConfirmModal}
      header={
        <div className="text-center">
          <div className={styles.logo}>
            <CacheflowLogo />
          </div>
          <Type header={3}>
            Enter your bank account details to enable automatic debit
          </Type>
        </div>
      }
      footer={
        <ButtonBar>
          <Button label={tk('Cancel')} onClick={handleClose} type="secondary" />
          <DoneButton
            contract={contract}
            label={tk('Agree & proceed')}
            onError={onError}
            onSuccess={handleClose}
            paymentMethodId={paymentMethod.id}
            proposal={proposal}
            createContractRequestEnricher={enrichRequest}
            createChangeRenewalRequestEnricher={enrichRenewalRequest}
            updateContractRequestEnricher={enrichUpdateRequest}
            isDisabled={!isFormValid}
          />
        </ButtonBar>
      }
    >
      <Type className="m-4" header={4}>
        Manually entering account details requires verification via email.
        Stripe will email you the steps to verify your bank account separately.
      </Type>
      {error && <div className="text-sm error-message">{error}</div>}
      <div className="flex m-4">
        <div className="w-2/3 ">
          <FormField
            label={tk('Legal name')}
            errorToShow={getErrorToShow('legalName')}
          >
            <Input
              id="bank_account_legal_name"
              onChange={(rv) => handleFieldChange('legalName', rv)}
              value={bankAccountDraft.legalName || ''}
              onBlur={() => setHasVisitedField('legalName')}
            />
          </FormField>
          <FormField
            label={tk('Bank name')}
            errorToShow={getErrorToShow('institutionName')}
          >
            <Input
              id="bank_account_institution_name"
              onChange={(rv) => handleFieldChange('institutionName', rv)}
              value={bankAccountDraft.institutionName || ''}
              onBlur={() => setHasVisitedField('institutionName')}
            />
          </FormField>
          <FormField
            label={tk('Routing number')}
            errorToShow={getErrorToShow('routingNumber')}
          >
            <Input
              id="bank_account_routing_number"
              onChange={(rv) => handleFieldChange('routingNumber', rv)}
              value={bankAccountDraft.routingNumber || ''}
              onBlur={() => setHasVisitedField('routingNumber')}
            />
          </FormField>
          <FormField
            label={tk('Account number')}
            errorToShow={getErrorToShow('accountNumber')}
          >
            <Input
              id="bank_account_account_number"
              onChange={(rv) => handleFieldChange('accountNumber', rv)}
              value={bankAccountDraft.accountNumber || ''}
              onBlur={() => setHasVisitedField('accountNumber')}
            />
          </FormField>
          <FormField
            label={tk('Confirm Account number')}
            errorToShow={getErrorToShow('confirmAccountNumber')}
          >
            <Input
              id="bank_account_confirm_account_number"
              onChange={(rv) => handleFieldChange('confirmAccountNumber', rv)}
              value={bankAccountDraft.confirmAccountNumber || ''}
              onBlur={() => setHasVisitedField('confirmAccountNumber')}
            />
          </FormField>
        </div>
      </div>
      <Type className="m-4" header={4}>
        On behalf of {companyInfo.name},{' '}
        {getScheduleMessage(proposal.proposalItems, paymentSchedule)}
      </Type>
    </Modal>
  );
};

export default ManualBankAccountModal;
