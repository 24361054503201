import axios from 'axios';
import { ENVIRONMENT_CONFIG } from '../config/hosts';
import { getApiUrl, getSubdomainParam } from './helper';
import {
  log,
  logError,
  logRejected,
  logResponse,
  SHARED_AXIOS_CONFIG,
  SHARED_HEADERS,
} from 'common';

const axiosHeaders: Record<string, string> = { ...SHARED_HEADERS };

const hostParam = getSubdomainParam();
if (hostParam) {
  axiosHeaders.CF_ORG_SUBDOMAIN = hostParam;
}

const createAxios = (auth: boolean) => {
  // supports local api server via subdomain url param  ex: ?subdomain=xyz
  const axiosClient = axios.create({
    ...SHARED_AXIOS_CONFIG,
    baseURL: getApiUrl(),
    headers: axiosHeaders,
  });

  // some helpful debugging
  axiosClient.interceptors.request.use((config) => {
    log.trace(`${config.method} ${config.url}`);

    // eslint-disable-next-line camelcase
    config.headers.ui_request_path = window.location.pathname;
    if (auth && ENVIRONMENT_CONFIG.token) {
      config.headers.Authorization = `Bearer ${ENVIRONMENT_CONFIG.token}`;
    }

    log.debug(config.headers);
    return config;
  }, logRejected);
  axiosClient.interceptors.response.use(logResponse, logError);

  return axiosClient;
};

export const nonAuthAxios = createAxios(false);
export const buyerAxios = createAxios(true);
