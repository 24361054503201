import React from 'react';

import styles from './NoneSelected.module.scss';

const NoneSelected = () => {
  return (
    <div className={styles.noneSelected}>
      <h1 className={styles.primary}>Please select a new payment method.</h1>
      <p className={styles.secondary}>
        The previous payment method used is not available for this renewal.
      </p>
    </div>
  );
};

export default NoneSelected;
