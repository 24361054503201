import React, { useState } from 'react';
import { log } from 'common';
import {
  PlaidLinkError,
  PlaidLinkOnExitMetadata,
  PlaidLinkOnSuccessMetadata,
} from 'react-plaid-link';
import DirectDebitConfirmModal from './DirectDebitConfirmModal';
import PlaidButton from './Plaid/PlaidButton';
import { MethodProps } from './methodUtils';
import ManualBankAccountModal from './ManualBankAccountModal';

const DirectDebit = ({
  proposal,
  companyInfo,
  paymentSchedule,
  paymentMethod,
  isEditable,
  contract,
  showCancelButton,
  onClose,
}: MethodProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [plaidToken, setPlaidToken] = useState('');
  const [isFallback, setIsFallback] = useState(false);
  const [plaidData, setPlaidData] = useState<
    PlaidLinkOnSuccessMetadata | undefined
  >(undefined);
  const onSuccess = (token: string, data: PlaidLinkOnSuccessMetadata) => {
    setIsOpen(true);
    setPlaidToken(token);
    setPlaidData(data);
  };

  const onFailure = (
    plaidLinkError: PlaidLinkError,
    data: PlaidLinkOnExitMetadata
  ) => {
    log.warn('plaid error link: ', plaidLinkError);
    log.debug('plaid error data: ', data);
    setIsOpen(false);
    setIsFallback(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    setIsFallback(false);
  };

  const paymentInfo = () => {
    if (!contract) {
      return null;
    }

    if (contract.billingMethod?.paymentMethod.name === paymentMethod?.name) {
      return (
        <div className="pb-2 text-md">
          Cacheflow will use the account ending in{' '}
          <span className="font-semibold">
            {contract.billingMethod?.account?.mask}
          </span>{' '}
          to charge you based on the payment plan selected.
        </div>
      );
    }

    return null;
  };

  if (companyInfo === undefined || paymentSchedule === undefined) {
    return null;
  }

  return (
    <div>
      {isEditable && (
        <DirectDebitConfirmModal
          companyInfo={companyInfo}
          paymentSchedule={paymentSchedule}
          proposal={proposal}
          paymentMethod={paymentMethod}
          plaidToken={plaidToken}
          plaidData={plaidData}
          isOpen={isOpen}
          handleClose={handleClose}
          contract={contract}
        />
      )}

      {isFallback && (
        <ManualBankAccountModal
          proposal={proposal}
          paymentMethod={paymentMethod}
          companyInfo={companyInfo}
          paymentSchedule={paymentSchedule}
          isOpen={isFallback}
          handleClose={handleClose}
          contract={contract}
        />
      )}
      <div className="xs:mt-8 sm:mt-0">
        <p className="text-sm mb-4 font-bold xs:text-sm sm:text-lg xs:mt-8 sm:mt-0">
          Pay with {paymentMethod?.name?.toLowerCase()}
        </p>
        <p className="text-sm mb-4 text-semidbold">
          Cacheflow uses <span className="font-bold">Plaid</span> to securely
          authenticate and connect your bank accounts for payments.
        </p>

        {isEditable && (
          <>
            {paymentInfo()}
            <PlaidButton
              proposal={proposal}
              onSuccessAction={onSuccess}
              onFailureAction={onFailure}
              showCancelButton={showCancelButton}
              onSetIsFallback={setIsFallback}
              onClose={onClose}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default DirectDebit;
