import React, { useState } from 'react';
import {
  Button,
  CompanyInfo,
  CreateContactRequest,
  emailValidator,
  FormField,
  getErrorMessage,
  Input,
  Loading,
  log,
  Proposal,
  useFormValidation,
  useToast,
} from 'common';
import SellerDetails from './SellerDetails';
import { useNavigate, useParams } from 'react-router-dom';
import { useCheckoutCustomer, useTemplate } from '../services/checkoutApi';
import { useQueryClient } from '@tanstack/react-query';
import './CompanyForm.css';
import styles from '../App.module.scss';
import clsx from 'clsx';
import ErrorPage from '../components/ErrorPage';

interface Props {
  companyInfo?: CompanyInfo;
}

const CompanyForm: React.FC<Props> = ({ companyInfo }) => {
  const { templateId } = useParams<{ templateId: string }>();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const showToast = useToast();

  if (templateId === undefined) {
    return <ErrorPage />;
  }

  const { data: template, isLoading: isTemplateLoading } =
    useTemplate(templateId);

  const [company, setCompany] = useState<string | undefined>(undefined);
  const [contact, setContact] = useState<CreateContactRequest>({
    firstName: '',
    lastName: '',
    email: '',
  });

  const [isProcessing, setIsProcessing] = useState(false);

  const { isFormValid, getErrorToShow } = useFormValidation(
    [
      { fieldName: 'email', validator: emailValidator },
      {
        fieldName: 'firstName',
        isRequired: true,
        humanReadableName: 'First name',
      },
      {
        fieldName: 'lastName',
        isRequired: true,
        humanReadableName: 'Last name',
      },
      {
        fieldName: 'company',
        isRequired: true,
        humanReadableName: 'Company',
      },
    ],
    {
      email: contact.email,
      firstName: contact.firstName,
      lastName: contact.lastName,
      company,
    }
  );

  const handleSuccess = (successData: Proposal) => {
    log.debug('MUTATION SUCCESS', successData);
    navigate(`/p/${successData.id}?email=${contact.email.toLowerCase()}`);
    setIsProcessing(false);
  };

  const handleError = (error: unknown) => {
    log.warn('Error creating proposal', error);
    setIsProcessing(false);
    const errorMessage = getErrorMessage(error);
    if (errorMessage) {
      showToast.error('Trigger due failed: ' + errorMessage);
    }
  };

  const { mutate } = useCheckoutCustomer(
    handleSuccess,
    handleError,
    queryClient
  );

  const proceed = () => {
    setIsProcessing(true);

    mutate({
      referenceId: templateId,
      contact,
      customer: {
        name: company,
      },
    });
  };

  if (isTemplateLoading) {
    return <Loading />;
  }

  if (!template) {
    return (
      <ErrorPage
        info
        title="Checkout Error"
        message={`Please contact the ${
          companyInfo?.name || 'sender'
        } for a valid link.`}
      />
    );
  }

  return (
    <div>
      <div className="company-form-wrapper">
        <SellerDetails companyInfo={companyInfo} />

        <div className={clsx(styles.paper, 'company-form')}>
          <div className="company-form-buyerdetails">
            <div className="company-form-infobox">
              <h1>Please fill out the form below</h1>
              The information will be used to complete your checkout.
            </div>
            <FormField
              label="Company name"
              note="All fields required"
              errorToShow={getErrorToShow('company')}
            >
              <Input
                value={company}
                isDisabled={isProcessing}
                dataTestId="company-name"
                onChange={(value) => {
                  setCompany(value);
                }}
              />
            </FormField>

            <FormField
              label="Contact email"
              errorToShow={getErrorToShow('email')}
            >
              <Input
                value={contact.email}
                isDisabled={isProcessing}
                dataTestId="contact-email"
                onChange={(email) => {
                  setContact({ ...contact, email });
                }}
              />
            </FormField>
            <div className="flex gap-6 w-full">
              <FormField
                label="First name"
                className="flex-1"
                errorToShow={getErrorToShow('firstName')}
              >
                <Input
                  value={contact.firstName}
                  dataTestId="contact-firstName"
                  isDisabled={isProcessing}
                  onChange={(firstName) => {
                    setContact({ ...contact, firstName });
                  }}
                />
              </FormField>
              <FormField
                label="Last name"
                className="flex-1"
                errorToShow={getErrorToShow('lastName')}
              >
                <Input
                  value={contact.lastName}
                  dataTestId="contact-lastName"
                  isDisabled={isProcessing}
                  onChange={(lastName) => setContact({ ...contact, lastName })}
                />
              </FormField>
            </div>
          </div>
          <div className="company-form-actions">
            <Button
              label="Continue to checkout"
              block
              isDisabled={isProcessing || !isFormValid}
              onClick={proceed}
              isLoading={isProcessing}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyForm;
