import React, { useEffect, useState } from 'react';
import { LDClient, LDContext } from 'launchdarkly-js-client-sdk';
import { asyncWithLDProvider, useFlags } from 'launchdarkly-react-client-sdk';
import {
  FlagsProvider,
  getLdOptions,
  getSubDomain,
  Loading,
  log,
  Proposal,
} from 'common';
import { ENVIRONMENT_CONFIG } from '../config/hosts';

const env = ENVIRONMENT_CONFIG.env.toLowerCase();

export const launchDarklyIdentify = async (
  proposal: Proposal,
  ldClient: LDClient
): Promise<void> => {
  if (!ENVIRONMENT_CONFIG.ldClientSecret || !proposal.customer) {
    return;
  }

  await ldClient.identify({
    name: proposal.customer.name,
    key: proposal.customer.id,
    custom: {
      env,
      org: getSubDomain(),
      products: [],
    },
  });
};

const getLaunchDarklyWrapper = (): Promise<
  React.ComponentType<{ children: React.ReactNode }>
> => {
  if (!ENVIRONMENT_CONFIG.ldClientSecret) {
    log.warn('launch darkly client not set');
    return new Promise((resolve) => {
      resolve(React.Fragment);
    });
  }

  const context: LDContext = {
    name: getSubDomain(),
    key: getSubDomain(),
    custom: {
      env,
      org: getSubDomain(),
      products: [],
    },
  };

  return asyncWithLDProvider({
    clientSideID: ENVIRONMENT_CONFIG.ldClientSecret,
    context,
    options: getLdOptions(ENVIRONMENT_CONFIG.isProduction),
  });
};

export const LaunchDarklyWrapper: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [launchDarklyProviderWrapper, setLaunchDarklyProviderWrapper] =
    useState<
      | {
          LaunchDarklyProvider: React.ComponentType<{
            children: React.ReactNode;
          }>;
        }
      | undefined
    >(undefined);

  useEffect(() => {
    if (launchDarklyProviderWrapper === undefined) {
      getLaunchDarklyWrapper().then(
        (LaunchDarklyProvider) =>
          setLaunchDarklyProviderWrapper({ LaunchDarklyProvider }),
        log.error
      );
    }
  }, []);

  if (!launchDarklyProviderWrapper) {
    return <Loading />;
  }

  const { LaunchDarklyProvider } = launchDarklyProviderWrapper;

  return (
    <LaunchDarklyProvider>
      <FlagsProvider
        useFlags={useFlags}
        ldClientSecret={ENVIRONMENT_CONFIG.ldClientSecret}
        defaultFlags={ENVIRONMENT_CONFIG.defaultFlags}
      >
        {children}
      </FlagsProvider>
    </LaunchDarklyProvider>
  );
};
