import React, { useEffect, useState } from 'react';
import {
  basisPointsToPercentage,
  Bordered,
  CompanyInfo,
  formatCurrencyOrDefault,
  formatDate,
  formatDateOrDefault,
  formatNullableFormattedAmount,
  getToday,
  hasUsage,
  isChangeProposalType,
  isEvergreenProposal,
  pluralize,
  Proposal,
} from 'common';
import { SelectedOptionProps } from '../../types/model';
import { getAmountDesc } from '../../utils/utils';
import PageActions from '../PageActions';
import HeaderDetail from './HeaderDetail';
import Logo from './Logo';
import OrderInfo from './OrderInfo';
import ReadyIndicatorPixels from './ReadyIndicatorPixels';
import { useIsPreview } from '../../utils/viewMode';
import ProposalLayout from '../ProposalLayout';
import HeaderAddresses from './HeaderAddresses';

import styles from './Header.module.scss';

interface Props {
  companyInfo: CompanyInfo;
  proposal: Proposal;
  selectedOptions: SelectedOptionProps;
}

const Header = ({ proposal, companyInfo, selectedOptions }: Props) => {
  const isPreview = useIsPreview();
  const [titleSet, setTitleSet] = useState(false);

  useEffect(() => {
    if (titleSet) return;
    document.title = `${proposal.customer?.name} Proposal`;
    setTitleSet(true);
  }, [proposal.customer?.name, titleSet]);

  const getProposalEffectiveStartDate = () => {
    return proposal.effectiveStartDate || getToday();
  };

  const showDiscounts = (): boolean => {
    const discountAmount = selectedOptions.selectedSchedule?.totalDiscount ?? 0;

    return !proposal.options?.hideDiscounts && discountAmount > 0;
  };

  const isChangeProposal = isChangeProposalType(proposal);

  const isMonthlyEvergreen: boolean =
    proposal.termQty === 1 &&
    proposal.termType === 'month' &&
    proposal.renewalConfiguration?.termLengthMonths === 1 &&
    isEvergreenProposal(proposal);

  const serviceTerm = isMonthlyEvergreen
    ? 'Monthly'
    : pluralize(proposal.termQty, proposal.termType);

  const showEndDate = !isMonthlyEvergreen;
  const showDiscount =
    !!proposal.options?.showTotalContractValue && showDiscounts();

  return (
    <div className={styles.header}>
      <PageActions proposal={proposal} companyInfo={companyInfo} />
      <ProposalLayout.TwoColumn borderBottom>
        <Logo companyInfo={companyInfo} />
        <OrderInfo proposal={proposal} />
      </ProposalLayout.TwoColumn>

      <HeaderAddresses companyInfo={companyInfo} proposal={proposal} />

      <ReadyIndicatorPixels companyInfo={companyInfo} />

      <Bordered className={styles.headerDetails} borderBottom>
        {proposal.purchaseOrderNumber && (
          <HeaderDetail title="PO number" item={proposal.purchaseOrderNumber} />
        )}
        <HeaderDetail title="Service term" item={serviceTerm} />
        <HeaderDetail
          title="Effective date"
          item={formatDate(getProposalEffectiveStartDate())}
        />

        {showEndDate && (
          <HeaderDetail
            title="End date"
            item={formatDateOrDefault(proposal.endDate)}
          />
        )}

        <HeaderDetail title="Currency" item={proposal.currency} />

        {showDiscount && !isChangeProposal && (
          <HeaderDetail
            title="Discount amount"
            item={selectedOptions.selectedSchedule?.totalDiscountFormatted}
            info={basisPointsToPercentage(
              selectedOptions.selectedSchedule?.totalDiscountPercent
            )}
          />
        )}

        {proposal.options?.showTotalContractValue && !isMonthlyEvergreen && (
          <HeaderDetail
            title={isChangeProposal ? 'Total change' : 'Total amount'}
            item={
              isChangeProposal
                ? formatCurrencyOrDefault(
                    proposal.currency,
                    proposal.changes?.amountChange
                  )
                : isPreview
                  ? formatNullableFormattedAmount(
                      selectedOptions.selectedSchedule?.totalFormatted
                    ) || getAmountDesc(proposal)
                  : getAmountDesc(proposal)
            }
            info={hasUsage(proposal) ? 'will vary based on usage' : undefined}
            isLast
          >
            {!!proposal.freeMonths && proposal.freeMonths > 0 && (
              <p className={styles.freeMonths}>
                {`+ ${proposal.freeMonths} ${
                  proposal.freeMonths > 1 ? 'months free' : 'month free'
                }`}
              </p>
            )}
          </HeaderDetail>
        )}
      </Bordered>
    </div>
  );
};

export default Header;
