import React from 'react';
import { ThemeConfig } from 'common';
import { useCheckoutBranding } from './services/checkoutApi';
import { ENVIRONMENT_CONFIG } from 'app/config/hosts';
import Beacon from './components/Beacon';

interface Props {
  children: React.ReactNode;
}

const BrandingConfig: React.FC<Props> = ({ children }) => {
  const proposalId = /\/p\/(.+)/.exec(window.location.pathname)
    ? /\/p\/(.+)/.exec(window.location.pathname)![1]
    : undefined;
  const { data: companyInfo } = useCheckoutBranding(proposalId);

  return (
    <ThemeConfig
      brandingEnabled={companyInfo?.brandingEnabled}
      companyInfo={companyInfo}
      isProduction={ENVIRONMENT_CONFIG.isProduction}
    >
      <>
        {children}
        {companyInfo && <Beacon />}
      </>
    </ThemeConfig>
  );
};

export default BrandingConfig;
