import React from 'react';
import { Icon } from 'common';
import styles from '../App.module.scss';
import clsx from 'clsx';
import './ErrorPage.css';

interface Props {
  info?: boolean;
  message?: React.ReactNode;
  title?: React.ReactNode;
}

const ErrorPage = ({ title, message, info }: Props) => {
  const PageIcon = info ? Icon.Information : Icon.AlertVariant;

  return (
    <div
      className={clsx(
        styles.app,
        'min-h-screen m-auto text-center sm:px-6 py-8 print:p-0'
      )}
    >
      <div className={clsx(styles.error, styles.paper, 'error-wrapper')}>
        <div>
          <PageIcon
            className={clsx('m-auto', 'icon')}
            width="6rem"
            height="6rem"
          />
          {title && (
            <p className="mb-6 font-semibold xs:text-xl sm:text-3xl">{title}</p>
          )}
        </div>

        {message && (
          <p className="font-medium xs:text-sm sm:text-lg">{message}</p>
        )}
      </div>
    </div>
  );
};

export default ErrorPage;
