import React from 'react';
import { Button, ButtonBar, ExternalLink, Modal, Type } from 'common';
import CfLogo from '../assets/CfLogo';
import styles from './AboutCacheflowModal.module.scss';

interface Props {
  handleClose: () => void;
  isOpen: boolean;
}

const AboutCacheflowModal = ({ isOpen, handleClose }: Props) => {
  return (
    <Modal
      width={700}
      isOpen={isOpen}
      onClose={handleClose}
      className={styles.aboutCacheflowModal}
      header={
        <div className={styles.logo}>
          <CfLogo />
        </div>
      }
      footer={
        <ButtonBar>
          <Button label="Ok" onClick={handleClose} />
        </ButtonBar>
      }
    >
      <Type header={2}>About Cacheflow</Type>
      <Type paragraph>
        We built Cacheflow to modernize the way businesses buy and sell SaaS. A
        simple and delightful buying experience that leads to a better start to
        the relationship.
      </Type>
      <Type paragraph semiBold>
        Website:{' '}
        <ExternalLink href="https://getcacheflow.com/">
          getcacheflow.com
        </ExternalLink>
      </Type>
    </Modal>
  );
};

export default AboutCacheflowModal;
