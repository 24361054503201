import React, { useEffect, useState } from 'react';
import {
  Button,
  ButtonBar,
  Contract,
  hasAnAcceptedProposalStatus,
  Modal,
  Proposal,
  removeParam,
  Type,
} from 'common';
import { useActiveEmail } from '../utils/utils';
import TryLink from './TryLink';
import { useOriginalStatus } from '../utils/viewMode';
import { scrollToTop } from '../utils/scrolling';

interface Props {
  contract: Contract | undefined;
  proposal: Proposal;
}

const CongratulationsPrompt: React.FC<Props> = ({ contract, proposal }) => {
  const [readyToShow, setReadyToShow] = useState<boolean>(false);
  const hasAnAcceptedStatus = hasAnAcceptedProposalStatus(proposal);
  const originalStatus = useOriginalStatus();
  const isProposalComplete: boolean =
    hasAnAcceptedStatus && !!contract?.billingMethod;
  const [isUserClosed, setIsUserClosed] = useState(
    isProposalComplete && !originalStatus
  );

  const checkoutUserEmail = useActiveEmail();

  // The modal will initially open when state is changed to accepted, and then stay open until closed by user
  const isOpen: boolean = isProposalComplete && !isUserClosed;

  useEffect(() => {
    scrollToTop();

    setTimeout(() => {
      setReadyToShow(true);
    }, 500);
  }, [isOpen]);

  const onClose = () => {
    setIsUserClosed(true);
    if (originalStatus) {
      removeParam('original_status');
    }
  };

  const footer = (
    <ButtonBar>
      <Button label="Close" onClick={onClose} />
    </ButtonBar>
  );

  return (
    <Modal
      footer={footer}
      header="Congratulations! You’re all set."
      isOpen={isOpen && readyToShow}
      onClose={onClose}
      width={600}
    >
      <div>
        <Type paragraph>
          We’ve sent an email confirmation to {checkoutUserEmail}.
        </Type>
        <TryLink />
      </div>
    </Modal>
  );
};

export default CongratulationsPrompt;
