import ACHDetail from './ACHDetail';
import DoneButton from './DoneButton';
import React from 'react';
import { MethodProps } from './methodUtils';
import { Button } from 'common';

const ACH = ({
  proposal,
  paymentMethod,
  isEditable,
  isCurrent,
  contract,
  companyInfo,
  showCancelButton,
  onClose,
}: MethodProps) => {
  const bankConfig: any = paymentMethod?.configuration;
  const paymentMethodId = paymentMethod?.id;

  if (paymentMethodId === undefined) {
    return null;
  }

  return (
    <div className="xs:mt-8 sm:mt-0">
      <p className="mb-4 font-bold xs:text-sm sm:text-lg">
        Set up ACH payments in your bank
      </p>
      {bankConfig && (
        <p className="mb-4 font-medium xs:text-xs sm:text-sm">
          Use the following details to set up ACH payments in your bank.
        </p>
      )}

      {bankConfig ? (
        <div className="flex flex-wrap">
          <div className="w-full border-b" />
          <ACHDetail title="Account name" detail={bankConfig.accountName} />
          <ACHDetail title="Bank name" detail={bankConfig.bankName} />
          <ACHDetail
            title="ABA/Routing number"
            detail={bankConfig.routingNumber}
          />
          <ACHDetail title="Account number" detail={bankConfig.accountNumber} />
        </div>
      ) : companyInfo?.name ? (
        `Please contact your representative at ${companyInfo.name} for ACH routing information.`
      ) : (
        'Please contact your representative for ACH routing information.'
      )}

      <div className="pt-2 flex">
        <DoneButton
          proposal={proposal}
          paymentMethodId={paymentMethodId}
          isDisabled={!isEditable || isCurrent}
          contract={contract}
        />
        {showCancelButton && (
          <Button
            onClick={onClose}
            className="ml-[10px]"
            label="Cancel"
            type="secondary"
          />
        )}
      </div>
    </div>
  );
};

export default ACH;
