import React, { useState } from 'react';
import SubTitle from '../SubTitle';
import PaymentSchedule from './Schedule/PaymentSchedule';
import { SelectedOptionProps } from '../../types/model';
import ScheduleOptions from './ScheduleOption/ScheduleOptions';
import './ScheduleOption/scheduleOptions.scss';
import { getIsPdf } from '../../utils/pdf';
import {
  Button,
  BuyerProposalRequest,
  CheckoutPaymentMethod,
  Icon,
  Input,
  isChangeProposalType,
  Proposal,
  PURCHASE_ORDER_UPDATE_ERROR_MESSEGE,
  PURCHASE_ORDER_UPDATE_SUCCESS_MESSEGE,
  useToast,
} from 'common';
import { usePatchCheckoutSchedule } from 'checkout/src/services/proposal';
import { useActiveEmail } from 'checkout/src/utils/utils';
import { useQueryClient } from '@tanstack/react-query';
import { useIsPreview } from 'checkout/src/utils/viewMode';

interface Props {
  paymentMethods: CheckoutPaymentMethod[];
  proposal: Proposal;
  selectedOptions: SelectedOptionProps;
  setSelectedOptions: (selectedOptions: SelectedOptionProps) => void;
}

const PaymentScheduleSection = ({
  proposal,
  paymentMethods,
  selectedOptions,
  setSelectedOptions,
}: Props) => {
  const isPreview = useIsPreview();
  const isChangeProposal = isChangeProposalType(proposal);
  const isScheduleDisabled = !!proposal.signingDocument || isChangeProposal;
  const isPdf = getIsPdf();
  const [isEditingPO, setIsEditingPO] = useState<boolean>(true);
  const [poDisplayValue, setPoDisplayValue] = useState<string>(
    proposal.purchaseOrderNumber || ''
  );
  const qc = useQueryClient();
  const showToast = useToast();
  const activeEmail = useActiveEmail();

  const { mutate } = usePatchCheckoutSchedule(proposal.id, activeEmail)(
    () => {
      showToast.success(PURCHASE_ORDER_UPDATE_SUCCESS_MESSEGE);
    },
    () => {
      showToast.error(PURCHASE_ORDER_UPDATE_ERROR_MESSEGE);
    },
    qc
  );

  if (isPdf) {
    return (
      <div className="mt-8">
        <SubTitle title="Payment schedule" />
        <div className="w-1/2 break-inside-avoid">
          <PaymentSchedule
            isDisabled={isScheduleDisabled}
            proposal={proposal}
            selectedOptions={selectedOptions}
          />
        </div>
      </div>
    );
  }

  const handlePoChange = (data: BuyerProposalRequest) => {
    mutate(data);
  };

  const collectPurchaseOrderNumber: boolean =
    !!proposal.options?.collectPurchaseOrderNumber;

  return (
    <div className="mt-8 break-inside-avoid print:hidden">
      <div>
        <SubTitle
          title={isScheduleDisabled ? 'Payment plan' : 'Choose a payment plan'}
        />
      </div>

      {collectPurchaseOrderNumber && (
        <div className="payment-terms-wrapper pb-4 flex-col">
          <div className="w-[400px]">
            {isEditingPO ? (
              <b className="PONumberHeading">
                <p>PO number : &nbsp;&nbsp;</p>
                <p>{proposal.purchaseOrderNumber}</p>
                &nbsp;&nbsp;&nbsp;&nbsp;
                {proposal.status !== 'accepted' && (
                  <Button
                    label="Edit"
                    iconBefore={Icon.Edit}
                    onClick={() => setIsEditingPO(false)}
                    type="secondary"
                    isDisabled={isPreview}
                  />
                )}
              </b>
            ) : (
              <div className="POheadingMainWrapper">
                <div className="w-[20%]">
                  <b className="PONumberInnerHeading">PO number &nbsp;:</b>
                </div>
                <Input
                  className="mt-2 w-[180px]"
                  value={poDisplayValue}
                  maxLength={20}
                  onChange={(newValue) => {
                    setPoDisplayValue(newValue);
                  }}
                />
                <div className="button-wrapper">
                  <Button
                    type="secondary"
                    label="Cancel"
                    onClick={() => setIsEditingPO(true)}
                    className="px-2"
                  />
                  <Button
                    className="px-2"
                    onClick={() => {
                      handlePoChange({
                        purchaseOrderNumber: poDisplayValue,
                      });

                      setIsEditingPO(true);
                    }}
                    label="Save"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      <div className="grid gap-0 grid-cols-1 sm:grid-cols-2 sm:gap-6">
        <ScheduleOptions
          proposal={proposal}
          selectedOptions={selectedOptions}
          paymentMethods={paymentMethods}
          setSelectedOptions={setSelectedOptions}
          isDisabled={isScheduleDisabled}
        />
        <PaymentSchedule
          isDisabled={isScheduleDisabled}
          proposal={proposal}
          selectedOptions={selectedOptions}
        />
      </div>
    </div>
  );
};

export default PaymentScheduleSection;
