import React, { useState } from 'react';
import PlaidModal from './PlaidModal';
import ShieldIcon from '../../../../assets/ShieldIcon';
import styles from './PoweredByPlaid.module.scss';

const PoweredByPlaid = () => {
  const [isOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }
  function handleClose() {
    setIsOpen(false);
  }

  return (
    <div className="xs:flex sm:block">
      <div className="flex items-center font-normal xs:text-center sm:text-left xs:text-xs sm:text-sm ">
        <div onClick={openModal} className="flex items-center">
          <ShieldIcon className={styles.heightLogo} />
          <span className="font-medium ml-1.5 underline cursor-pointer">
            {' '}
            Learn more about Plaid
          </span>
        </div>
      </div>

      <PlaidModal isOpen={isOpen} handleClose={handleClose} />
    </div>
  );
};

export default PoweredByPlaid;
