import React from 'react';
import './SellerDetails.css';
import { CompanyInfo } from 'common';
import Logo from '../components/Header/Logo';

const SellerDetails = ({ companyInfo }: { companyInfo?: CompanyInfo }) => {
  return (
    <div className="pricing-seller-details">
      <Logo companyInfo={companyInfo} />
      {!companyInfo?.logo && <div>{companyInfo?.name}</div>}
    </div>
  );
};

export default SellerDetails;
