import React from 'react';
import clsx from 'clsx';
import PoweredBy from '../components/PoweredBy';
import { useCheckoutBranding } from '../services/checkoutApi';
import styles from '../App.module.scss';

const CheckoutFormWrapper: React.FC = () => {
  const { data } = useCheckoutBranding();

  return (
    <div className={clsx(styles.app, 'min-h-screen sm:px-6 py-8 print:p-0')}>
      <div className="m-auto mw">
        <div className="sm:pb-0 print:hidden">
          <PoweredBy companyInfo={data} />
        </div>
      </div>
    </div>
  );
};

export default CheckoutFormWrapper;
