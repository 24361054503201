import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  CheckoutPaymentMethod,
  isAcceptedProposalStatus,
  isRenewalProposalType,
  Loading,
  Proposal,
} from 'common';
import { getError, getRecordInaccessibleError } from './utils/utils';
import {
  findPaymentScheduleById,
  isProposalNeedingBillingAfterApproval,
} from './utils/proposalUtils';
import { SelectedOptionProps } from './types/model';
import { useContract } from './services/contract';
import { getEnabledMethodsForProposal } from './utils/payment';
import { useCheckoutBranding, usePaymentMethods } from './services/checkoutApi';
import Checkout from './Checkout';
import styles from './App.module.scss';

interface Props {
  proposal: Proposal;
}

const App: React.FC<Props> = ({ proposal }) => {
  const { data: contract, isLoading: contractIsLoading } = useContract(
    proposal.contract?.id,
    isRenewalProposalType(proposal) || !!proposal.acceptedScheduleId
  );

  const waitingForContract = !!proposal.acceptedScheduleId && contractIsLoading;

  const { data: paymentMethods, isLoading: paymentMethodsIsLoading } =
    usePaymentMethods(proposal.id);

  const { data: companyInfo, isLoading: companyInfoIsLoading } =
    useCheckoutBranding(proposal.id);

  const [selectedOptions, setSelectedOptions] = useState<SelectedOptionProps>();

  // use flag until proposal has option
  const proposalNeedsBillingAfterApproval =
    isProposalNeedingBillingAfterApproval(contract, proposal);

  const defaultPaymentMethodId = (selectedProps: SelectedOptionProps) => {
    const enabledMethodsTemp: CheckoutPaymentMethod[] | undefined =
      getEnabledMethodsForProposal(paymentMethods!, selectedProps);
    if (enabledMethodsTemp && enabledMethodsTemp.length > 0) {
      return enabledMethodsTemp[0]?.id;
    }
    return undefined;
  };

  useEffect(() => {
    // renewals have a state called pending_renewal which means
    // the proposal has been basically accepted but has not been applied to the contract yet.
    // It requires a bit more nuanced front end work to deal with it.
    // So while it's in the pending_renewal state, we don't want this to run since it's deriving
    // values from the contract. Instead it needs to get it from the proposal
    // (since that has the most up to date information to display).

    if (!paymentMethods) {
      return;
    }

    const useProposal =
      !contract ||
      (isRenewalProposalType(proposal) && !isAcceptedProposalStatus(proposal));

    if (useProposal) {
      let scheduleId;
      // change and renewal proposals have an accepted schedule
      // we need to use the proposal.selectedScheduleId for the current selected schedule
      // otherwise it will continue to select from the previous accepted schedule
      if (isRenewalProposalType(proposal) || proposal.selectedScheduleId) {
        scheduleId = proposal.selectedScheduleId;
      } else {
        scheduleId = proposal.acceptedScheduleId;
      }

      const { foundSchedule } = findPaymentScheduleById(proposal, scheduleId);

      const selectedProps = {
        selectedSchedule: foundSchedule,
      } as SelectedOptionProps;

      const paymentMethodId: string | undefined =
        defaultPaymentMethodId(selectedProps);

      setSelectedOptions({
        ...selectedProps,
        selectedMethodId: paymentMethodId,
      });
    } else {
      const { foundSchedule } = findPaymentScheduleById(
        proposal,
        proposal.acceptedScheduleId
      );

      let paymentMethodId: string | undefined =
        foundSchedule?.paymentMethodIds?.find(
          (methodId) => methodId === contract.billingMethod?.paymentMethod.id
        );

      if (!paymentMethodId && proposalNeedsBillingAfterApproval) {
        const selectedProps = {
          selectedSchedule: foundSchedule,
        } as SelectedOptionProps;

        paymentMethodId = defaultPaymentMethodId(selectedProps);
      }

      setSelectedOptions({
        selectedSchedule: foundSchedule,
        selectedMethodId: paymentMethodId,
      });
    }
  }, [contract, paymentMethods, proposal]);

  if (companyInfoIsLoading || paymentMethodsIsLoading || waitingForContract) {
    return (
      <div
        className={clsx(
          styles.app,
          'min-h-screen m-auto text-center sm:px-6 py-8 print:p-0'
        )}
      >
        <Loading />
      </div>
    );
  }

  if (contract?.status === 'cancelled') {
    return getRecordInaccessibleError(
      `${proposal.owner?.firstName} ${proposal.owner?.lastName}`,
      proposal.owner?.username
    );
  }

  if (
    selectedOptions?.selectedSchedule &&
    companyInfo &&
    paymentMethods !== undefined
  ) {
    return (
      <Checkout
        companyInfo={companyInfo}
        contract={contract}
        paymentMethods={paymentMethods}
        proposal={proposal}
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
      />
    );
  }

  return getError(null);
};

export default App;
