import React, { SVGProps } from 'react';
import { CheckoutPaymentMethod, Icon, PaymentIcon, RadioBlock } from 'common';

interface Props {
  dataTestId: string;
  isDisabled: boolean;
  isSelected: boolean;
  onClick?: () => void;
  paymentMethod: CheckoutPaymentMethod;
  setSelectedMethodId: (methodId: string) => void;
}

const methodIconMap = new Map<
  CheckoutPaymentMethod['paymentType'],
  React.ComponentType<SVGProps<SVGSVGElement>>
>([
  ['ach', PaymentIcon.Ach],
  ['cc', PaymentIcon.Creditcard],
  ['check', PaymentIcon.Check],
  ['custom', Icon.CurrencyUsd],
  ['direct_debit', PaymentIcon.Dd],
  ['external', Icon.CurrencyUsd],
  ['wire', PaymentIcon.Wire],
]);

const Method = ({
  isDisabled,
  isSelected,
  paymentMethod,
  dataTestId,
  onClick,
  setSelectedMethodId,
}: Props) => {
  const handleClick = () => {
    setSelectedMethodId(paymentMethod.id!);
    onClick?.();
  };

  const { name, description, paymentType } = paymentMethod;

  return (
    <RadioBlock
      dataTestId={dataTestId}
      description={description}
      iconAfter={isSelected ? Icon.Checkmark : null}
      iconBefore={methodIconMap.get(paymentType)}
      isBranded
      isDisabled={isDisabled}
      isSelected={isSelected}
      onClick={handleClick}
      title={name}
    />
  );
};

export default Method;
