import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'qs';
import { getMilliseconds, Loading, noop } from 'common';
import { useQueryClient } from '@tanstack/react-query';
import { updateSigningDocument } from '../services/docusign';
import { useActiveEmail } from '../utils/utils';
import { setProposal } from '../services/proposal';
import {
  useOriginalStatus,
  useOtpForAuth,
  useTokenForPreview,
} from '../utils/viewMode';

const DocusignViewCallback = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [retries, setRetries] = useState(0);

  const urlParams = new URLSearchParams(location.search);
  const eventVal = urlParams.get('event');
  const proposalId = urlParams.get('proposal');

  const email = useActiveEmail();
  const token = useTokenForPreview();
  const otpCode = useOtpForAuth();
  const originalStatus = useOriginalStatus();

  const queryClient = useQueryClient();

  /* eslint-disable camelcase */
  const query = {
    token,
    otp_code: otpCode,
    email,
    original_status: originalStatus,
  };
  /* eslint-enable camelcase */

  const redirectUrl = `/p/${proposalId}${qs.stringify(query, { addQueryPrefix: true })}`;

  useEffect(() => {
    if (eventVal !== 'signing_complete') {
      navigate(redirectUrl);
      return noop;
    }

    const checkSigningDocument = async () => {
      const proposalData = await updateSigningDocument(proposalId!);
      setProposal(queryClient, proposalData);
      setRetries((n) => n + 1);
      return !!proposalData.signingDocument;
    };

    const intervalId = setInterval(
      async () => {
        const isSigned = await checkSigningDocument();
        if (isSigned || retries > 5) {
          navigate(redirectUrl);
          clearInterval(intervalId);
        }
      },
      getMilliseconds({ seconds: 5 })
    );

    return () => clearInterval(intervalId);
  }, [eventVal, navigate, proposalId, redirectUrl, queryClient]);

  return <Loading />;
};

export default DocusignViewCallback;
