import React, { useState } from 'react';

import {
  CheckoutPaymentMethod,
  CompanyInfo,
  Contract,
  isAcceptedProposalStatus,
  MutedDiv,
  Proposal,
  useFlags,
} from 'common';

import { SelectedOptionProps } from '../../types/model';
import useUpdateMethod from './useUpdateMethod';
import ProposalTypeMethods from '../PaymentMethod/ProposalTypeMethods';
import { PAYMENT_SETUP_SECTION } from '../../utils/scrolling';

interface Props {
  companyInfo: CompanyInfo;
  contract?: Contract;
  isMuted: boolean;
  isPdf: boolean;
  paymentMethods: CheckoutPaymentMethod[];
  proposal: Proposal;
  selectedOptions: SelectedOptionProps;
  setSelectedMethodId: (method: string) => void;
}

export interface ChangeRenewalStateType {
  isCanceled: boolean;
  isChanged: boolean;
  isClicked: boolean;
  isDisabled: boolean;
  isEditable: boolean;
  originalPaymentSelection?: {
    id: string;
  } | null;
}

const ProposalTypePaymentSection = ({
  proposal,
  contract,
  companyInfo,
  paymentMethods,
  selectedOptions,
  isPdf,
  isMuted,
  setSelectedMethodId,
}: Props) => {
  const isAccepted = isAcceptedProposalStatus(proposal);
  const { enableChangeProposalPaymentMethods } = useFlags();
  const {
    paymentUpdateSection: updateSectionText,
    modal,
    isAuthorized,
  } = useUpdateMethod(proposal);

  const [changeRenewalState, setChangeRenewalState] =
    useState<ChangeRenewalStateType>({
      isDisabled: true,
      isEditable: false,
      isClicked: false,
      isCanceled: false,
      isChanged: false,
      originalPaymentSelection: null,
    });

  const showEditPayment = () => {
    return (
      // show passcode section if they have any payment method
      enableChangeProposalPaymentMethods && proposal.proposalType === 'change'
        ? contract?.billingMethod?.paymentMethod
        : !!proposal.billingMethod?.paymentMethod
    );
  };

  const paymentInfo = () => {
    const baseText = `We are currently using ${enableChangeProposalPaymentMethods && proposal.proposalType === 'change' ? contract?.billingMethod?.paymentMethod.name : proposal.billingMethod?.paymentMethod.name} for payments. `;

    if (contract?.billingMethod?.account) {
      return (
        <p className="pb-2">
          {baseText} We will use the account ending in{' '}
          <span className="font-semibold">
            {contract.billingMethod.account.mask}
          </span>{' '}
          for payments on the current subscription.
        </p>
      );
    }

    return <p className="pb-2">{baseText}</p>;
  };

  return (
    <div id={PAYMENT_SETUP_SECTION}>
      {modal()}
      {isAccepted && showEditPayment() && (
        <>{updateSectionText(paymentInfo(), undefined)}</>
      )}
      {!isPdf && (
        <MutedDiv isMuted={isMuted} className="print:hidden">
          <ProposalTypeMethods
            proposal={proposal}
            companyInfo={companyInfo}
            contract={contract}
            paymentMethods={paymentMethods}
            selectedOptions={selectedOptions}
            setChangeRenewalState={setChangeRenewalState}
            changeRenewalState={changeRenewalState}
            enabledForOtp={isAuthorized()}
            setSelectedMethodId={setSelectedMethodId}
          />
        </MutedDiv>
      )}
    </div>
  );
};

export default ProposalTypePaymentSection;
