import React from 'react';
import { CompanyInfo, Proposal } from 'common';
import SubTitle from 'checkout/src/components/SubTitle';
import { useDefaultSignature } from 'checkout/src/services/signature';
import { useActiveContact } from 'checkout/src/utils/utils';

// preview pdfs should have a blank signature
interface Props {
  companyInfo: CompanyInfo;
  proposal: Proposal;
}

const DocusignPdfSignatures: React.FC<Props> = ({ companyInfo, proposal }) => {
  const { data: defaultSignature } = useDefaultSignature();
  const activeContact = useActiveContact(proposal);
  const signingContact =
    proposal.signingDocument?.signer ||
    proposal.customer?.billingContact ||
    activeContact;
  const manualSigning = !proposal.options?.requiresSigning;

  return (
    <div className="break-inside-avoid">
      <SubTitle title="Signatures" />
      <div>
        IN WITNESS WHEREOF, the parties have caused this Agreement to be signed
        by their duly authorized representatives, effective as of the date last
        signed by the parties below.
      </div>
      <SubTitle title="Accepted and Agreed:" />
      <div className="gap-6 sm:grid sm:grid-cols-2 grid-flow-column">
        <div className="xs:flex xs:justify-between xs:mb-3 sm:block sm:mb-0  ">
          <strong>{companyInfo.name}</strong>
        </div>
        <div className="xs:flex xs:justify-between xs:mb-3 sm:block sm:mb-0  ">
          Customer: <strong>{proposal.customer?.name}</strong>
        </div>
        <div
          className="xs:flex xs:justify-between xs:mb-3 sm:block sm:mb-0"
          style={{ height: '100px' }}
        >
          <div className="border-b-black border-b-[1px] h-full w-full">
            <strong>
              {defaultSignature && defaultSignature.signatureImageData && (
                <img
                  className="h-24 w-48 mt-2"
                  src={defaultSignature.signatureImageData}
                />
              )}
            </strong>
          </div>
        </div>
        <div
          className="xs:flex xs:justify-between xs:mb-3 sm:block sm:mb-0"
          style={{ height: '100px' }}
        >
          <div className="border-b-black border-b-[1px] h-full w-full">
            {!manualSigning && (
              <span style={{ color: 'var(--paper-background-color)' }}>
                CACHEFLOW_INTERNAL_SIGN_HERE
              </span>
            )}
          </div>
        </div>
        <div className="xs:flex xs:justify-between xs:mb-3 sm:block sm:mb-0  ">
          <div>
            Name:{' '}
            <strong>
              {defaultSignature?.name ||
                `${proposal.owner?.firstName} ${proposal.owner?.lastName}`}
            </strong>
          </div>
          <div>
            Title: <strong>{defaultSignature?.title}</strong>
          </div>
          <div>
            Date:{' '}
            <span style={{ color: 'var(--paper-background-color)' }}>
              CACHEFLOW_INTERNAL_DATE_SIGNED
            </span>
          </div>
        </div>
        <div className="xs:flex xs:justify-between xs:mb-3 sm:block sm:mb-0  ">
          <div>
            Name:{' '}
            <strong>
              {signingContact.firstName} {signingContact.lastName}
            </strong>
          </div>
          <div>
            Title: <strong>{signingContact.role}</strong>
          </div>
          <div>
            Date:{' '}
            <span style={{ color: 'var(--paper-background-color)' }}>
              CACHEFLOW_INTERNAL_DATE_SIGNED
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocusignPdfSignatures;
