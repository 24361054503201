import React from 'react';
import { useIsPreview } from '../utils/viewMode';
import { useToast } from 'common';
import styles from './DisableInPreview.module.scss';

interface Props {
  children: React.ReactNode;
}

const DisableInPreview: React.FC<Props> = ({ children }) => {
  const isPreview = useIsPreview();
  const showToast = useToast();

  if (!isPreview) {
    return children;
  }

  return (
    <div
      onClick={(event) => {
        event.stopPropagation();
        showToast.info('This feature is disabled in preview mode');
      }}
    >
      <div className={styles.disabledInPreview}>{children}</div>
    </div>
  );
};

export default DisableInPreview;
