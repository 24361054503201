import {
  CheckoutPaymentMethod,
  CompanyInfo,
  Contract,
  hasAnAcceptedProposalStatus,
  isChangeProposalType,
  MutedDiv,
  Proposal,
  useFlags,
} from 'common';
import React from 'react';
import Methods from '../PaymentMethod/Methods';
import { getIsSectionMuted } from '../../utils/workflow';
import useUpdateMethod from './useUpdateMethod';
import { SelectedOptionProps } from '../../types/model';
import { PAYMENT_SETUP_SECTION } from '../../utils/scrolling';

interface Props {
  companyInfo: CompanyInfo;
  contract?: Contract;
  isPdf: boolean;
  needsBillingModal?: boolean;
  paymentMethods: CheckoutPaymentMethod[];
  proposal: Proposal;
  selectedOptions: SelectedOptionProps;
  setSelectedMethodId: (method: string) => void;
}

const ProposalPaymentSection = ({
  proposal,
  contract,
  companyInfo,
  paymentMethods,
  selectedOptions,
  isPdf,
  needsBillingModal,
  setSelectedMethodId,
}: Props) => {
  const hasAnAcceptedStatus = hasAnAcceptedProposalStatus(proposal);

  const {
    paymentUpdateSection: updateSectionText,
    modal,
    isAuthorized,
  } = useUpdateMethod(proposal);

  const { locationCodeMapping: locationCodeValidationEnabled } = useFlags();

  const invalidBillingOrShippingCountry =
    locationCodeValidationEnabled &&
    (!proposal.customer?.billingAddress?.countryIso ||
      !proposal.customer.shippingAddress?.countryIso);

  const isMuted =
    isChangeProposalType(proposal) ||
    getIsSectionMuted(proposal, 'payment_methods') ||
    invalidBillingOrShippingCountry;

  const isEditable =
    isAuthorized() || needsBillingModal || (!hasAnAcceptedStatus && !isMuted);

  const showEditPayment = (): boolean => {
    return (
      // show passcode section if they have any payment method
      !!contract?.billingMethod?.paymentMethod
    );
  };

  const awaitingAuthorization = !isAuthorized() && showEditPayment();

  const paymentInfo = () => {
    const baseText = `We are currently using ${contract?.billingMethod?.paymentMethod.name} for payments. `;

    if (contract?.billingMethod?.account) {
      return (
        <p className="pb-2">
          {baseText} We will use the account ending in{' '}
          <span className="font-semibold">
            {contract.billingMethod.account.mask}
          </span>
          .
        </p>
      );
    }

    return <p className="pb-2">{baseText}</p>;
  };

  const isExternalPaymentMethods = (
    externalPaymentMethods: CheckoutPaymentMethod[]
  ): boolean => {
    const externalPaymentTypes = ['ach', 'custom'];
    return (
      externalPaymentMethods.length <= 1 &&
      externalPaymentMethods.some(
        (method) =>
          method.paymentType &&
          externalPaymentTypes.includes(method.paymentType)
      )
    );
  };

  const isExternalPayment = isExternalPaymentMethods(paymentMethods);

  return (
    <div id={PAYMENT_SETUP_SECTION}>
      {modal()}
      {hasAnAcceptedStatus && showEditPayment() && !isExternalPayment && (
        <>{updateSectionText(paymentInfo(), undefined)}</>
      )}
      {!isPdf && (
        <MutedDiv
          isMuted={isMuted || awaitingAuthorization}
          className="print:hidden"
        >
          <Methods
            contract={contract}
            proposal={proposal}
            companyInfo={companyInfo}
            paymentMethods={paymentMethods}
            isEditable={isEditable}
            selectedOptions={selectedOptions}
            setSelectedMethodId={setSelectedMethodId}
          />
        </MutedDiv>
      )}
    </div>
  );
};

export default ProposalPaymentSection;
